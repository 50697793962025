<ion-grid fixed class="ion-no-padding ion-no-margin e-bill-payment-cnt">
  <ion-content fullscreen [scrollY]="false" class="ion-no-padding ion-no-margin">
    <ion-slides class="h-100 swiper-no-swiping" #slider [options]="slideOpts">
      <ion-slide class="h-100" style="overflow-y: auto">
        <div class="slide h-100">
          <ion-row class="ion-no-margin header">
            <ion-col size="12" class="ion-text-center title">
              <h2 class="text-white">Facturas Electrónicas</h2>
            </ion-col>
            <ion-col size="12" class="customer-info">
              <ion-row class="align-items-center">
                    <ion-col size="3"sizeMd="12" sizeXl="3" sizeXs="12" sizeSm="12" sizeLg="3"  class="ml-cnt-columns">
                      <span>(*) Fecha inicial</span>
                      <ion-item lines="none">
                        <ion-datetime class="ion-margin-start" [(ngModel)]="fechasConsulta.inicial" [pickerOptions]="customPickerOptionsInicial"
                                      monthShortNames="ene, feb, mar, abr, may, jun, jul, ago, sep, oct, nov, dic" placeholder="----/--/--"
                                      displayFormat="YYYY-MMM-DD"></ion-datetime>
                      </ion-item>
                    </ion-col>
                    <ion-col size="3" sizeMd="12" sizeXl="3" sizeXs="12" sizeSm="12" sizeLg="3" class="ml-cnt-columns mt-sm-1">
                      <span>(*) Fecha final</span>
                      <ion-item lines="none">
                        <ion-datetime  class="ion-margin-start" [(ngModel)]="fechasConsulta.final" [pickerOptions]="customPickerOptionsFinal"
                                      monthShortNames="ene, feb, mar, abr, may, jun, jul, ago, sep, oct, nov, dic" placeholder="----/--/--"
                                      displayFormat="YYYY-MMM-DD"></ion-datetime>
                      </ion-item>
                    </ion-col>
                    <ion-col size="3" sizeMd="12" sizeXl="2" sizeXs="12" sizeSm="12" sizeLg="3" class="ml-cnt-columns ion-text-center">
                      <ion-button (click)="obtenerFacturasButton()" color="buttonaccept" shape="round" class="mt-button-20"
                                  fill="solid">
                        Filtrar
                      </ion-button>
                    </ion-col>
                <ion-col size="2" sizeMd="12" sizeXl="3" sizeXs="12" sizeSm="12" sizeLg="2"  class="ml-cnt-columns">
                  <ion-item class="input-search">
                    <ion-label position="floating" class="ion-padding-start">Buscar por número de factura</ion-label>
                    <ion-input class="ion-margin-start" [(ngModel)]="busqueda.numFactura" name="numFactura" (keyup)="buscarFactura()" type="text">
                    </ion-input>
                  </ion-item>
                </ion-col>
              </ion-row>
              <ion-row *ngIf="rows">
                <ion-col size="12">
                  <ion-card class="ion-margin-top" style="overflow: auto !important;">
                    <ion-card-content class="ion-no-padding">
                      <table class="responsive-table-ebillspayment">
                        <thead>
                          <tr>
                            <th>Número de factura</th>
                            <th>Monto</th>
                            <th>Clave</th>
                            <th>Fecha y hora de emisión</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of rowsTemp" class="tr-table">
                            <td>{{item.NumeroProcesoFacturaElectronica}}</td>
                            <td>{{item.Monto}}</td>
                            <td>{{item.Clave}}</td>
                            <td>{{item.FechaEmision}} {{item.HoraEmision}}</td>
                            <td>
                              <div (click)="downloadFactura(item.NumeroProcesoFacturaElectronica)"
                                class="ion-text-center btn-download">
                                <img width="15" src="assets/imgs/servicios/ic_zip.png" alt="factura" />
                                <div>
                                  <small class="">Descargar</small>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <ion-row>
                        <ion-col size="12" *ngIf="showAlertData">
                          <div *ngIf="rowsTemp.length <= 0" class="ion-padding ion-text-center">
                            <div style="margin-bottom: 0;" class="alert-info alert ion-align-items-center d-flex">
                              <ion-text style="padding-left: 7px; padding:10px;" class="text-center-alert">No existen facturas registradas en el período de tiempo seleccionado.</ion-text>
                            </div>
                          </div>
                        </ion-col>
                      </ion-row>

                    </ion-card-content>
                  </ion-card>
                  <ion-card-content class="content-end-table">
                  </ion-card-content>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>

          <ion-row class="footer">
            <ion-col class="ion-no-padding">
              <ion-button (click)="closeModal()" color="buttoncancel" class="ion-no-margin" expand="full" fill="clear">
                Cerrar
              </ion-button>
            </ion-col>
            <!-- <ion-col class="ion-no-padding">
             <ion-button (click)="showPaymentSlide()" color="buttonaccept" class="ion-no-margin" expand="full"
                fill="clear">Continuar
              </ion-button>
            </ion-col> -->
          </ion-row>
        </div>
      </ion-slide>
    </ion-slides>
  </ion-content>

</ion-grid>

<!--  -->
