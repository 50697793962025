<ion-content style="box-shadow: none; border: none;">
  <app-header-text
    titleIcon="md-filetray"
    [sticky]="true"
    (leftBtnClick)="backModal()"
    title="Pago PostVenta"
    [iconRight]="'none'"
  >
  </app-header-text>
  <iframe style="margin-top: 60px" #frameAddNewCardFoyPayment id="frameAddNewCardFoyPayment" [src]="url" width="100%" height="100%"
          (load)="onIframeLoad($event)" frameborder="0"></iframe>
</ion-content>
