<ion-content>
  <ion-item color="primary" class="header-help-section">
    <ion-icon (click)="goBack()" slot="start" class="bck-button-transparent" name="chevron-back" color="white">
    </ion-icon>
    <ion-chip color="primary" class="title-center">
      <img class="logo-seccion" [src]="logo" />
      <ion-label class="title-help">Sugerencias</ion-label>
    </ion-chip>
    <ion-chip slot="end" color="maincardsnavigation" outline="false">
      <ion-label></ion-label>
    </ion-chip>
  </ion-item>
  <ion-grid>
    <ion-row *ngIf="!authService.bs.isDesktop()" class="ion-margin-top ion-align-items-center">
      <ion-col
        (click)="closeModal()"
        size="2"
        class="ion-text-center ion-no-padding"
      >
        <ion-icon
          class="bck-button"
          name="chevron-back"
          color="light"
        ></ion-icon>
      </ion-col>
      <ion-col size="8" class="fsz-14 ion-text-center">
        <ion-icon name="chatbubble-ellipses" color="gptitle"></ion-icon>
        <ion-text class="ion-margin-start" color="gptitle"
          >Sugerencias</ion-text
        >
      </ion-col>
    </ion-row>
    <div class="ion-padding">
      <p class="gq-titulos">
        Tu opinión es valiosa para nosotros. Envíanos un mensaje.
      </p>

      <ion-list>
        <ion-item>
          <ion-textarea
            [(ngModel)]="comentario"
            placeholder="Detalla tu comentario"
          ></ion-textarea>
        </ion-item>
      </ion-list>

      <div class="gp-buton-fallas ion-text-center">
        <ion-button
          style="width: 150px"
          (click)="reportarFalla()"
          color="danger"
          class="gq-button-actualizar btn-radius"
          >Enviar
        </ion-button>
      </div>
    </div>
  </ion-grid>
</ion-content>
