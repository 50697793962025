import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AlertController } from "@ionic/angular";
import { BenefitHelper } from "../benefits/BenefitHelper";
import {CountriesService} from "../../services/graphql/countries.service";
import {AlertInput} from "@ionic/core";

@Injectable({
  providedIn: "root",
})
export class WorkshopsHelper {
  /* */
  $currentCountry = new Subject<any>();

  public selectedCountry = "SV";
  countries: any [] = [];
  constructor(
    private alertCtrl: AlertController,
    private benefitHelper: BenefitHelper,  private countriesService: CountriesService) {
    this.getAllCountries();
    this.selectedCountry = window.localStorage.getItem("paisVisita") || "SV";
    this.pokeCurrentCountry(this.selectedCountry);
    setTimeout(() => {
        this.acceptChanges();
    }, 1000);
  }

  acceptChanges() {
    const country = localStorage.getItem("paisVisita") || "SV";
    this.handler(country);
  }

  getCurrentCountryAsObservable() {
    return this.$currentCountry.asObservable();
  }

  pokeCurrentCountry(val: string) {
    this.$currentCountry.next(val);
  }

  getCountryCodeByName(countryName: string) {
    const countries = {
      salvador: 'SV',
      nicaragua: 'NI',
      honduras: 'HN',
      guatemala: 'GT',
      costa_rica: 'CR',
      grupo_q_panama: 'PA'
    };

    return countryName in countries ? countries[countryName] : 'SV';
  }

  getAllCountries() {
    this.countriesService.getCountries().subscribe((resp: any) => {
      if (resp?.data?.allCountries) {
        this.countries = resp?.data?.allCountries;
      } else {
        this.countries = [];
      }
    });
  }

  handler(data: any) {
    this.selectedCountry = data;
    this.pokeCurrentCountry(data);
    this.benefitHelper.selectedCountry = data;
    if (data == "null" || data == null || data == undefined || data == "") {
      data = "SV";
    }
    window.localStorage.setItem("paisVisita", data);
    this.benefitHelper.pokeCurrentCountry(data);
  }

  async presentAlertCheckbox() {
    const inputsDinamicos: AlertInput[] = this.countries.map(pais => {
      return {
        name: pais.abbr,
        type: "radio",
        label: pais.name,
        value: pais.abbr,
        checked: this.selectedCountry === pais.abbr,
      };
    }) as AlertInput[];
    const alert = await this.alertCtrl.create({
      cssClass: "my-custom-class",
      header: "Seleccionar País",
      inputs: inputsDinamicos,
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: () => { },
        },
        {
          text: "Aceptar",
          cssClass: "btnAceptarWorkshops",
          handler: (data) => {
            this.handler(data);
          },
        },
      ],
    });

    await alert.present();
  }
}
