import { Component, OnInit } from '@angular/core';
import {PopoverController} from "@ionic/angular";
import {UtilService} from "../../providers/util.service";

@Component({
  selector: 'app-notify-crediq-tokenization',
  templateUrl: './notify-crediq-tokenization.component.html',
  styleUrls: ['./notify-crediq-tokenization.component.scss'],
})
export class NotifyCrediqTokenizationComponent implements OnInit {
  acceptTerms = false;
  constructor(private popoverController: PopoverController, private utilService: UtilService) { }
  ngOnInit() {}
  closeWithAction(action: string) {
    if (action === 'exit_with_accept') {
      void this.utilService.presentLoading();
    }
    void this.popoverController.dismiss(action, null, 'popover_notifyCrediqTokenization');
  }
}

