import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoanHelper } from '../../../helpers/crediq/LoanHelper';
import {AlertController, ModalController, NavController, Platform} from '@ionic/angular';
import { Router } from '@angular/router';
import { AnalyticsService } from '../../../providers/analytics.service';
import { UtilService } from '../../../providers/util.service';
import {
  ConfirmPaymentCreditcardComponent
} from "../../../components/confirm-payment-creditcard/confirm-payment-creditcard.component";

@Component({
  selector: 'app-credit-cards',
  templateUrl: './credit-cards.page.html',
  styleUrls: [ './credit-cards.page.scss' ],
})
export class CreditCardsPage implements OnInit, OnDestroy {
  backBtnSubscriber: Subscription;
  userCardsSubscription: Subscription;

  userCards: any[] = [];

  cardTypes: any = {
    VISA: {
      img: '/assets/icon/cards/visa.svg'
    },
    MASTERCARD: {
      img: '/assets/icon/cards/mastercard.svg'
    },
  };

  constructor(
    public navCtrl: NavController,
    public platform: Platform,
    public router: Router,
    public loanHelper: LoanHelper,
    public analyticsService: AnalyticsService,
    public utilService: UtilService,
    public alertController: AlertController,
    public modalController: ModalController
  ) {
    this.userCards = this.loanHelper.getUserCards();
  }

  ngOnInit() {
    this.analyticsService.logEvent(AnalyticsService.CQ_OPEN_PAYMENT_CARDS, 'cq_credit_cards')

    this.userCardsSubscription = this.loanHelper.getUserCardsObservable().subscribe((data) => {
      // console.log('observable for user cards');
      // console.log("data", data);
      this.userCards = this.loanHelper.getUserCards();
    });

    this.backBtnSubscriber = this.platform.backButton.subscribeWithPriority(999999, () => {
      return this.navCtrl.back({animated: true});
    });
  }

  ngOnDestroy(): void {
    if (this.userCardsSubscription) {
      this.userCardsSubscription.unsubscribe();
    }
    if (this.backBtnSubscriber) {
      this.backBtnSubscriber.unsubscribe();
    }
  }

  ionViewDidEnter() {
  }

  goBack() {
    this.navCtrl.back();
  }

  async removeCreditCard(id: string) {
    const alert = await this.alertController.create({
      cssClass: 'buttonCss',
      header: '¿Está seguro que desea eliminar esta tarjeta?',
      message: 'No podrá recuperarla si confirma esta acción.',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {

          }
        }, {
          text: 'Confirmar',
          handler: () => {
            this.removeCreditCardWS(id);
          }
        }
      ]
    });
    await alert.present();
  }

  removeCreditCardWS(token) {
    this.utilService.presentLoading();
    this.loanHelper.removeCreditCard(token).then((res: any) => {
      this.utilService.dismissLoading();
      if (res['process-status'] === 'ok' && res.status === 'ok') {
        this.userCards = [ ...this.userCards.filter(x => x.token != token) ];

        this.loanHelper.setUserCards(this.userCards);

        // Refetch cards data
        this.loanHelper.getuserCardsWS(true); // get user cc
        this.loanHelper.getUserLoanCardsWS(true); // get user cc

        return this.utilService.presentToast('Se ha eliminado la tarjeta');
      }

      return this.utilService.presentToast('Error al eliminar tarjeta.');
    }, (err) => {
      console.log(err);
      this.utilService.dismissLoading();
    });
  }

  async validateCreditCard(token: string) {
    const modal = await this.modalController.create({
      component: ConfirmPaymentCreditcardComponent,
      showBackdrop: false,
      backdropDismiss: true
    });
    await modal.present();
    modal.onDidDismiss()
      .then((data: any) => {
        const close = data.data.optPayment.close;
        console.log(close);
        if (!close) {
          if (parseFloat(data.data.optPayment.amount) > 0) {
            const amount = data.data.optPayment.amount;
            this.validateCreditCardWS(token, amount);
          }
        }
      });
  }

  validateCreditCardWS(token, amount) {
    this.utilService.presentLoading();
    this.loanHelper.validateCreditCardWS(token, amount).then((response: any) => {
      this.utilService.dismissLoading();
      if (response['process-status'] === 'ok' && response.status === 'ok') {
        // console.log(this.userCards);
        this.userCards.find(x => x.token === token).valida = "true";
        this.loanHelper.setUserCards(this.userCards);
        // Refetch cards data
        this.loanHelper.getuserCardsWS(true); // get user cc
        this.loanHelper.getUserLoanCardsWS(true); // get user cc
        return this.utilService.presentToast('Se ha activado la tarjeta');
      }
    }, (err) => {
      this.utilService.dismissLoading();
      return this.utilService.presentToast(err);
    });
  }

  backFn() {
      // /crediq/home
    this.navCtrl.navigateRoot('/crediq/home');
  }

  public getFormattedDate(date = '') {
    const sp = date.split('/');
    return sp[0] + '/' + sp[1];
  }

}
