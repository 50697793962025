import { Injectable } from '@angular/core';
import {SoapService} from '../services/soap/soap.service';
import {environment as env} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AyudaService {

  constructor(public soapService: SoapService) {}

  enviarFalla(datos) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      datos: JSON.stringify(datos)
    };
    return this.soapService.post(url, 'falla_sugerencia', atributos)
        .then((response: any) => {
          return JSON.parse(response.return);
        }).catch(error => {
          return error;
        });
  }
}
