<ion-content>
  <app-header-text iconRight="none" titleIcon="card" (leftBtnClick)="backFn()" title="Mis tarjetas" ></app-header-text>

  <!-- Header page -->
  <ion-grid>
    <div class="ion-padding ion-text-center">
      <div class="alert-info vh-aligned alert ion-align-items-center d-flex">
        <ion-icon class="icon-alert-info" name="information-circle-outline"></ion-icon>
        <ion-text>Desliza tu tarjeta hacia la izquierda para ver más opciones.</ion-text>
      </div>
    </div>
    <ion-list class="mtn-30">
      <ion-list>
        <ion-item-sliding *ngFor="let card of userCards; index as i;">

          <ion-item class="item-card">
            <ion-icon name="card"></ion-icon>
            <span>
              <ion-label>
                {{card?.alias}}
              </ion-label>
              <span class="number-credit-card">
                **** {{card?.ultimos_digitos}}
              </span>
            </span>
            <ion-icon *ngIf="card.valida == 'true' " slot="end" color="success" name="shield-checkmark"></ion-icon>
            <ion-icon *ngIf="card.valida == 'false' " slot="end" color="danger"  name="alert-circle-outline"></ion-icon>
          </ion-item>

          <ion-item-options side="end">
            <ion-item-option *ngIf="card.valida == 'false'" color="gpblue" (click)="validateCreditCard(card?.token)">
              <ion-icon slot="icon-only" name="shield-checkmark"></ion-icon>
            </ion-item-option>
            <ion-item-option color="danger" (click)="removeCreditCard(card?.token)">
              <ion-icon slot="icon-only" name="trash"></ion-icon>
            </ion-item-option>
          </ion-item-options>

        </ion-item-sliding>
      </ion-list>
    </ion-list>
  </ion-grid>

  <ion-fab-button style="right: 21px; bottom: 15px; position: fixed;" color="gpblue"
    [routerLink]="'/crediq/credit-cards/add'">
    <ion-icon class="icon-add-credit-card " color="light" name="add"></ion-icon>
  </ion-fab-button>
</ion-content>
