import StorageHelper from '..//StorageHelper';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../../providers/auth.service';
import { environment as env } from '../../../environments/environment';
import { UtilService } from '../../providers/util.service';
import { VehicleHelper } from '../VehicleHelper';
import { DriverQualificationService } from "../../providers/smartq/driver-qualification.service";
import { calificacionConductorSQ } from "../../pages/smartq/models";
import { SmartHelper } from "./SmartHelper";

@Injectable({
  providedIn: 'root'
})

export class DriverScoreHelper extends StorageHelper {
  private storageKeys = env.smartq.storagekeys;

  private $userScores = new Subject<any>();
  private isLoadingScores = false;

  public userScores = [];

  isLoadingScore = false;

  fakeCalificacion = {
    dias: "15",
    calificacion: "74/100",
    resumen: {
      "arrancadas_fuertes": 0,
      "aceleradas_bruscas": 2,
      "frenadas_bruscas": 0,
      "giros_bruscos": 0,
      "excesos_velocidad": 70,
      "segundos_en_exceso_de_velocidad": 1079,
      "posibles_colisiones": 0,
      "accidentes": 0
    },
    eventos: [
      {
        "fecha_gps": "2022-01-27 06:23:56",
        "cp": "866381054245540",
        "motivo": "7",
        "velocidad": "49.99999995"
      },
      {
        "fecha_gps": "2022-01-27 06:26:13",
        "cp": "866381054245540",
        "motivo": "7",
        "velocidad": "48.0000001"
      },
      {
        "fecha_gps": "2022-01-27 06:27:12",
        "cp": "866381054245540",
        "motivo": "7",
        "velocidad": "48.0000001"
      },
      {
        "fecha_gps": "2022-01-27 06:28:49",
        "cp": "866381054245540",
        "motivo": "7",
        "velocidad": "44.9999994"
      },
      {
        "fecha_gps": "2022-01-27 06:29:11",
        "cp": "866381054245540",
        "motivo": "7",
        "velocidad": "42.99999955"
      },
      {
        "fecha_gps": "2022-01-27 06:29:30",
        "cp": "866381054245540",
        "motivo": "7",
        "velocidad": "46.00000025"
      },
      {
        "fecha_gps": "2022-01-27 06:30:06",
        "cp": "866381054245540",
        "motivo": "7",
        "velocidad": "44.9999994"
      },
      {
        "fecha_gps": "2022-01-27 06:30:22",
        "cp": "866381054245540",
        "motivo": "7",
        "velocidad": "51.0000008"
      }
    ]
  };

  constructor(
    private authService: AuthService,
    private utilService: UtilService,
    private driverService: DriverQualificationService,
    private smartHelper: SmartHelper
  ) {
    super();
  }

  setIsLoadingScores(val: boolean) {
    this.isLoadingScores = val;
  }

  getIsLoadingScores() {
    return this.isLoadingScores;
  }

  getUserScoresAsObservable() {
    return this.$userScores.asObservable();
  }

  pokeUserScores(val) {
    this.$userScores.next(val);
  }

  getUserScoreByVIN(vehicle, global = false) {
    try {
      const cardLicence = vehicle.VIN;
      if (global) {
        return new calificacionConductorSQ(this.getItem(env.production ? `prod:${this.storageKeys.userDriverGlobalScore}:${cardLicence}` : `dev:${this.storageKeys.userDriverGlobalScore}:${cardLicence}`)) || null;
      } else {
        return new calificacionConductorSQ(this.getItem(env.production ? `prod:${this.storageKeys.userDriverScore}:${cardLicence}` : `dev:${this.storageKeys.userDriverScore}:${cardLicence}`)) || null;
      }
    } catch (e) {
      console.warn(e);
      return null;
    }
  }

  getUserScores() {
    try {
      return this.getItem(env.production ? `prod:${this.storageKeys.userDriverScore}` : `dev:${this.storageKeys.userDriverScore}`) || this.userScores;
    } catch (e) {
      console.warn(e);
      return this.userScores;
    }
  }

  getGlobalScore() {
    let sum = 0;
    let qty = 0;
    try {
      this.smartHelper.getSmartQVehicles().map(x => {
        const scoreObj = this.getUserScoreByVIN(x, true);
        if (scoreObj && !scoreObj.hasError()) {
          qty++;
          sum += +scoreObj.calificacion;
        }
      });
      return sum / qty;
    } catch (e) {
      console.warn(e);
      return sum;
    }
  }

  getUserScoresFromVehicleList(vehicles, forced = false) {
    vehicles.filter(x => x.ACCESORIO_ACTIVO.toUpperCase() === 'X').map(vehicle => {
      this.getUserScoreWS(vehicle, forced);
    });
  }

  getUserScoreWS(vehicle, forced = false, selectedMonth = null, emmit = false) {
    if (vehicle && vehicle.VIN) {
      const cardLicence = vehicle.VIN;
      const storageKey = env.production ? `prod:${this.storageKeys.userDriverScore}:${cardLicence}` : `dev:${this.storageKeys.userDriverScore}:${cardLicence}`;
      const storageGlobalKey = env.production ? `prod:${this.storageKeys.userDriverGlobalScore}:${cardLicence}` : `dev:${this.storageKeys.userDriverGlobalScore}:${cardLicence}`;
      if (!this.isValidKeyETA(storageKey, 1) || forced) {
        if (!this.isLoadingScore) {
          this.isLoadingScore = true;
          this.driverService.calificacionConductores(vehicle, selectedMonth)
            .then((result: any) => {
              void this.utilService.dismissAllLoadingControllers();
              this.isLoadingScore = false;
              const responseSQ = new calificacionConductorSQ(result);
              // const responseSQ = new calificacionConductorSQ(this.fakeCalificacion);

              if (responseSQ.hasError()) {
                // this.utilService.presentToast('Falló Calificacion SQ');
                // this.pokeUserScores(null);
                return;
              }

              if (selectedMonth) { // Save only current month
                this.setItem(storageKey, JSON.stringify(responseSQ));
              } else {
                if (!this.getItem(storageKey)) {
                  this.setItem(storageKey, JSON.stringify(responseSQ));
                }
                this.setItem(storageGlobalKey, JSON.stringify(responseSQ));
              }

              if (emmit) {
                this.pokeUserScores(responseSQ);
              }

            }).catch(error => {
            this.isLoadingScore = false;
            void this.utilService.dismissAllLoadingControllers();
            console.warn(error);
            // this.utilService.presentToast(`Problemas de conexión calificación conductor: ${JSON.stringify(error)}`);
          });
        }
      }
    }
  }
}
