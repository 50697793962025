export const BASE_CREDIQ_URL = 'https://cqwbqa.crediq.com/CustomerBackServicesQA/webresources/';
export const BASE_GQ_URL = 'https://calidad.grupoq.co.cr/';
export const CQ_URL_APPT_FAC_RESP = 'https://migrupoq.calidad.grupoq.co.cr/pago.php';
// const BASE_CREDIQ_URL = 'https://www.crediqweb.com:8181/CustomerBackServices/webresources/';
const BASE_SMARTQ_URL = 'https://[cr].detektorgps.com/gps_[cr]/framework/servicios/wSmartQv/';
const BASE_GRAPHLQ_ENDPOINT = 'https://pre.admin.gq.cronos.mobi/graphql';
const BASE_ENDPOINT_HTTP_BENEFITS = 'https://pre.admin.gq.cronos.mobi/';
const BASE_ENDPOINT_HTTP = 'https://admin.gq.cronos.mobi/';
const BASE_API_AUTOGESTION = 'https://pod.gqservice.net/RESTAdapter/migrupoq/iframe?operation=';
const PAYMENT_AFS_WINDOW = 'https://pagofactranz.calidad.grupoq.co.cr/index.php';
const PAYMENT_AFS_WINDOW_ORIGIN = 'https://pagofactranz.calidad.grupoq.co.cr/index.php';

export const environment = {
  production: false,
  ASSETS_URL: 'https://migrupoq.nyc3.cdn.digitaloceanspaces.com/grupoq/production/',
  SITE_URL: 'https://migrupoq.com/',
  API_URLS: [
    ''
  ],
  passcode: 'l0v32020@',
  settings: {
    key_current_theme: 'CURR_THEME',
    map_api: 'AIzaSyCrvUVLjfkK04rzVHVyDHmx6Ry-24qZEVM',
    key_push: '',
    key_session_storage: '',
    api_key: '',
    token_smart: 'tokenDriverSQ',
    username: 'username',
  },
  endpoints: {
    SEND: '',
    app_config: `${BASE_ENDPOINT_HTTP}pel/conf/`,
    transactions: `${BASE_ENDPOINT_HTTP}pel/transactions/`,
    transactionsAFS: `${BASE_ENDPOINT_HTTP}/pel/transactions-afs/`,
    card_validator: `${BASE_ENDPOINT_HTTP}pel/card-validators/`,
    endpointLoginClient: `${BASE_ENDPOINT_HTTP}api/user/login/`,
    endpointDevice: `${BASE_ENDPOINT_HTTP}api/user/device/`,
    paymentAfsWindow: PAYMENT_AFS_WINDOW,
    paymentAfsWindowOrigin: PAYMENT_AFS_WINDOW_ORIGIN,
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCuzoDRcwd6XVYTRsfwT3Uc--y-glBv4Tc',
    authDomain: 'migrupoq-c1974.firebaseapp.com',
    databaseURL: 'https://migrupoq-c1974.firebaseio.com',
    projectId: 'migrupoq-c1974',
    storageBucket: 'migrupoq-c1974.appspot.com',
    messagingSenderId: '397608709661',
    appId: '1:397608709661:web:d5065874b664b5e1b0e0f4'
  },
  // API_ENDPOINT: 'https://www.grupoq.co.cr/webservices/migrupoq_app/servicio.php',
  API_ENDPOINT: `${BASE_GQ_URL}webservices/migrupoq_app/servicio.php`,
  API_REST_ENDPOINT: 'https://pidev.grupoq.com:51501/RESTAdapter/smartq/migrupoq/services',
  API_GRAPHQL_ENDPOINT: `${BASE_GRAPHLQ_ENDPOINT}`,
  WS_CODIGO: 'e347d058dfa2834248f2455b418cd31a',
  APP_VERSION: 'MiGrupoQ - POST - V1 - QA',
  firebase: {
    user: 'soporte@cronos.mobi',
    password: ')xDo$rep('
  },
  crediq: {
    endpoints: {
      // login: `${BASE_CREDIQ_URL}cuentas/consultaCliente`,
      login: `${BASE_CREDIQ_URL}cuentas/registerSpecial`,
      attendNotificationOfChangeOfGateway: `${BASE_CREDIQ_URL}cuentas/atenderNotificacionCambioPasarela`,
      loginTest: `${BASE_CREDIQ_URL}cuentas/login`,
      saveCreditCard: `${BASE_ENDPOINT_HTTP}tokenizer/`,
      getCards: `${BASE_CREDIQ_URL}tarjeta/tarjetasCliente`,
      getCardsUserCrediQ: `${BASE_CREDIQ_URL}tarjetaTokenizada/obtenerTarjetasUsuario`,
      getCardsUserCrediQPrestamos: `${BASE_CREDIQ_URL}tarjetaTokenizada/obtenerTarjetasUsuarioPagoPrestamos`,
      loanDetail: `${BASE_CREDIQ_URL}prestamos/consultarPrestamos`,
      loanDetailLight: `${BASE_CREDIQ_URL}prestamos/getGarantias`,
      loanNPE: `${BASE_CREDIQ_URL}prestamos/carnetsAsociadosByPrestamo`,
      addCreditCard: `${BASE_CREDIQ_URL}tarjetaTokenizada/agregarTarjeta`,
      validateCreditCard: `${BASE_CREDIQ_URL}tarjetaTokenizada/validarTarjeta`,
      removeCreditCard: `${BASE_CREDIQ_URL}tarjetaTokenizada/removerTarjeta`,
      changeLoanAlias: `${BASE_CREDIQ_URL}prestamos/actualizarPrestamo`,
      collecters: `${BASE_CREDIQ_URL}prestamos/colectores`,
      loanDetailRequest: `${BASE_CREDIQ_URL}prestamos/solicitarEstadoCuenta`,
      makePayment: `${BASE_CREDIQ_URL}tarjetaTokenizada/realizarPago`,
    },
    storagekeys: {
      userCardsKey: 'crediq:userCards',
      userLoanCardsKey: 'crediq:userCards:loans',
      userLoansDetailKey: 'crediq:userLoansDetail',
      userLoansDetailLightKey: 'crediq:userLoansDetailLight',
      loanNPE: 'crediq:npe',
      collecters: 'crediq:collecters',
      access_token: ''
    }
  },
  smartq: {
    credentials: {
      user: 'GRUPOQDTK',
      passwd: 'Q3JlZGlxMTIw',
      pais: 'CR'
    },
    endpoints: {
      login: `${BASE_SMARTQ_URL}generarToken`,
      limiteVelocidad: `${BASE_SMARTQ_URL}obtenerLimiteVelocidad`,
      setLimiteVelocidad: `${BASE_SMARTQ_URL}configurarVelocidad`,
      enviarComandos: `${BASE_SMARTQ_URL}enviarComando`,
      obtenerRuta: `${BASE_SMARTQ_URL}obtenerRuta`,
      ultimoPunto: `${BASE_SMARTQ_URL}ultimoPunto`,
      consumo: `${BASE_SMARTQ_URL}consumoCombustible`,
      obtenerAlertasConfiguradas: `${BASE_SMARTQ_URL}obtenerAlertas`,
      catalogoAlertas: `${BASE_SMARTQ_URL}obtenerAlertas`,
      catalogoAlertas2: `${BASE_SMARTQ_URL}obtenerAlertasConfigurables`,
      activarAlerta: `${BASE_SMARTQ_URL}activarAlertas`,
      desactivarAlerta: `${BASE_SMARTQ_URL}desactivarAlertas`,
      alertasHistorial: `${BASE_SMARTQ_URL}historialAlertasPlaca`,
      historialAuditoria: `${BASE_SMARTQ_URL}historialAuditoria`,
      generarToken: `${BASE_SMARTQ_URL}generarToken`,
      validarToken: `${BASE_SMARTQ_URL}validarToken`,
      calificacionConductores: `${BASE_SMARTQ_URL}calificacionConductores`,
      calificacionConductoresPorFecha: `${BASE_SMARTQ_URL}calificacionConductoresPorFecha`,
    },
    storagekeys: {
      smartToken: 'smartq:token',
      vehicleFlag: 'isSmart',
      performance: 'TMP_performance',
      speedLimit: 'TMP_speedLimit',
      alertHistory: 'TMP_alerts:history',
      userDriverScore: 'TMP_userScore',
      userDriverGlobalScore: 'TMP_userGlobalScore',
    }
  },
  cronos: {
    login: `${BASE_ENDPOINT_HTTP_BENEFITS}api/user/login/`,
    updateFbID: `${BASE_ENDPOINT_HTTP}api/user/fbid/update/`,
    fbLogin: `${BASE_ENDPOINT_HTTP}api/user/login/fb/`
  },
  migrupoq : {
    autogestion: {
      credentials: {
        user: 'MGQ_APP',
        password: 'ptcU86Uch9WUH4M!',
        platform: 'appGQ',
      },
      authToken: `${BASE_API_AUTOGESTION}authToken`,
      canalesDigitales: `${BASE_API_AUTOGESTION}canalesDigitales`,
      autogestionPage: `${BASE_API_AUTOGESTION}autogestionPage`
    }
  },
  benefits : {
    registerVisit: `${BASE_ENDPOINT_HTTP_BENEFITS}api/visit/register/`,
    coupons: {
      redeemCoupon: `${BASE_ENDPOINT_HTTP_BENEFITS}api/get_coupon/`,
      endpointUserCoupons: `${BASE_ENDPOINT_HTTP_BENEFITS}api/user/coupons/`
    },
    favorite: {
      addFavorite: `${BASE_ENDPOINT_HTTP_BENEFITS}api/user/favorite/add/`,
      removeFavorite: `${BASE_ENDPOINT_HTTP_BENEFITS}api/user/favorite/remove/`,
    }
  }
};
