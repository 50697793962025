import {ChangeDetectorRef, Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {AlertController} from "@ionic/angular";
import {AuthService} from '../../providers/auth.service';
import {BenefitsGraphqlService} from "../../services/graphql/benefits-graphql.service";
import {WorkshopsHelper} from "../workshops/workshopsHelper";
import {map, tap} from "rxjs/operators";
import {CountriesService} from "../../services/graphql/countries.service";
import { AlertInput } from '@ionic/core';

@Injectable({
  providedIn: "root",
})
export class BenefitHelper {
  public selectedCountry = '';

  $currentCountry = new Subject<any>();

  exclusiveList: any = [];
  points: any = [];
  events: any;
  promos: any;

  countries: any [] = [];

  constructor(
    private alertCtrl: AlertController,
    protected authService: AuthService,
    private benefitsGraphql: BenefitsGraphqlService,
    private countriesService: CountriesService
  ) {
    this.cleanConsoleWithBenefits();
    this.getAllCountries();
    this.getCurrentCountryAsObservable().subscribe((resp: any) => {
      this.getEventsByCountry(resp);
      this.getPromosByCountry(resp);
      this.cleanConsoleWithBenefits();
    });
  }

  cleanConsoleWithBenefits() {
    // console.clear();
    // console.log('La consola se ha limpiado desde benefitHelper.');
  }

  getCurrentCountryAsObservable() {
    return this.$currentCountry.asObservable();
  }

  pokeCurrentCountry(val: string) {
    this.$currentCountry.next(val);
  }

  async getEventsByCountry(country: string) {
    this.events = [];
    this.benefitsGraphql.getEventsByCountry(country)
      .subscribe((resp: any) => {
        if (resp?.data?.eventosByCountry) {
          this.events = resp?.data?.eventosByCountry;
        }
      });
  }

  async getPromosByCountry(country: string) {
    this.promos = [];
    this.benefitsGraphql.getPromosByCountry(country)
      .subscribe((resp: any) => {
        if (resp?.data?.promocionesByCountry) {
          this.promos = resp?.data?.promocionesByCountry;
        }
      });
  }

  getPoints() {
    const localData = JSON.parse(localStorage.getItem('httpUserData'));
    if (localData) {
      this.points = localData?.data?.points;
      return this.points || 0;
    } else {
      return 0;
    }
  }

  getDataExclusive(country?: string) {
    this.exclusiveList = [];
    const localData = JSON.parse(localStorage.getItem('httpUserData'));
    if (localData) {
      this.exclusiveList = localData?.data?.exclusive_benefits || [];
      this.exclusiveList = [...this.exclusiveList].filter(x => x.pais.toUpperCase() === this.selectedCountry);
      return this.exclusiveList;
    }
  }

  getAllCountries() {
    this.countriesService.getCountries().subscribe((resp: any) => {
      if (resp?.data?.allCountries) {
        this.countries = resp?.data?.allCountries;
      } else {
        this.countries = [];
      }
    });
  }

  async presentAlertCheckbox() {
    // Transformar los países a inputs para el alerta
    const inputsDinamicos: AlertInput[] = this.countries.map(pais => {
      return {
        name: pais.abbr,
        type: "radio",
        label: pais.name,
        value: pais.abbr,
        checked: this.selectedCountry === pais.abbr,
      };
    }) as AlertInput[];
    const alert = await this.alertCtrl.create({
      cssClass: "buttonCss",
      header: "Seleccionar País",
      inputs: inputsDinamicos,
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
          },
        },
        {
          text: "Aceptar",
          handler: (data) => {
            this.selectedCountry = data;
            this.pokeCurrentCountry(data);
          },
        },
      ],
    });

    await alert.present();
  }
}
