import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {IonInput, ModalController} from '@ionic/angular';
import {UtilService} from '../../providers/util.service';

@Component({
  selector: 'app-confirm-payment-creditcard',
  templateUrl: './confirm-payment-creditcard.component.html',
  styleUrls: ['./confirm-payment-creditcard.component.scss'],
})
export class ConfirmPaymentCreditcardComponent implements OnInit, AfterViewInit {

  @ViewChild("monto", { static: true }) public numberInput: IonInput;
  inputFormatted: any;
  @Input() requiereCodigo: any;
  @Input() loanAmount: any;

  previousAmountValue = '';

  heroForm = {
    amount: ''
  };

  constructor(private modalCtrl: ModalController, private util: UtilService) {}

  ngOnInit() {
    if (this.loanAmount) {
      this.heroForm.amount = this.loanAmount;
    }
  }

  ngAfterViewInit() {
    this.numberInput.getInputElement().then(input => {
      input.onkeyup = ((val: any) => {
        this.onKeyUp(val.key);
        val.preventDefault();
        val.stopImmediatePropagation();
        val.cancelBubble = true;
      });
      input.onclick = ((val: any) => {
        input.setSelectionRange(0, val.target.value.length);
      });
    });
  }

  onKeyUp(val: any) {
    // TODO: Fix this tmp solution
    const _str = this.numberInput.value.toString();
    val = _str[_str.length -1];
    const raw = this.numberInput.value.toString().replace(/,/g, '');
    let amount = '';
    if (raw) {
      const regex = /^[0-9.,]+$/;
      if (val.toString().match(regex)) {
        const raw = this.numberInput.value.toString().replace(/,/g, '');
        amount = this.formatNumberBank(raw);
        this.numberInput.value = amount;
        this.previousAmountValue = amount;
      } else {
        this.numberInput.value = this.previousAmountValue;
      }
    }
  }

  formatNumberBank(numStr) {
    // @ts-ignore
    const isPoint = numStr[numStr.length - 1] === '.';
    const subStr = numStr.substr(0, numStr.length - 1)
    const hasAlreadyPoint = subStr.includes('.');
    // const parsed = parseFloat(numStr, 10);

    if (subStr.includes('.') && isPoint) {
      numStr = numStr.substr(0, numStr.length - 1);
    }
    // if (isNaN(numStr)) {
    //   return null;
    // }
    const nf = new Intl.NumberFormat('en-US');
    const formatted = numStr;
    const decimal = formatted.split(".");
    const hasDecimal = decimal.length > 1;
    // @ts-ignore
    this.inputFormatted = nf.format(formatted);
    if (hasDecimal && formatted[formatted.length - 1] == '0' && (decimal.length > 0)) {
      if (formatted[formatted.length - 2] == '0') {
        this.inputFormatted = `${this.inputFormatted}.0`;
      }

      if (formatted[formatted.length - 2] == '.') {
        this.inputFormatted = `${this.inputFormatted}.`;
      }

      this.inputFormatted = `${this.inputFormatted}0`;
    }

    const hasMoreThan2Decimals = decimal && decimal.length > 1 && decimal[1].length > 2;
    if (hasMoreThan2Decimals) {
      this.inputFormatted = this.inputFormatted.substr(0, this.inputFormatted.length - 1);
    }

    if (isPoint && !hasAlreadyPoint && hasDecimal) {
      this.inputFormatted = `${this.inputFormatted}.`;
    }

    return this.inputFormatted;
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  formatNumber(event) {
    let val = event;
    let char = val[val.length - 1];
    if (!['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ',', '.'].includes(char)) {
      this.heroForm.amount = null;
      val = val.slice(0, -1);
      this.heroForm.amount = val;
      this.numberInput.value = val;
    } else {
      val = val.replaceAll('-', '');
      let regex = /[^\d.+-]|\.(?=.*\.)/g;
      const subst = ``;
      let result = val.replace(regex, subst);
      // const v = +vString;
      let chunks = result.split(".");
      let decimals = chunks[1] ? '.' + chunks[1].substr(0, 2) : '';
      result = Number(`${chunks[0]}${decimals}`);
      const formatValue = result.toLocaleString();
      if (formatValue) {
        this.heroForm.amount = null;
        this.heroForm.amount = char == '.' ? `${formatValue}.` : formatValue;
        this.numberInput.value = char == '.' ? `${formatValue}.` : formatValue;
      } else {
        this.heroForm.amount = "0";
      }
    }
    return false;
  }

  showInputCodeCard() {
    return this.requiereCodigo;
  }

  goOut() {
    const optPayment = {
      ...this.heroForm,
      close: true
    }
    this.modalCtrl.dismiss({
       optPayment
     });
  }

  confirmData() {
    const qtyCvv = this.heroForm.amount;
    const qtyCvvText = this.heroForm.amount.length;
    if (parseFloat(qtyCvv) <= 0 || qtyCvvText == 0) {
      this.util.presentToast('El monto digitado debe de ser mayor a 0.');
      return;
    }
    this.closeSuccess();
  }

  closeSuccess() {
    const optPayment = {
      ...this.heroForm,
      close: false
    }
    this.modalCtrl.dismiss({
      optPayment
    });
  }
}
