import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { AuthService } from './auth.service';
import { DeviceService } from './device.service';
import { environment as env } from '../../environments/environment';
import { HTTP } from '@ionic-native/http/ngx';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UtilService } from './util.service';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { DomSanitizer } from '@angular/platform-browser';
// import { PushNotifComponent } from "../components/push-notif/push-notif.component";
// import {QrCouponPage} from "../pages/my-coupons/qr-coupon/qr-coupon.page";
const API_URLS = env.API_URLS;

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  loginForm: FormGroup;

  push: any;
  country_push_index: any;

  public push_status: any;
  public push_benefit_status: any;

  _correo: any;

  /* API INTERCEPTOR */
  current_url = API_URLS[0];
  current_url_index = 0;
  last_index = 5;

  loginClient = {
    id_cliente: '',
    codigo: '',
    numero_documento: '',
    tipo_documento: '',
    nombre: '',
    pais: '',
    // country_obj: "",
    sociedad: '',
    tipo: '',
    salt: '',
    correo: '',
    celular: ''
  };

  dataDevice = {
    uuid: '',
    is_new_install: '',
    registration_id: '',
    device_id: '',
    type: '',
    name: '',
    celular: '',
    pais: '',
    country: '',
    phone: '',
    app_code: '',
    app_version: '',
    model: '',
    platform: '',
    manufacturer: '',
    os_version: '',
  };

  endpointLoginClient = env.endpoints.endpointLoginClient;
  endpointDevice = env.endpoints.endpointDevice;

  /* Arreglo donde se almacena la respuesta del login */
  _responseLogin: any;
  /* Token FCM */
  _tokenFCM: any;

  constructor(
    private authService: AuthService,
    private deviceService: DeviceService,
    private firebasex: FirebaseX,
    private nativeHttp: HTTP,
    private http: HttpClient,
    private utilService: UtilService,
    private appVersion: AppVersion,
    private plt: Platform,
    private sanitizer: DomSanitizer,
    private alertController: AlertController,
    private modalController: ModalController,
    private router: Router,
    private modalCtrl: ModalController,
  ) {
    if (!this.authService.bs.isDesktop()) {
      this.nativeHttp.setHeader('*', 'Accept', 'application/json');
      this.nativeHttp.setHeader('*', 'Content-Type', 'application/json');
      // this.getWsProfileClient();
    }
  }

  async initPush() {
    if (!this.authService.bs.isDesktop()) {
      this.firebasex.getToken().then((fcmToken) => {
        console.log(fcmToken);
      });
      this.firebasex.onTokenRefresh().subscribe(async (fcmToken) => {
        this.onRegistration(fcmToken).then();
      });
    }
  }

  async onRegistration(fcmToken) {
    this._tokenFCM = await fcmToken;
    window.localStorage.setItem('fcm', this._tokenFCM);
  }

  getFcmToken() {
    return this._tokenFCM ? this._tokenFCM : window.localStorage.getItem('fcm');
  }


  async getWsProfileClient() {
    const existAuthData = this.authService.getProfile();
    if (existAuthData) {
      await this.initPush();
      this.getDatosLoginClient();
      this.convertFormData();
    }
  }

  getDatosLoginClient() {
    const profileClient = this.authService.getProfile();
    const existProfile = !!this.authService.getProfile();
    if (existProfile) {
      this.loginClient.id_cliente = profileClient.id_cliente.toString();
      this.loginClient.codigo = this.authService.getCodigoUsuario();
      this.loginClient.numero_documento = profileClient.numero_documento;
      this.loginClient.tipo_documento = profileClient.tipo_documento;
      this.loginClient.nombre = profileClient.nombre;
      this.loginClient.pais = this.authService.getPaisCodeUsuario().toLowerCase();
      this.loginClient.sociedad = profileClient.sociedad;
      this.loginClient.tipo = profileClient.tipo;
      this.loginClient.correo = this.authService.getEmailUser();
      this.loginClient.salt = this.authService.getPasswd();
      this.loginClient.celular = profileClient.celular;
    }
  }

  convertFormData() {
    const formData = new FormData();
    formData.append('id_cliente', this.loginClient.id_cliente);
    formData.append('codigo', this.loginClient.codigo);
    formData.append('numero_documento', this.loginClient.numero_documento);
    formData.append('tipo_documento', this.loginClient.tipo_documento);
    formData.append('nombre', this.loginClient.nombre);
    formData.append('pais', this.authService.getPaisCodeUsuario().toUpperCase());
    formData.append('sociedad', this.loginClient.sociedad);
    formData.append('tipo', this.loginClient.tipo);
    formData.append('salt', this.loginClient.salt);
    formData.append('correo', this.loginClient.correo);
    formData.append('celular', this.loginClient.celular);

    this.http.post(this.endpointLoginClient, formData).subscribe(async (resp: any) => {
      if (!resp.status) {
        this.utilService.presentToast('No ha sido posible obtener el token');
        return;
      }
      this._responseLogin = resp;
      await this.sendInfoDevice();
    }, err => {
      // console.log('ERR INIT PUSH:::', err);
    });
  }

  returnStatusInstallApp() {
    const flagNewInstall = localStorage.getItem('isNewInstall');
    if (flagNewInstall != null || flagNewInstall !== undefined) {
      return localStorage.getItem('isNewInstall');
    } else {
      return '1';
    }
  }

  async getDeviceInfo() {
    const profileClient = this.authService.getProfile();
    const device = await this.deviceService.getDeviceInfo();
    localStorage.setItem('uuidDevice', this._responseLogin.data.id);
    this.dataDevice.uuid = this._responseLogin.data.id;
    this.dataDevice.is_new_install = this.returnStatusInstallApp();
    this.dataDevice.registration_id = this._tokenFCM;
    this.dataDevice.device_id = device.uuid;
    this.dataDevice.type = device.platform ? device.platform.toLowerCase() : '';
    this.dataDevice.name = device.model;
    this.dataDevice.celular = '';
    this.dataDevice.pais = this.authService.getPaisCodeUsuario().toLowerCase();
    this.dataDevice.country = this.authService.getPaisCodeUsuario().toLowerCase();
    this.dataDevice.phone = '';
    this.dataDevice.app_code = 'App Code';
    this.dataDevice.app_version = await this.appVersion.getVersionNumber();
    this.dataDevice.model = device.model;
    this.dataDevice.platform = device.platform;
    this.dataDevice.manufacturer = device.manufacturer;
    this.dataDevice.os_version = device.version;
    // console.log('DEVICEINFO:::', device);
    // console.log('DEVICEINFOMAP:::', this.dataDevice);
  }

  async sendInfoDevice() {
    await this.getDeviceInfo();
    const formDataDevice = new FormData();
    formDataDevice.append('uuid', this.dataDevice.uuid);
    formDataDevice.append('is_new_install', '' + this.dataDevice.is_new_install);
    formDataDevice.append('registration_id', this.dataDevice.registration_id);
    formDataDevice.append('device_id', this.dataDevice.device_id);
    formDataDevice.append('type', this.dataDevice.type);
    formDataDevice.append('name', this.dataDevice.name);
    formDataDevice.append('celular', this.dataDevice.celular);
    formDataDevice.append('pais', this.dataDevice.pais);
    formDataDevice.append('country', this.dataDevice.country);
    formDataDevice.append('phone', this.dataDevice.phone);
    formDataDevice.append('app_code', this.dataDevice.app_code);
    formDataDevice.append('app_version', this.dataDevice.app_version);
    formDataDevice.append('model', this.dataDevice.model);
    formDataDevice.append('platform', this.dataDevice.platform);
    formDataDevice.append('manufacturer', this.dataDevice.manufacturer);
    formDataDevice.append('os_version', this.dataDevice.os_version);

    // Validar reporte de instalaciones solo disponibles para moviles
    if (!this.utilService.bs.isDesktop()) {
      this.http.post(this.endpointDevice, formDataDevice).subscribe(result => {
        // console.log('RESPT SEND INDO DEVICE:::', result);
        //this.utilService.presentToast("Se ingreso el dispositivo");
        localStorage.setItem('isNewInstall', '0');
      }, error => {
        // console.log('ERROR SEND INFO DEVICE:::', error);
      });
    }

  }

  async presentAlert(head: string, subheader?: string, msg?: string) {
    const alert = await this.alertController.create({
      header: head,
      subHeader: subheader,
      message: msg,
      buttons: [
        // {
        //   text: 'Ver mis cupones',
        //   role: 'success',
        //   handler: (data: any) => {
        //     this.router.navigate(['/benefit-tabs/my-coupons']);
        //   }
        // },
        {
          text: 'Aceptar',
          role: 'success',
          handler: (data: any) => {
            // console.log('Todo ok :::', data);
          }
        }
      ],
    });
    await alert.present();
  }

  applyBenefit(benefit: any) {
    try {
      this.utilService.presentLoading();
      const uuid = this.authService.getUserCronosData().id;
      const formDataRedeemCoupon = new FormData();
      formDataRedeemCoupon.append('user_uuid', uuid);
      formDataRedeemCoupon.append('benefit_id', benefit.id);
      formDataRedeemCoupon.append('is_exclusive', benefit.isExclusive);
      this.http.post(env.benefits.coupons.redeemCoupon, formDataRedeemCoupon).subscribe((arg: any) => {
        if (arg.msg) {
          this.presentAlert('Nuevo Cupón', '', arg.msg);
        }
        this.utilService.dismissLoading();
      }, (err: any) => {
        this.utilService.dismissLoading();
        this.utilService.presentToast('Ha ocurrido un error.', err);
      });
    } catch (e) {
      setTimeout(() => {
        this.utilService.dismissLoading();
      }, 700);
      this.utilService.presentToast('Ha ocurrido un error.');
    }
  }

  // async openQrCoupon(coupon: any) {
  //   const modal = await this.modalController.create({
  //     component: QrCouponPage,
  //     id: 'qrCoupon',
  //     swipeToClose: true,
  //     backdropDismiss: false,
  //     componentProps: {
  //       coupon: coupon
  //     }
  //   });
  //   await modal.present();
  // }

}
