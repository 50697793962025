import { ErrorHandler } from '@angular/core';

import * as Sentry from 'sentry-cordova';
import { environment } from '../../environments/environment';

export class SentryIonicErrorHandler extends ErrorHandler {
  handleError(error) {
    super.handleError(error);
    try {
      Sentry.captureException(error.originalError || error);
    } catch (e) {
      if (!environment.production) {
        console.error(e);
      }
    }
  }
}
