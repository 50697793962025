import { Injectable } from '@angular/core';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { AuthService } from './auth.service';

declare let gtag: any;

@Injectable({
  providedIn: 'root'
})

export class AnalyticsService {

  // MIGRUPOQ
  static OPEN_LOGIN = 'open_login';
  static OPEN_LOGIN_SUCCCESS = 'Inicios_de_Sesion';
  static OPEN_EMERGENCY = 'Boton_Emergencia';
  static OPEN_MEMBERSHIP = 'Membresia';
  static OPEN_CONTACT = 'Contacto';
  static OPEN_APPOINTMENT = 'open_appt';
  static OPEN_HELP = 'Ayuda';
  static OPEN_ABOUT = 'Acerca_de';
  static OPEN_APPT_HISTORY = 'Historial_de_Mantenimiento';
  static OPEN_APPT_RESCHEDULE = 'Modificar_cita';
  static OPEN_PROFILE = 'Perfil';
  static OPEN_BENEFITS = 'Beneficios';
  static COMPLETE_APPOINTMENT = 'Nueva_cita';
  static OPEN_MIGQ_FLOW = 'MiGrupoQ';

  static ONOFF_SPEED_LIMIT = 'Act_Desac_Exceso_velocidad';
  static ONOFF_POTENTIAL_COLLISION = 'Act_Desac_Posible_colision';
  static EDIT_INFORMATION_ALIASES = 'Editar_Información_Alias';
  static EDIT_INSURANCE_INFORMATION = 'Editar_informacion_Seguro';

  static MGQ_PAYMENT_APPT = 'Pago_Linea_Postventa';
  static MGQ_UPDATE_PROFILE = 'Actualizar_Perfil';


  // CREDIQ
  static CQ_OPEN_HOME = 'Home_CrediQ';
  static CQ_OPEN_LOAN_DETAILS = 'Ver_detalles';
  static CQ_OPEN_LOAN_STATEMENT = 'Estado_de_cuenta';
  static CQ_OPEN_PAYMENT_CARDS = 'Carne_de_pago';
  static CQ_OPEN_PAYMENT_SHEET = 'Tarjetas';
  static CQ_QUOTE_SERVICONTRATO = 'Cotizacion_servicontratos';
  static CQ_QUOTE_CONFIRMAR_GENERAR_SERVICONTRATO = 'Confirmar_Generar_Cotizacion_servicontratos';

  // SMARTQ
  static SQ_STATUS_VEHICLE_WORKSHOP = 'Estatus_vehiculo_Taller';
  static SQ_OPEN_SMARTQ = 'SmartQ';
  static SQ_SPEED_LIMIT = 'Limite_velocidad';
  static SQ_ROUTES = 'Recorridos';
  static SQ_MAP = 'Ver_Mapa';
  static SQ_SHARE_WHASTAPP = 'Compartir_Whastapp';
  static SQ_ALERTS = 'Alertas';
  static SQ_CONFIG_ALERTS = 'Configurar_Alertas';
  static SQ_ONOFF_BATTERY = 'Act_Desac_Bateria';
  static SQ_ONOFF_POWER_ON = 'Act_Desac_Encendido';
  static SQ_ONOFF_POWER_OFF = 'Act_Desac_Apagado';
  static SQ_ONOFF_TOWING_VEHICLE = 'Act_Desac_Vehiculo_Grúa';
  static SQ_AUDIT_ALERTS = 'Aditoria_alertas';
  static SQ_COMMANDS = 'Comandos';
  static SQ_OFF_LOCK_ENGINE = 'Apagar_bloquear_motor';
  static SQ_UNLOCK_ENGINE = 'Desbloquear_Motor';
  static SQ_LOCK_SECURE = 'Bloquear_Seguros';
  static SQ_UNLOCK_SECURE = 'Desbloquear_Seguros';
  static SQ_AUDIT_COMMANDS = 'Aditoria_comandos';
  static SQ_PAY_ONLINE = 'Pago_Linea_CrediQ';
  static SQ_REPROGRAMMING_APPOINTMENT = 'Reprogramar_cita';
  static SQ_DELETE_APPOINTMENT = 'Eliminar_cita';
  static SQ_MEMBERSHIP_EXTENSION = 'Extesion_membresía';
  static SQ_DISASSOCIATE_VEHICLE = 'Desasociar_vehiculo';
  static SQ_DRIVING_HABITS = 'Habitos_Conduccion';
  static SQ_EMERGENCY_24_7 = 'Atencion_24_7_SmartQ';

  constructor(
    private authService: AuthService,
    private firebasex: FirebaseX,
  ) {
  }

  logEvent(event, page) {
    if (this.authService.bs.isDesktop()) {
      gtag('event', event, {
        event_category: page,
        event_label: event,
        value: this.authService.getProfile() && this.authService.getProfile().id_cliente || ''
      });
      return;
    }

    this.firebasex
      .logEvent(event, {
        page,
      })
      .then((res) => console.log(res))
      .catch((error) => console.error(error));
  }

}
