import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApptStatusHelper} from "../../../../helpers/migrupoq/ApptStatusHelper";
import {UtilService} from "../../../../providers/util.service";
import {
  ApproveQuoteResponse,
  CITA_AUTORIZACION,
  CITA_CONFIRMADA,
  CITA_FINALIZADA,
  CITA_LAVADO,
  CITA_NA,
  CITA_PROCESO,
  CITA_REPUESTOS,
  DownloadJobResponse,
  OrderQuote,
  OrderQuoteResponse,
  VehicleStatus,
  VehicleStatusResponse
} from './models';
import {VehicleHelper} from "../../../../helpers/VehicleHelper";
import {ModalController, NavController, Platform, PopoverController} from "@ionic/angular";
import {forkJoin, from, Subscription} from "rxjs";
import {QuoteStatusModalComponent} from "../../../../components/migq/quote-status-modal/quote-status-modal.component";
import {AnalyticsService} from '../../../../providers/analytics.service';
import {CatalogHelper} from "../../../../helpers/CatalogHelper";
import {DomSanitizer} from "@angular/platform-browser";
import {InAppBrowser} from "@ionic-native/in-app-browser/ngx";
import {LoanHelper} from "../../../../helpers/crediq/LoanHelper";
import {take} from "rxjs/operators";
import {AuthService} from "../../../../providers/auth.service";
import {BASE_GQ_URL, CQ_URL_APPT_FAC_RESP} from "../../../../../environments/environment";
import {
  PaymentWebAddCardFrameComponent
} from "../../../../components/payment-web-add-card-frame/payment-web-add-card-frame.component";
import {PaymentApptWebComponent} from "../../../../components/payment-appt-web/payment-appt-web.component";

@Component({
  selector: 'app-appt-status',
  templateUrl: './appt-status.page.html',
  styleUrls: ['./appt-status.page.scss'],
})
export class ApptStatusPage implements OnInit, OnDestroy {

  public vehicleStatus: VehicleStatusResponse = new VehicleStatusResponse();
  public currentVehicleStatus: VehicleStatus[] = [];
  currentVehicleFinalStatus = '';
  currentVehicleFinalStatusDescription = '';
  orderQuotes: OrderQuoteResponse[] = [];
  vehiclesStatusesQuote: VehicleStatus[] = [];
  $vehicleStatus: Subscription;
  backBtnSubscriber: Subscription;
  isLoading = false;
  isLoadingQuotes = false;
  currentVehicle;
  ASSET_URL = UtilService.ASSET_URL;
  vehiclesImages: any;

  isPaymentInProgress = false;

  intervalPago: any = null;

  public appStatuses = {
    CITA_CONFIRMADA,
    CITA_FINALIZADA,
    CITA_AUTORIZACION,
    CITA_REPUESTOS,
    CITA_PROCESO,
    CITA_LAVADO,
    CITA_NA,
  };

  migqUrl = '';
  cQUrlApptFacResp = '';

  constructor(
    public apptStatusHelper: ApptStatusHelper,
    protected vehicleHelper: VehicleHelper,
    protected utilService: UtilService,
    protected popoverCtrl: PopoverController,
    protected modalCtrl: ModalController,
    protected platform: Platform,
    private catalogHelper: CatalogHelper,
    protected navCtrl: NavController,
    protected analitycs: AnalyticsService,
    private sanitizer: DomSanitizer,
    private inApp: InAppBrowser,
    private loanHelper: LoanHelper,
    private authService: AuthService
  ) {
    this.vehiclesImages = this.catalogHelper.getVehiclesImages();
    this.currentVehicle = this.vehicleHelper.getCurrentVehicle();
    this.initData();
    this.migqUrl = BASE_GQ_URL;
    this.cQUrlApptFacResp = CQ_URL_APPT_FAC_RESP;
  }

  ngOnInit() {


    this.checkHelperLoading(this.currentVehicle.SOCIEDAD);

    this.$vehicleStatus = this.apptStatusHelper.getApptStatusObservable().subscribe((data) => {

      if (!data.vehicle) {
        this.isLoading = false;
      }

      if (data.society === this.currentVehicle.SOCIEDAD && data?.vehicle?.VIN === this.currentVehicle.VIN) {
        // reinitialize order quotes
        this.orderQuotes = [];

        if (this.isLoading) {
          // this.utilService.dismissLoading();
          this.isLoading = false;
        }
        this.initData();
      }
    });

    this.backBtnSubscriber = this.platform.backButton.subscribeWithPriority(999999, () => {
      return this.navCtrl.back({animated: true});
    });

    this.analitycs.logEvent(AnalyticsService.SQ_STATUS_VEHICLE_WORKSHOP, 'appt-status');
  }

  ionViewDidLeave() {
    this.isLoading = false;
  }

  checkHelperLoading(society) {
    this.isLoading = this.apptStatusHelper.isLoadingApptStatus(society);
  }

  refreshStatuses() {
    // Re-fetch new data status
    this.apptStatusHelper.loadApptStatuses(this.currentVehicle.SOCIEDAD);
    this.checkHelperLoading(this.currentVehicle.SOCIEDAD);
  }

  ngOnDestroy() {
    if (this.$vehicleStatus) {
      this.$vehicleStatus.unsubscribe();
    }
    if (this.backBtnSubscriber) {
      this.backBtnSubscriber.unsubscribe();
    }
  }

  initData() {
    this.vehicleStatus = this.apptStatusHelper.getApptStatus(this.currentVehicle.SOCIEDAD);
    // debugger
    try {
      this.currentVehicleStatus = this.vehicleStatus && this.vehicleStatus.getStatusesByVIN(this.currentVehicle.VIN) || [];

      this.currentVehicleFinalStatus = this.vehicleStatus.getFinalStatusByVIN();
      this.currentVehicleFinalStatusDescription = this.vehicleStatus.getFinalStatusDescriptionByVIN();

      // If Appt status require quote info
      // @ts-ignore
      this.vehiclesStatusesQuote = this.vehicleStatus.filterByStatusAuthPending(this.currentVehicleStatus);
      if (this.vehiclesStatusesQuote.length) {
        this.orderQuotes = [];
        this.vehiclesStatusesQuote.map(vehicleStatus => {
          this.getQuoteByOrder(vehicleStatus);
        });
      }
    } catch (ex) {
      console.log('@@EXC:: ', ex);
    }
  }

  /**
   * Get quote by order number
   * @param vehicleStatus vehicleStatus object
   */
  getQuoteByOrder(vehicleStatus: VehicleStatus) {
    this.isLoadingQuotes = true;
    this.apptStatusHelper.getQuoteByOrderID(vehicleStatus.VBELN).then((data) => {
      this.isLoadingQuotes = false;

      const dataResponseVhcle = data.data.map(x => x.VHCLE);

      const alreadyInArray = this.orderQuotes.find(quotes => {
        return quotes.data.find(quote => dataResponseVhcle.includes(quote.VHCLE));
      });

      if (!alreadyInArray) {
        return this.orderQuotes.push(new OrderQuoteResponse(data));
      }
    }, (err) => {
      this.isLoadingQuotes = false;
    });
  }

  get finalStatus() {
    return this.vehicleStatus.getApptStatusObjByVIN(this.currentVehicle.VIN);
  }

  get showPaymentButton() {
    return this.finalStatus.BTN_PAGO_LINEA;
  }

  downloadJobs(orderNumber) {
    this.utilService.presentLoading();
    this.apptStatusHelper.downloadJobs(orderNumber).then((data) => {
      this.utilService.dismissLoading();
      const downloadResponse: DownloadJobResponse = new DownloadJobResponse(Array.isArray(data) ? data[0] : data);

      // debugger
      if (downloadResponse.hasError()) {
        return this.utilService.presentToast(downloadResponse.descripcion || 'Error al obtener archivo PDF de la proforma.');
      }

      this.utilService.saveAndOpenPdf(downloadResponse.dataPDF, `orden-${orderNumber}-detalle`);

    }, (err) => {
      this.utilService.dismissLoading();
    });
  }


  async showQuoteModal(ev, quote: OrderQuote) {

    const modal = await this.modalCtrl.create({
      component: QuoteStatusModalComponent,
      componentProps: {
        quote,
      },
    });

    modal.onDidDismiss().then((data: any) => {
      if (data.data && data.data.response) {
        const response: ApproveQuoteResponse = data.data.response;

        if (response.hasError()) {
          return this.utilService.presentToast(response.descripcion || 'Falló al aprobar presupuesto.');
        }
        // Re-fetch new data status
        this.refreshStatuses();
      }
    });

    return await modal.present();
  }

  backFn() {
    this.navCtrl.back();
  }

  generate_random_number() {
    return Math.floor(Math.random() * 9000) + 1000;
  }

  get_country_user() {
    const countryUser = this.authService.getPaisUsuario().toLowerCase();
    const countryCodes = {
      'costa rica': 1,
      'costa_rica': 1,
      'gevesa': 2,
      'guatemala': 3,
      'honduras': 4,
      'nicaragua': 5,
      'salvador': 6,
      'guatemala_mb': 7,
      'nicaragua_am': 8,
      'costa_rica_am': 9,
      'honduras_am': 10,
      'panama': 11,
    };
    return countryCodes[countryUser] || 0;
  }

  async openIframeModalAddNewCard(url: string) {
    const trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    const modal = await this.modalCtrl.create({
      component: PaymentApptWebComponent,
      componentProps: {url: trustedUrl},
      id: 'frame-payment-appt-web-modal',
      backdropDismiss: false,
      cssClass: 'custom-modal'
    });
    modal.onDidDismiss().then((data: any) => {
        this.refreshStatuses();
    });
    return await modal.present();
  }

  payQuote() {

    this.analitycs.logEvent(AnalyticsService.MGQ_PAYMENT_APPT, 'appt-status');

    const fac_id = this.finalStatus?.VBELN;
    // const fac_id = this.currentVehicleStatus[0].VBELN;
    // const htmlWindowSub = from(this.loanHelper.getPaymentWindowCode());
    const billData = from(this.apptStatusHelper.getPaymentInfo(fac_id));
    this.utilService.presentLoading();

    billData.pipe(take(1)).subscribe({
      complete: () => this.utilService.dismissLoading(),
      next: (billDataResponse) => {
        const dataFormPayment = {
          country_pay: billDataResponse?.BUKRS,
          id_pago: this.generate_random_number(),
          client_code: this.authService.getCodigoUsuario(),
          client_type: this.authService.getProfile().tipo,
          id_fac: fac_id,
          total_order: billDataResponse?.TOTAL_ORDEN,
          client_name: this.authService.getProfile().nombre,
          client_email: this.authService.getProfile().correo,
          id_department: this.authService.getProfileDetail().provincia,
          fac_moneda: billDataResponse?.MONEDA
        };
        const testPay = this.apptStatusHelper.generateHTMLForPaymentCronos(dataFormPayment);

        const parser = new DOMParser();
        // @ts-ignore
        const doc = parser.parseFromString(testPay, 'text/html');
        const script = doc.createElement('script');
        script.textContent = `
          document.addEventListener('DOMContentLoaded', function () {
              const urlRetorno = document.getElementById('fac_sitio_origen_url_resp');
              urlRetorno.value = '${this.cQUrlApptFacResp}';
              const submitButton = document.querySelector('button[type="submit"]');
              submitButton.click();
          }, false);
        `;

        doc.head.appendChild(script);
        const serializer = new XMLSerializer();
        const htmlStringWithScript = serializer.serializeToString(doc);

        this.loanHelper.createTransactionAFS({
          user_id: this.loanHelper.authService.getCodigoUsuario(),
          body: htmlStringWithScript
        }).then(
          (response: any) => {
            const data = JSON.parse(response.data);
            void this.openIframeModalAddNewCard(`https://admin.gq.cronos.mobi/pel/transactions-afs/${data.uid}`);
          }
        );
      },
    });
  }
}
