import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavController, Platform, PopoverController } from '@ionic/angular';
import { AuthService } from '../../providers/auth.service';
import { UtilService } from '../../providers/util.service';
import { SmartHelper } from '../../helpers/smartq/SmartHelper';
import { Subscription } from 'rxjs';
import { VehicleHelper } from '../../helpers/VehicleHelper';
import { LogService } from '../../providers/logging.service';
import { Device } from '@ionic-native/device/ngx';

@Component({
  selector: 'app-speed-limit',
  templateUrl: './speed-limit.component.html',
  styleUrls: ['./speed-limit.component.scss'],
})
export class SpeedLimitComponent implements OnInit, OnDestroy {
  backBtnSubscriber: Subscription;

  vehicle: any;
  speedLimit: any;
  updated = false;

  constructor(
    private popoverCtrl: PopoverController,
    private smartHelper: SmartHelper,
    private authService: AuthService,
    public platform: Platform,
    private utilHelper: UtilService,
    private navCtrl: NavController,
    private logService: LogService,
    private device: Device) {
  }

  ngOnInit() {
    this.backBtnSubscriber = this.platform.backButton.subscribeWithPriority(999999, () => {
      if (!this.authService.isAnyPopupActive) {
        return this.dismissModal();
      }
    });
  }

  ngOnDestroy() {
    if (this.backBtnSubscriber) {
      this.backBtnSubscriber.unsubscribe();
    }
  }

  saveSpeedLimit() {
    this.utilHelper.presentLoading();
    const deviceCompleto = JSON.stringify(this.logService.getInfoSecurity({ descripcion: `Envio de comando SQ - Configurando limite de velocidad` }));

    this.smartHelper.saveSpeedLimitWS(this.speedLimit.velocidad, this.vehicle, deviceCompleto).then((data: any) => {
      this.utilHelper.dismissLoading();
      // console.log(data);
      if (!data.hasOwnProperty('error')) {
        this.utilHelper.presentToast('Dato actualizado');
        this.updated = true;
        this.dismissModal();
      } else {
        this.utilHelper.presentAlert({message: data.error});
      }
    }).catch((err) => {
      // console.log(err);
    });
  }

  async dismissModal() {
    await this.popoverCtrl.dismiss({updated: this.updated, speedLimit: this.speedLimit.velocidad});
  }
}
