import StorageHelper from "./../StorageHelper";
import { BehaviorSubject, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { UtilService } from "../../providers/util.service";
import { AuthService } from "../../providers/auth.service";
import { ServicesService } from "../../providers/services.service";
import {VehicleHelper} from '../VehicleHelper';

@Injectable({
  providedIn: "root",
})

export class ServicontratosHelper {

  codCliente: any;
  tokenCliente: any;
  paisCliente: any;

  listaServicontratos: any[] = [];
  listaServicontratosTemp: any[] = [];

  currentVehicle = {
    VHCLE: '',
    MATNR: '',
    SOCIEDAD: '',
    VIN: ''
  };

  constructor(
    private utilService: UtilService,
    private auth: AuthService,
    private serviceService: ServicesService,
    private vehicleHelper: VehicleHelper
  ) {
  }

  getDataUser() {
    this.codCliente = this.auth.getCodigoUsuario();
    this.tokenCliente = this.auth.getToken();
    this.paisCliente = this.auth.getPaisUsuario();
  }

  clearListadoServicontratos() {
    this.listaServicontratosTemp = [];
    this.listaServicontratos = [];
  }

  getCurrentVehicle() {
    this.currentVehicle.MATNR = this.vehicleHelper.getCurrentVehicle().MATNR;
    this.currentVehicle.VHCLE = this.vehicleHelper.getCurrentVehicle().VHCLE;
    this.currentVehicle.SOCIEDAD = this.vehicleHelper.getCurrentVehicle().SOCIEDAD;
    this.currentVehicle.VIN = this.vehicleHelper.getCurrentVehicle().VIN;
  }

  getListadoServicontratos() {
    this.getCurrentVehicle();
    this.utilService.presentLoading();
    this.getDataUser();
    this.serviceService
      .getListadoServicontratos(this.codCliente, this.tokenCliente)
      .then((response: any) => {
        if (response === 'No se detectan registros') {
          this.utilService.dismissLoading();
          return this.utilService.presentToast(response);
        } else {
          let paisWhithSociety: string = this.currentVehicle.SOCIEDAD;
          paisWhithSociety = paisWhithSociety.toUpperCase();
          this.listaServicontratosTemp = response[paisWhithSociety];
          this.listaServicontratos = this.listaServicontratosTemp.filter(x => x.VIN === this.currentVehicle.VIN);
          this.utilService.dismissLoading();
        }
      })
      .catch((error: any) => {
        console.log("🚀 ~ file: ServicontratosHelper.ts ~ ServicontratosHelper ~ getListadoServicontratos ~ error", error);
        this.utilService.dismissLoading();
      });
  }
}
