import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { Device } from '@ionic-native/device/ngx';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  sim_data: any;
  device_data: any;
  sim_permission: any;

  constructor(
    private storage: Storage,
    private device: Device,
  ) {
  }

  /**
   * @method getSimInfo
   * @description get SIM info
   */

  async getDeviceInfo() {
    return this.device_data = await this.device;
  }


  /**
   * Check DN promise
   */
}
