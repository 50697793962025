import { Injectable } from '@angular/core';
import { environment as env } from '../../../environments/environment';
import { AuthService } from '../auth.service';
import { BaseService } from "../../services/http/base.service";
import { UtilService } from "../util.service";

@Injectable({
  providedIn: 'root'
})
export class DriverQualificationService {

  user: string = env.smartq.credentials.user;
  passwd: string = env.smartq.credentials.passwd;

  constructor(
    private authService: AuthService,
    private bs: BaseService) { }

  generarToken() {
    return this.bs.post([`${env.smartq.endpoints.generarToken}`.replace(/\[cr\]/gi, 'cr')], {
      usuario: this.user,
      pass: this.passwd,
    });
  }

  validarToken(token: any) {
    return this.bs.post([`${env.smartq.endpoints.validarToken}`.replace(/\[cr\]/gi, 'cr')], {
      usuario: this.user,
      token: token,
    });
  }

  calificacionConductores(vehicle, selectedMonth = null) {
    const now = new Date();
    let currentYear = selectedMonth ? new Date(selectedMonth).getFullYear() : now.getFullYear();
    const selectedDate = new Date(currentYear, selectedMonth ? new Date(selectedMonth).getMonth() : now.getMonth(), now.getDay()).toString();
    const firstDateDay = UtilService.getFirstDateDay(selectedDate);
    const endDateDay = UtilService.getLastDateDay(selectedDate);
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    return this.bs.post([ `${env.smartq.endpoints.calificacionConductoresPorFecha}`.replace(/\[cr\]/gi, country) ], {
      usuario: this.user,
      vin: vin,
      fechaIni: firstDateDay,
      fechaFin: endDateDay,
    }, true, 'smartq');
  }

}
