import { Component, OnInit } from '@angular/core';
import {ToastController} from '@ionic/angular';

@Component({
  selector: 'app-notifications-toast',
  templateUrl: './notifications-toast.component.html',
  styleUrls: ['./notifications-toast.component.scss'],
})
export class NotificationsToastComponent implements OnInit {

  constructor(private toastController: ToastController) { }

  ngOnInit() {}

  async presentToast(msg, time) {
    const toast = await this.toastController.create({
      message: msg,
      duration: time
    });
    toast.present();
  }
}

