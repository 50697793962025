import StorageHelper from '..//StorageHelper';
import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService} from '../../providers/auth.service';
import {environment as env} from '../../../environments/environment';
import {Collecter} from '../../models/global';
import {CatalogService} from '../../providers/crediq/catalog.service';
import {UtilService} from '../../providers/util.service';

@Injectable({
  providedIn: 'root'
})

export class CollectHelper extends StorageHelper {
  private loadingCollecters = false;
  private storageKeys = env.crediq.storagekeys;

  private collecters: Collecter[];

  private $collecters = new Subject<any>();

  constructor(
    private authService: AuthService,
    private utilService: UtilService,
    private catalogService: CatalogService,
  ) {
    super();
  }

  setIsLoadingCollecters(status: boolean) {
    this.loadingCollecters = status;
  }

  setCollecters(collecters) {
    this.collecters = collecters;
    window.localStorage.setItem(env.production ? `dev:${this.storageKeys.collecters}:${this.authService.getPaisCodeUsuario()}` : `prod:${this.storageKeys.collecters}:${this.authService.getPaisCodeUsuario()}`, JSON.stringify(collecters));
  }

  getCollecters(): any {
    return this.collecters && this.collecters || JSON.parse(window.localStorage.getItem(env.production ? `dev:${this.storageKeys.collecters}:${this.authService.getPaisCodeUsuario()}` : `prod:${this.storageKeys.collecters}:${this.authService.getPaisCodeUsuario()}`)) || null;
  }

  pokeCollecters(data) {
    this.$collecters.next(data && data || this.getCollecters());
  }

  getCollectersObservable() {
    return this.$collecters.asObservable();
  }

  isLoadingCollecters() {
    return this.loadingCollecters;
  }

  getCollectersWS(skipCache = false) {
    if (!this.isValidKeyETA(env.production ? `dev:${this.storageKeys.collecters}:${this.authService.getPaisCodeUsuario()}` : `prod:${this.storageKeys.collecters}:${this.authService.getPaisCodeUsuario()}`, 7) || skipCache) {
      if (!skipCache) {
        this.setIsLoadingCollecters(true);
      }
      this.catalogService.getCollecters(this.authService.getPaisCodeUsuario()).then((result: any) => {
        this.setIsLoadingCollecters(false);
        this.setCollecters(result.colectores || []);
        this.pokeCollecters(result.colectores || []);
      }).catch(error => {
        this.setIsLoadingCollecters(false);
        this.utilService.presentToast('Problemas de conexión: COLLC');
      });
    }
  }
}
