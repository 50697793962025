import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { SoapService } from '../services/soap/soap.service';
import { Subject } from 'rxjs';
import { QCar } from '../models/global';
import { UtilService } from "./util.service";

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  response: any;
  url = env.API_ENDPOINT;
  url_reset_pass = env.API_ENDPOINT;
  codigo = env.WS_CODIGO;

  hayCitas: any;
  sinCitas: any;
  mensaje: any;

  appointments: any;
  $userAppts = new Subject<any>();

  constructor(public soapService: SoapService, private utilService: UtilService) {
  }


  pokeUserAppts(data) {
    this.$userAppts.next(data);
  }

  getUserApptsObservable() {
    return this.$userAppts.asObservable();
  }

  getUserAppts(): any {
    return this.appointments && this.appointments || JSON.parse(window.localStorage.getItem('userAppts')) || [];
  }

  setUserAppts(userAppts) {
    this.appointments = userAppts;
    window.localStorage.setItem('userAppts', JSON.stringify(userAppts));
  }

  getHistorial(sociedad, codigo_sap, inventario, placa = '') {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      codigo_sap,
      inventario,
      placa
    };
    // console.log('atributos');
    // console.log(atributos);
    return this.soapService.post(url, 'consulta_historial_vehiculo_2', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  // getHistorial(sociedad, codigo_sap, inventario, placa = '') {
  //   const url = `${this.url}`;
  //   const atributos = {
  //     cod: this.codigo,
  //     sociedad,
  //     codigo_sap,
  //     inventario
  //   };
  //   return this.soapService.post(url, 'consulta_historial_vehiculo', atributos)
  //     .then((result: any) => {
  //       return JSON.parse(result.return);
  //     }).catch(error => {
  //       return error;
  //     });
  // }

  getPaquetesCliente(sociedad, cliente_sap, kilometraje, LABVAL_TYPE, inventario) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      cliente_sap,
      kilometraje,
      LABVAL_TYPE,
      inventario
    };
    return this.soapService.post(url, 'consultar_paquetes_cliente', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  getTalleres(sociedad, paquete, danos, id_marca, tipoVhcle, vin) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      paquete,
      danos,
      id_marca,
      tipoVhcle,
      vin
    };
    return this.soapService.post(url, 'consultar_talleres', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  crearCitaTaller(sociedad, datos_cliente, datos_cita, contacto) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      datos_cliente,
      datos_cita,
      contacto
    };
    return this.soapService.post(url, 'crear_cita_taller', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  crearCitaAp(sociedad, datos_cliente, datos_cita, contacto) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      datos_cliente,
      datos_cita,
      contacto
    };
    return this.soapService.post(url, 'crear_cita_AP', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  obtenerMantenimientosAutopits(sociedad, app) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      app
    };
    return this.soapService.post(url, 'consulta_mantenimentos_autopits', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  obtenerAutopits(sociedad) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad
    };
    return this.soapService.post(url, 'consultar_autopits', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  obtenerDisponibilidadTaller(sociedad, paquete, revision, detalle, otro, taller, value_sqc) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      paquete,
      revision,
      detalle,
      otro,
      taller,
      value_sqc
    };
    return this.soapService.post(url, 'seleccionar_disponibilidad_taller', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  obtenerDisponibilidadAp(sociedad, datos) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      datos
    };
    return this.soapService.post(url, 'seleccionar_disponibilidad_AP', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  obtenerCitas(cliente_codigo) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      cliente_codigo
    };
    return this.soapService.post(url, 'obtener_citas', atributos)
      .then((result: any) => {
        // console.log('estamos en el result');
        // console.log(result);
        return JSON.parse(result.return);
      }).catch(error => {
        // console.log('se recibio error');
        // console.log(error);
        return error;
      });
  }

  eliminarCita(sociedad, cliente_codigo, num_cita, ap) {
    let url = `${this.url}`;
    let atributos = {
      cod: this.codigo,
      sociedad,
      cliente_codigo,
      num_cita,
      ap
    };
    return this.soapService.post(url, 'eliminar_cita', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  reprogramarCitaTaller(sociedad, codigo_cliente, num_cita, id_taller, fecha, hora) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      codigo_cliente,
      num_cita,
      id_taller,
      fecha,
      hora: hora + '00'
    };
    return this.soapService.post(url, 'repogramar_cita', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  reprogramarCitaAutopits(sociedad, codigo_cliente, num_cita, id_taller, fecha, hora, tipo_cliente, tipo_vhcle, cedula, inventario, contacto, origen) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      codigo_cliente,
      num_cita,
      id_taller,
      fecha,
      hora,
      tipo_cliente,
      tipo_vhcle,
      cedula,
      inventario,
      contacto,
      origen
    };
    return this.soapService.post(url, 'reprogramar_citaAP', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  autenticarDatos(id_pais: string, sidchk: string, nmrchk: string, lnkchk: string) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      id_pais: id_pais,
      sidchk: sidchk,
      nmrchk: nmrchk,
      lnkchk: lnkchk
    };
    return this.soapService.post(url, 'datos_restaurar_clave', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        // console.log(error);
      });
  }

  //obtiene_moneda_cliente_sociedad
  getMonedaClientePais(cod_sociedad: string) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      id_pais: cod_sociedad
    };
    // debugger;
    return this.soapService.post(url, 'obtiene_moneda_cliente_sociedad', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  //tipos_pago
  getTiposPagoCliente(cod_sociedad: string, moneda: string) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      id_pais: cod_sociedad,
      moneda: moneda
    };
    // debugger;
    return this.soapService.post(url, 'tipos_pago', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  restaurarClave(id_pais: string, codigo_cliente: string, tipo_cliente: string, clave: string, sidchk: string, nmrchk: string, lnkchk: string) {
    const url = `${this.url_reset_pass}`;
    const atributos = {
      cod: this.codigo,
      id_pais: id_pais,
      codigo_cliente: codigo_cliente,
      tipo_cliente: tipo_cliente,
      clave: clave,
      sidchk: sidchk,
      nmrchk: nmrchk,
      lnkchk: lnkchk
    };
    return this.soapService.post(url, 'restaurar_clave', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  obtenerMonedaClienteSociedad(cod_sociedad: string) {
    const atributos = {
      cod: this.codigo,
      cod_sociedad: cod_sociedad,
    };
    const url = `${this.url}`;
    const urlService = 'obtiene_moneda_cliente_sociedad';
    return this.soapService.post(url, urlService, atributos)
      .then((result: any) => {
        const jsonResponse = JSON.parse(result.return);
        return jsonResponse;
      }).catch(error => {
        return error;
      });
  }

  // Cotizaciones de servicontratos
  getDataServicontratos(cod_sociedad: string,
                        inventario: string,
                        material: string,
                        kilometraje: string,
                        cant_paquetes: number,
                        moneda: string, codigo_cliente: string) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      cod_sociedad: cod_sociedad,
      inventario: inventario,
      material: material,
      kilometraje: kilometraje,
      cant_paquetes: cant_paquetes,
      moneda: moneda,
      codigo_cliente: codigo_cliente
    };
    // debugger;
    return this.soapService.post(url, 'obtener_data_servicontrato', atributos)
    .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }
  obtenerListadoFacturas(code: string, cedula: string, numero_cliente: string, fecha_inicio: string, fecha_final: string) {
    // debugger;
    const url = `${this.url}`;
    const atributos = {
      cod: code,
      cedula: cedula,
      numero_cliente: numero_cliente,
      fecha_inicio: fecha_inicio,
      fecha_final: fecha_final
    };
    return this.soapService.post(url, 'ConsultaFacturasCliente', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  getListadoServicontratos(
    cod_cliente: any,
    token: any
  ) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      cod_cliente: cod_cliente,
      token: token
    };
    return this.soapService.post(url, 'consultar_cotizaciones', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }
  DescargarFactura(code: string, numfactura: string) {
    const url = `${this.url}`;
    const atributos = {
      cod: code,
      numero_factura: numfactura,
    };
    return this.soapService.post(url, 'DescargaFacturasCliente', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  getListadoSucursalesServicontratos(
    sociedad: any,
  ) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad: sociedad,
    };
    return this.soapService.post(url, 'obtener_sucursales_servicontratos', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  savePayment(code: string, idCountry: string, data: {}, quotes: {}, payments: {}, origins: {}) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      codigo: code,
      id_pais: idCountry,
      datos_guardar: data,
      facturas: quotes,
      abonos: payments,
      originales: origins
    };
    return this.soapService.post(url, 'guardar_pagos', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  generarCotizacionServicontrato(
    sociedad: any,
    codigo_cliente: any,
    moneda: any,
    vehiculo: any,
    rango_km: any,
    marca: any,
    prima: any,
    tipo_paquete: any,
    centro_svc: any) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad: sociedad,
      codigo_cliente: codigo_cliente,
      moneda: moneda,
      vehiculo: vehiculo,
      rango_km: rango_km,
      marca: marca,
      prima: prima,
      tipo_paquete: tipo_paquete,
      centro_svc: centro_svc
    };
    return this.soapService.post(url, 'generar_cotizacion_servicontrato', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }
  quoteClientInfo(code: string, idCountry: string) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      codigo: code,
      id_pais: idCountry,
    };
    return this.soapService.post(url, 'factura_informacion_cliente', atributos)
      .then((result: any) => {
        return JSON.parse(result.return);
      }).catch(error => {
        return error;
      });
  }

  handleBlockedServiceContract(
    sociedad: string,
    codigo_cliente: string,
    inventario: string,
    id_sucursal: string,
    id_error: string) {
    const url = `${this.url}`;
    const atributos = {
      cod: this.codigo,
      sociedad,
      codigo_cliente,
      inventario,
      id_sucursal,
      id_error
    };
    const urlService = 'notifica_ejecutivo_svc';
    return this.soapService.post(url, urlService, atributos)
      .then((result: any) => {
        const jsonResponse = JSON.parse(result.return);
        // this.utilService.checkAndManageErrorResponse(jsonResponse, urlService, "migrupoq");
        return jsonResponse;
      }).catch(error => {
        return error;
      });
  }

}
