import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {UtilService} from "../../providers/util.service";
import {LoanHelper} from "../../helpers/crediq/LoanHelper";

@Component({
  selector: 'app-payment-appt-web',
  templateUrl: './payment-appt-web.component.html',
  styleUrls: ['./payment-appt-web.component.scss'],
})
export class PaymentApptWebComponent implements OnInit, OnDestroy {
  @Input() url: string;
  @ViewChild('frameTest', {static: true}) iframe: ElementRef;
  private boundReceiveMessage: any;

  constructor(private modalCtrl: ModalController,
              private utilService: UtilService,
              private loanHelper: LoanHelper) {
  }

  ngOnInit() {
    this.boundReceiveMessage = this.receiveMessage.bind(this);
    window.addEventListener('message', this.boundReceiveMessage, false);
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.boundReceiveMessage, false);
  }

  receiveMessage(event: MessageEvent) {
    // You can access the message data from the event object.
    if (event.data && event.data === 'cerrar') {
     this.modalCtrl.dismiss();
    }
  }

  onIframeLoad(event: any): void {
    // const iframe: HTMLIFrameElement = event.target;
    // const currentUrl = iframe.contentWindow.location.href;
  }

  backModal() {
    this.modalCtrl.dismiss();
  }

}
