import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalController, NavController, Platform} from '@ionic/angular';
import {UtilService} from '../../providers/util.service';
import {LoanHelper} from '../../helpers/crediq/LoanHelper';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-select-card',
  templateUrl: './select-card.component.html',
  styleUrls: ['./select-card.component.scss'],
})
export class SelectCardComponent implements OnInit, OnDestroy {

  @Input() arrCards: any;

  backBtnSubscriber: Subscription;

  userCardsSubscription: Subscription;
  selectedCard;
  constructor(private modalCtrl: ModalController,
              private util: UtilService,
              protected navCtrl: NavController,
              public loanHelper: LoanHelper, private platform: Platform) {
  }

  ngOnInit() {
    if (this.loanHelper.isLoadingUserLoanCards()) {
      this.util.presentLoading();
    }
    this.userCardsSubscription = this.loanHelper.getUserLoanCardsObservable().subscribe((data) => {
      if (this.loanHelper.isLoadingUserLoanCards) {
        this.util.dismissLoading();
      }
    });
    this.backBtnSubscriber = this.platform.backButton.subscribeWithPriority(
      999999,
      () => {
        this.backFn();
      }
    );
  }

  ngOnDestroy() {
    this.backBtnSubscriber.unsubscribe();
  }

  confirmData() {
    this.closeSuccess();
  }

  closeSuccess() {
    const card = this.selectedCard;
    this.modalCtrl.dismiss({
     card
    });
  }

  backFn() {
    this.modalCtrl.dismiss();
  }
}
