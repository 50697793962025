import {Injectable} from '@angular/core';
import {BaseService} from '../../services/http/base.service';
import {environment as env} from '../../../environments/environment';
import {HttpHeaders} from '@angular/common/http';
import {UtilService} from "../util.service";
import {SmartHelper} from "../../helpers/smartq/SmartHelper";
import {CollectHelper} from "../../helpers/crediq/CollectHelper";

@Injectable({
  providedIn: 'root'
})
export class LoanService {

  constructor(private bs: BaseService, private utilService: UtilService) {
  }

  getUserCards(country, email, clientCode) {
    return this.bs.get([`${env.crediq.endpoints.getCards}?pais=${country}&email=${email}&codigoCliente=${clientCode}`]);
  }

  getUserCardsUserCrediQ(country: string) {
    const flag = this.getWsStatus('obtenerTarjetasUsuario');
    if (!flag) {
      this.updateWsStatus('obtenerTarjetasUsuario', true);
      return new Promise((resolve, reject) => {
        this.bs.post([`${env.crediq.endpoints.getCardsUserCrediQ}`], {pais: country}, true, 'crediq').then((result: any) => {
          this.utilService.checkAndManageErrorResponse(result, '/tarjetaTokenizada/obtenerTarjetasUsuario', "crediq");
          this.updateWsStatus('obtenerTarjetasUsuario', false);
          resolve(result);
        }).catch(err => {
          this.updateWsStatus('obtenerTarjetasUsuario', false);
          reject(err);
        });
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(null);
      });
    }
  }

  // Crear metodo para actualizar la flag de status segun el ws name
  updateWsStatus(wsName: string, status: boolean) {
    const statusLoansRequest = localStorage.getItem('statusLoansRequest');
    if (statusLoansRequest) {
      const statusLoansRequestObj = JSON.parse(statusLoansRequest);
      statusLoansRequestObj[wsName] = status;
      localStorage.setItem('statusLoansRequest', JSON.stringify(statusLoansRequestObj));
    }
  }

  // Crear metodo para obtener el valor de status segun el ws name
  getWsStatus(wsName: string): boolean {
    const statusLoansRequest = localStorage.getItem('statusLoansRequest');
    if (statusLoansRequest) {
      const statusLoansRequestObj = JSON.parse(statusLoansRequest);
      return statusLoansRequestObj[wsName];
    } else {
      return null;
    }
  }

  getUserCardsPrestamos(country: string) {
    const flag = this.getWsStatus('obtenerTarjetasUsuarioPagoPrestamos');
    if (!flag) {
      this.updateWsStatus('obtenerTarjetasUsuarioPagoPrestamos', true);
      return new Promise((resolve, reject) => {
        this.bs.post([`${env.crediq.endpoints.getCardsUserCrediQPrestamos}`], {pais: country}, true, 'crediq').then((result: any) => {
          this.utilService.checkAndManageErrorResponse(result, '/tarjetaTokenizada/obtenerTarjetasUsuarioPagoPrestamos', "crediq");
          this.updateWsStatus('obtenerTarjetasUsuarioPagoPrestamos', false);
          resolve(result);
        }).catch(err => {
          this.updateWsStatus('obtenerTarjetasUsuarioPagoPrestamos', false);
          reject(err);
        });
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(null);
      });
    }
  }

  makePayment(args) {
    return new Promise((resolve, reject) => {
      this.bs.post([`${env.crediq.endpoints.makePayment}`], args, true, 'crediq').then((result: any) => {
        this.utilService.checkAndManageErrorResponse(result, '/tarjetaTokenizada/realizarPago', "crediq");
        resolve(result);
      }).catch(err => {
        reject(err);
      });
    });
  }

  createTransaction(args) {
    return this.bs.post([`${env.endpoints.transactions}`], args, false);
  }

  createTxCardValidator3DS(args) {
    return this.bs.post([`${env.endpoints.card_validator}`], args, false);
  }

  createTransactionAFS(args) {
    return this.bs.post([`${env.endpoints.transactionsAFS}`], args, false);
  }

  getPaymentWindowCode() {
    return this.bs.get([`${env.endpoints.paymentAfsWindow}`], false, true);
  }

  // loginCrediQ(country, channel, userID) {
  //   return this.bs.get([`${env.crediq.endpoints.login}?pais=${country}&canal=${channel}&identificacion=${userID}`]);
  // }

  loginCrediQ(email: string, passwd: string, country: string, identificacion: any) {
    const flag = this.getWsStatus('registerSpecial');
    if (!flag) {
      return new Promise((resolve, reject) => {
        this.bs.get([`${env.crediq.endpoints.login}?pais=${country}&numeroIdentificacion=${identificacion}&email=${email}&pass=${passwd}&canal=SMARTQ&version=4.8.4`])
          .then((result: any) => {
            this.utilService.checkAndManageErrorResponse(result, '/cuentas/registerSpecial', "crediq");
            this.updateWsStatus('registerSpecial', false);
            resolve(result);
          }).catch(err => {
          this.updateWsStatus('registerSpecial', false);
          reject(err);
        });
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(null);
      });
    }
  }

  attendNotificationOfChangeOfGateway(country: string, email: string) {
    // ?pais=${country}&email=${email}
    return new Promise((resolve, reject) => {
      this.bs.post([`${env.crediq.endpoints.attendNotificationOfChangeOfGateway}`], {pais: country, email})
        .then((result: any) => {
          resolve(result);
        }).catch(err => {
        reject(err);
      });
    });
  }

  generarTokenTarjetaCronosLS(data: any) {
    return this.bs.post([`${env.crediq.endpoints.saveCreditCard}`], data).then(response => {
      return response;
    }).catch(error => {
      console.log("@ERROR Generate Token Credit Cards", error);
    });
  }

  getLoanDetails(country, clientCode) {
    const flag = this.getWsStatus('consultarPrestamos');
    if (!flag) {
      this.updateWsStatus('consultarPrestamos', true);
      return new Promise((resolve, reject) => {
        this.bs.get([`${env.crediq.endpoints.loanDetail}?pais=${country}&codigoCliente=${clientCode}`]).then((result: any) => {
          this.utilService.checkAndManageErrorResponse(result, '/prestamos/consultarPrestamos', "crediq");
          this.updateWsStatus('consultarPrestamos', false);
          resolve(result);
        }).catch(err => {
          this.updateWsStatus('consultarPrestamos', false);
          reject(err);
        });
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(null);
      });
    }
  }

  requestLoanDetail(country, clientCode, email, idPrest) {
    return new Promise((resolve, reject) => {
      this.bs.get([`${env.crediq.endpoints.loanDetailRequest}?pais=${country}&codigoCliente=${clientCode}&email=${email}&idPrest=${idPrest}&detalle=D`]).then((result: any) => {
        this.utilService.checkAndManageErrorResponse(result, '/prestamos/solicitarEstadoCuenta', 'crediq');
        resolve(result);
      }).catch(err => {
        reject(err);
      });
    });
  }

  getLoanDetailsLight(country, clientCode) {
    const flag = this.getWsStatus('getGarantias');
    if (!flag) {
      this.updateWsStatus('getGarantias', true);
      return new Promise((resolve, reject) => {
        this.bs.get([`${env.crediq.endpoints.loanDetailLight}?pais=${country}&codigoCliente=${clientCode}`]).then((result: any) => {
          this.utilService.checkAndManageErrorResponse(result, '/prestamos/getGarantias', "crediq");
          this.updateWsStatus('getGarantias', false);
          resolve(result);
        }).catch(err => {
          this.updateWsStatus('getGarantias', false);
          reject(err);
        });
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(null);
      });
    }
  }

  getLoanNPE(country, clientCode, loanNumber) {
    return new Promise((resolve, reject) => {
      this.bs.get([`${env.crediq.endpoints.loanNPE}?pais=${country}&codigoCliente=${clientCode}&numeroPrestamo=${loanNumber}`]).then((result: any) => {
        this.utilService.checkAndManageErrorResponse(result, '/prestamos/carnetsAsociadosByPrestamo', "crediq");
        resolve(result);
      }).catch(err => {
        reject(err);
      });
    });
    // this.utilService.checkAndManageErrorResponse(result, '/cuentas/registerSpecial', "crediq");
  }

  // addUserCard(args) {
  //   return this.bs.get([`${env.crediq.endpoints.addCreditCard}?pais=${args.country}
  //   &email=${args.email}&numeroDocumento=${args.doc}
  //   &anioVencimiento=${args.expYear}
  //   &mesVencimiento=${args.expMonth}
  //   &codigoSeguridad=${args.cvv}
  //   &nombreTitular=${args.cardName}
  //   &emisor=${args.cardCompany}
  //   &tipoTarjeta=${args.cardType}
  //   &alias=${args.cardAlias}`.replace(/ /g, '')]);
  // }

  addUserCard(args) {
    return new Promise((resolve, reject) => {
      this.bs.post([`${env.crediq.endpoints.addCreditCard}`], args, true, "crediq").then((result: any) => {
        this.utilService.checkAndManageErrorResponse(result, '/tarjetaTokenizada/agregarTarjeta', "crediq");
        resolve(result);
      }).catch(err => {
        reject(err);
      });
    });
  }

  validateUserCard(args) {
    return new Promise((resolve, reject) => {
      this.bs.post([`${env.crediq.endpoints.validateCreditCard}`], args, true, "crediq").then((result: any) => {
        this.utilService.checkAndManageErrorResponse(result, '/tarjetaTokenizada/validarTarjeta', "crediq");
        resolve(result);
      }).catch(err => {
        reject(err);
      });
    });
  }

  removeCreditCard(token, pais) {
    return new Promise((resolve, reject) => {
      this.bs.post([`${env.crediq.endpoints.removeCreditCard}`], {pais, token}, true, "crediq").then((result: any) => {
        this.utilService.checkAndManageErrorResponse(result, '/tarjetaTokenizada/removerTarjeta', "crediq");
        resolve(result);
      }).catch(err => {
        reject(err);
      });
    });
  }

  submitPayment(args) {
    return this.bs.post([`${env.crediq.endpoints.addCreditCard}`], args, true, "crediq");
  }

  changeLoanAlias(args) {
    return this.bs.get([`${env.crediq.endpoints.changeLoanAlias}?pais=${args.country}&id=${args.loanNumber}&alias=${args.loanALias}`]);
  }


}
