import {Injectable} from '@angular/core';
import {AlertController, LoadingController, Platform, ToastController} from '@ionic/angular';
import {Observable, Subject, Subscription} from 'rxjs';
import {environment as env} from '../../environments/environment';
import {Router} from '@angular/router';
import {AppConfigService} from './app-config.service';
import {BaseService} from './base.service';
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  static ASSET_URL = env.SITE_URL + 'assets/vehiculos/';

  errorCodes = {};

  // Create an array to keep track of active loading controllers
  private activeLoadingControllers: HTMLIonLoadingElement[] = [];

  constructor(
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    public platform: Platform,
    public toastCtrl: ToastController,
    public bs: BaseService,
    private router: Router,
    private appConf: AppConfigService,
    private auth: AuthService
  ) {
    this.errorCodes = appConf.getAppConfig();
  }

  public spinner: any;
  private isSpinnerVisible = false;
  private spinnerBehavior = new Subject<any>();
  private spinnerSubscription: Subscription;
  lastErrorsShown: { [key: string]: number } = {}; // objeto que registra el tiempo en que se mostró cada error

  static groupBy(items, key) {
    return items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [
          ...(result[item[key]] || []),
          item,
        ],
      }),
      {},
    );
  }

  static getColorGraphByScore(score) {
    if (score >= 80) {
      return '#78C000';
    } else if (score <= 79 && score >= 60) {
      return '#f1c40f';
    } else {
      return '#c0392b';
    }
  }

  static getFirstDateDay(dateArg = '') {
    const now = dateArg ? new Date(dateArg) : new Date();
    const date = new Date(now.getFullYear(), now.getMonth(), 1).toLocaleDateString();
    const dateConvert = this.convertDateForSmartqScoreDriver(date);
    return dateConvert + ' 00:00:00';
  }

  static getLastDateDay(dateArg = '') {
    const now = dateArg ? new Date(dateArg) : new Date();
    const date = new Date(now.getFullYear(), now.getMonth() + 1, 0).toLocaleDateString();
    const dateConvert = this.convertDateForSmartqScoreDriver(date);
    return dateConvert + ' 23:59:00';
  }

  static convertDateForSmartqScoreDriver(date) {
    const dateSplit = date.split('/');
    // if (dateSplit[2].length > 4) {
    //   dateSplit[2] = dateSplit[2].substring(1);
    // }
    const dateSend = dateSplit[2] + '-' + dateSplit[1].toString().padStart(2, '0') + '-' + dateSplit[0].toString().padStart(2, '0');
    return dateSend;
  }

  public getObservableSpinner(): Observable<any> {
    return this.spinnerBehavior.asObservable();
  }

  async presentLoading() {
    this.spinner = await this.loadingCtrl.create({
      spinner: null,
      id: 'customSpinner',
      translucent: true,
      cssClass: 'migq-spinner',
      message: `<img src="./assets/imgs/loader/preloader.gif" alt="loading">`,
      backdropDismiss: false
    });
// Add the created loading controller to the activeLoadingControllers array
    this.activeLoadingControllers.push(this.spinner);
    return await this.spinner.present();
  }

  async dismissLoading() {
    try {
      return await this.loadingCtrl.dismiss(null, null, 'customSpinner').then(() => {
      });
    } catch (e) {
    }
  }

  // Function to dismiss all loading controllers
  async dismissAllLoadingControllers() {
    for (const loadingController of this.activeLoadingControllers) {
      await this.loadingCtrl.dismiss();
    }

    // Clear the activeLoadingControllers array
    this.activeLoadingControllers = [];
  }

  async presentToast(mensaje, position: 'top' | 'middle' | 'bottom' = 'bottom', color?: string, header?: string, buttons?: any, cssClass?: string | string[]) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      ...(header && {header}),
      color: color ?? 'dark',
      duration: 3000,
      ...(buttons && {buttons}),
      ...(cssClass && {cssClass}),
      position
    });
    await toast.present();
  }

  async presentAlert(props) {
    const confirm = await this.alertCtrl.create({
      header: props.title || 'Aviso',
      message: props.message,
      cssClass: 'buttonCss',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Aceptar',
          handler: () => {
            if (props.confirmMethod) {
              return props.confirmMethod();
            }
          }
        }
      ]
    });
    await confirm.present();
  }

  cleanSpecialChars(text) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/'/g, '');
  }

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    if (lat2 && lon2) {
      const R = 6371; // Radius of the earth in km
      const dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
      const dLon = this.deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km
      return d.toFixed(2);
    } else {
      // console.log("No se han encontrado GEOLOCATIONS");
    }
  }

  orderBusinessByDistance(businessList, currentPos) {
    const newList = [];
    businessList.forEach((business) => {
      const pos = {
        lat: business.latitude,
        lon: business.longitude,
      };

      const distance = this.getDistanceFromLatLonInKm(pos.lat, pos.lon, currentPos.lat, currentPos.lon);
      newList.push({...business, ...{distance}});
    });

    return this.sortByKey(newList, 'distance');
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  formatNumber(value) {
    let vString = value.replace(/,/g, '');
    vString = vString.replace('-', '');
    vString = vString.replace(/\./g, '');
    vString = vString.replace('.', '');
    vString = vString.replace(/\D/g, '');
    const v = +vString;
    return v.toLocaleString();
  }

  convertKMtoMI(km: any) {
    try {
      let [distance] = km.split(' ');
      distance = distance.replace(/,/g, '');
      return this.formatNumber((parseFloat(distance) * 0.62137).toFixed(0)) + ' mi';
    } catch (error) {
      return 0 + ' mi';
    }
  }

  sortByKey(array, key) {
    return array.sort((a, b) => {
      return a[key] - b[key];
    });
  }

  saveAndOpenPdf(pdf: string, filename: string) {
    // const writeDirectory = this.platform.is('ios') ? this.file.dataDirectory : this.file.externalDataDirectory;
    // this.file.writeFile(writeDirectory, filename, this.convertBase64ToBlob(pdf, 'data:application/pdf;base64'), {replace: true})
    //   .then(() => {
    //     this.opener.open(writeDirectory + filename, 'application/pdf')
    //       .catch(() => {
    //         console.log('Error opening pdf file');
    //       });
    //   })
    //   .catch(() => {
    //     console.error('Error writing pdf file');
    //   });
  }

  redirectUriAccordingScreen(urlMovil: string = '/login', urlWeb: string = '/landing') {
    // Si es desktop, redireccion al landing, de lo contrario, redirecciono al login
    if (this.bs.isDesktop) {
      const width = window.screen;
      if (width.availWidth <= 1000) {
        // console.log("ES DESKTOP PERO EN VISTA MOVIL");
        this.router.navigate([urlMovil]);
      } else {
        // console.log("ES DESKTOP CON PANTALLA GRANDE");
        this.router.navigate([urlWeb]);
      }
    } else {
      this.router.navigate([urlMovil]);
    }
  }

  convertBase64ToBlob(b64Data, contentType): Blob {
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  saveByteArray(reportName, byte) {
    const blob = new Blob([byte]);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const fileName = reportName + '.pdf';
    link.download = fileName;
    link.click();
  }

  getCardType(cardNumber: string) {
    const localNumber = cardNumber.replace(/ /g, '');
// visa
    let re = new RegExp('^4');
    if (localNumber.match(re) != null) {
      return 'Visa';
    }

// Mastercard
// Updated for Mastercard 2017 BINs expansion
    if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(localNumber)) {
      return 'Mastercard';
    }

// American express
    re = new RegExp('^3[47]');
    if (localNumber.match(re) != null) {
      return 'American express';
    }

// Discover
    re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
    if (localNumber.match(re) != null) {
      return 'Discover';
    }

// Diners
    re = new RegExp('^36');
    if (localNumber.match(re) != null) {
      return 'Diners';
    }

// Diners
    re = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}');
    if (localNumber.match(re) != null) {
      return 'Diners';
    }

// Diners - Carte Blanche
    re = new RegExp('^30[0-5]');
    if (localNumber.match(re) != null) {
      return 'Diners - Carte Blanche';
    }

// JCB
    re = new RegExp('^35(2[89]|[3-8][0-9])');
    if (localNumber.match(re) != null) {
      return 'JCB';
    }

// JCB
    re = new RegExp('^(?:2131|1800|35)[0-9]{0,}$');
    if (localNumber.match(re) != null) {
      return 'JCB';
    }

// JCB
    re = new RegExp('^30');
    if (localNumber.match(re) != null) {
      return 'JCB';
    }

// Visa Electron
    re = new RegExp('^(4026|417500|4508|4844|491(3|7))');
    if (localNumber.match(re) != null) {
      return 'Visa Electron';
    }

// Maestro
    re = new RegExp('^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)');
    if (localNumber.match(re) != null) {
      return 'Maestro';
    }

// Maestro
    re = new RegExp('^(5[06789]|6)[0-9]{0,}');
    if (localNumber.match(re) != null) {
      return 'Maestro';
    }

// Dankort
    re = new RegExp('^(5019)');
    if (localNumber.match(re) != null) {
      return 'Dankort';
    }

// Interpayment
    re = new RegExp('^(636)');
    if (localNumber.match(re) != null) {
      return 'Interpayment';
    }

// Unionpay
    re = new RegExp('^(62|88)');
    if (localNumber.match(re) != null) {
      return 'Unionpay';
    }

    return 'NA';

  }

  returnAppConf() {
    return this.appConf.getAppConfig();
  }

  /* GPS PERMISION GENERAL */

  checkAndManageErrorResponse(response: any, url: string, app: 'migrupoq' | 'detektor' | 'crediq', alertType: 'toast' | 'alert' | 'none' = 'toast') {
    try {
      this.errorCodes = this.appConf.getAppConfig();
      let error = false;
      let message = '';
      response = response instanceof Array ? response[0] : response;
      // Get the appropriate error code based on the app and URL
      const errorCode = this.errorCodes[app][url];

      // Check the response for errors based on the app
      if (app === 'migrupoq') {
        error = response?.Error === 'si';
        message = error && response.descripcion ? response.descripcion : '';
      } else if (app === 'crediq') {
        error = response?.['process-status'] === 'error';
      } else if (app === 'detektor') {
        error = response?.error;
        message = error && response.error ? response.error : '';
      }

      // Display an alert or toast message based on the error and alert type
      if (alertType === 'none') {
        return;
      }
      if (error) {
        const messageToDisplay = message ? message : errorCode.message;
        const codeToDisplay = `Código ${errorCode.code}`;
        const buttonText = 'Ayuda';

        this.presentToast(
          messageToDisplay,
          'bottom',
          'danger',
          codeToDisplay,
          [{text: buttonText, handler: () => this.router.navigateByUrl('/help')}],
          'interceptor-toast'
        ).then(r => r);
      }
    } catch (ex) {
      console.log('Exc.');
    }
  }

  // verificamos si la funciona de pago esta permitida en el pais
  isPaymentAllowed(pais: string): boolean {
    const blackList = ['GT', 'HN', 'CR'];
    if (blackList.includes(pais)) {
      return false;
    }
    return true;
  }
}

type App = 'migrupoq' | 'detektor' | 'crediq';
type AlertType = 'toast' | 'alert' | 'none';
