import {Injectable} from '@angular/core';
import {HTTP} from '@ionic-native/http/ngx';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Storage} from '@ionic/storage';
import {Platform} from '@ionic/angular';
import {catchError, timeout} from 'rxjs/operators';
import {environment, environment as env} from '../../../environments/environment';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(
    private httpNative: HTTP,
    private http: HttpClient,
    private storage: Storage,
    private platform: Platform) {
    // this.platform.ready().then(() => {
    //   this.httpNative.setRequestTimeout(2500);
    // });
  }

  getTokenByApp(app) {
    // if (app == "crediq") {
    //   debugger;
    // }
    let key = '', val = '';
    switch (app) {
      case 'smartq':
        const tokenSQ = window.localStorage.getItem(environment.smartq.storagekeys.smartToken);
        key = 'access-token';
        val = tokenSQ;
        break;
      case 'crediq':
        const tokenCQ = JSON.parse(window.localStorage.getItem(env.production ? 'prod:crediq:user' : 'qa:crediq:user'))["access-token"] || '';
        key = 'authorization';
        val = tokenCQ;
        break;
      case 'cronos':
        const token = window.localStorage.getItem('token');
        const username = JSON.parse(window.localStorage.getItem('userProfile')).username;
        key = 'Authorization';
        val = `ApiKey ${username}:${token}`;
        break;
      default:
        break;
    }
    return {key, val};
  }


  // HEADERS
  async getNativeHeader(nToken, app: 'smartq' | 'crediq' | 'cronos' | '' = '') {
    let headers: any = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Cache-control': 'no-cache',
      'Expires': '0',
      'Pragma': 'no-cache'
    };

    if (nToken) {
      const {key, val} = this.getTokenByApp(app);
      headers[key] = val;
    }
    return headers;
  }


  async getBrowserHeader(nToken, app: 'smartq' | 'crediq' | 'cronos' | '' = '') {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded')
      .append('Cache-control', 'no-cache')
      .append('Expires', '0')
      .append('Pragma', 'no-cache');
    if (nToken) {
      const {key, val} = this.getTokenByApp(app);
      headers = headers.append(key, val);
    }
    return headers;
  }


  // POST
  async post<T>(url: string[], body, nToken = false, app: 'smartq' | 'crediq' | 'cronos' | '' = ''): Promise<T> {
    let _url = url.join('/');

    return this.isDesktop() ? this.browserPost<T>(_url, body, await this.getBrowserHeader(nToken, app))
      :
      this.nativePost<T>(_url, body, await this.getNativeHeader(nToken, app));
  }

  private async nativePost<T>(url, body, headers): Promise<T> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.httpNative.setServerTrustMode('nocheck');
        let x = await this.httpNative.post(url, body, headers);
        resolve(JSON.parse(x.data));
      } catch (ex) {
        try {
          reject({error: JSON.parse(ex.error).message, status: ex.status});
        } catch (e) {
          // console.log(e);
          reject({error: 'Algo salio mal', status: false});
        }
      }
    });
  }


  private async browserPost<T>(url, body, headers): Promise<T> {
    return new Promise((resolve, reject) => {
      this.http.post<T>(url, this.formData(body), {headers}).subscribe(d => resolve(d), err => {
        // console.log(err);
        reject({
          status: err.status,
          message: err.error.message
        });
      });
    });
  }


  // GET

  async get<T>(url: string[], nToken = false, _any = false): Promise<T> {
    let _url = url.join('/');
    return this.isDesktop() ? this.browserGet<T>(_url, await this.getBrowserHeader(nToken), _any)
      :
      this.nativeGet<T>(_url, await this.getNativeHeader(nToken), _any);
  }


  private async nativeGet<T>(url, headers, _any): Promise<T> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.httpNative.setServerTrustMode('nocheck');
        // this.httpNative.setRequestTimeout(5.0);
        let x = await this.httpNative.get(url, null, headers);
        if (_any) {
          return resolve(x.data);
        }
        resolve(JSON.parse(x.data));

        // alert('GOOD ' + JSON.stringify(x.data));
      } catch (ex) {
        // alert(JSON.stringify(ex.status));
        try {

          reject({error: JSON.parse(ex.error).message, status: ex.status});
        } catch (ex1) {
          reject({error: ex});
        }
        reject({error: JSON.parse(ex.error).message, status: ex.status});
      }
    });
  }

  public async browserGet<T>(url, headers, _any = false): Promise<T> {
    const responseType = _any ? 'text' as 'json' : 'json';

    return new Promise((resolve, reject) => {
      this.http.get<T>(url, {headers, responseType}).pipe(
        timeout(60000),
        catchError(e => {
          // do something on a timeout
          return of(null);
        })
      ).subscribe(d => resolve(d), err => {
        try {
          reject({status: err.status, message: err.error.message});
        } catch (ex) {
          reject({status: 500, message: err});
        }
      });
    });
  }

  postLoginHttp(userData): Observable<any> {
    return this.http.post<any>(env.cronos.login, userData);
  }

  postAddFavorite(id, token) {

    const formData = new FormData();
    formData.append('user_uuid', token);

    return this.http.post<any>(env.benefits.favorite.addFavorite + id + '/', formData);
  }

  postRemoveFavorite(id, token) {

    const formData = new FormData();
    formData.append('user_uuid', token);

    return this.http.post<any>(env.benefits.favorite.removeFavorite + id + '/', formData);
  }

  public isDesktop() {
    return this.platform.is('desktop') || this.platform.is('mobileweb');
  }

  formData(body) {
    return Object.keys(body).map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(body[key])).join('&');
  }
}
