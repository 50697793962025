<ion-content>
  <app-header-car sticky="true" [allowCarSelection]="false" [backFn]="closeModal.bind(this)"></app-header-car>

  <div class="ion-text-center quote-header">
    <ion-text>
      <ion-badge color="warning">Cotización #{{quote.VBELN}}</ion-badge>
    </ion-text>
    <div *ngIf="isQuoteReadOnly">
      <ion-text>
        <ion-badge color="medium">Presupuesto autorizado</ion-badge>
      </ion-text>
    </div>
    <div *ngIf="!isQuoteReadOnly" class="alert alert-info ion-margin">
      <ion-text>Esta cotización es adicional a los trabajos que ya se realizaron o por los que llevó originalmente el
        vehículo al taller.
      </ion-text>
    </div>
  </div>

  <div [ngClass]="isQuoteReadOnly ? 'view-readonly mb-80' : ''">
    <ion-card class="card-appts ion-padding-bottom br-15" (click)="expandItem(groups[key], groups[key][0])"
              *ngFor="let key of Object.keys(groups)">
      <ion-card-header class="ion-no-padding">
        <ion-card-title class="ion-justify-content-between d-flex">
          <ion-item color="primary" class="w-100">
            <ion-label>{{key}} {{groups[key][0].TEXT_GRP}}</ion-label>
            <ion-text slot="start">{{getGroupAmount(groups[key]) | currency : quote.SIMBOLO_MONEDA + ' '}} </ion-text>
            <ion-checkbox slot="end" [(ngModel)]="groups[key][0].expanded"></ion-checkbox>
          </ion-item>
        </ion-card-title>
      </ion-card-header>
      <ion-card-content class="ion-no-padding" (click)="$event.preventDefault(); $event.stopPropagation();">
        <app-accordeon expandHeight="1300px" [expanded]="groups[key][0].expanded">
          <ion-list>
            <ion-item *ngFor="let orderDetails of groups[key]">
              <ion-label>{{orderDetails.DESCR1}}</ion-label>
              <ion-text slot="end">{{orderDetails.Monto | currency : quote.SIMBOLO_MONEDA + ' '}}</ion-text>
              <!--            <ion-checkbox disabled="true" slot="end" [(ngModel)]="orderDetails.checked"></ion-checkbox>-->
            </ion-item>
          </ion-list>
        </app-accordeon>
      </ion-card-content>
    </ion-card>

    <ion-list *ngIf="!isQuoteReadOnly" class="status-comment">
      <ion-item>
        <ion-textarea class="ion-padding" [(ngModel)]="comment" placeholder="Detalla tu comentario"></ion-textarea>
      </ion-item>
    </ion-list>
  </div>

  <div *ngIf="!isQuoteReadOnly">
    <div class="alert alert-info ion-margin">
      <span>Los precios ya incluyen impuestos y descuentos aplicados</span>
    </div>
  </div>

  <div *ngIf="!isQuoteReadOnly" style="margin-bottom: 96px; margin-top: 20px;">
    <ion-button color="buttonupdateprofile" expand="block"
                (click)="sendQuoteStatus()"
                class="gq-button-actualizar btn-rounded ion-margin-horizontal">Confirmar
    </ion-button>
  </div>

  <!--<div class="footer ion-text-center">-->
  <!--<span>Total: <h3 class="ion-no-margin">{{getTotalAmount() | currency}}</h3></span>-->
  <!--</div>-->

  <div class="footer ion-justify-content-between">
    <ion-row>
      <ion-col size="6" class="ion-text-center">
        <span>Grupos de trabajo: <h3 class="ion-no-margin"><ion-checkbox class="custom-check" checked
                                                                         disabled></ion-checkbox>
          {{ getCheckedGroups()?.length }} aprobados.</h3></span>
      </ion-col>
      <ion-col size="6" class="ion-text-center">
        <span>Total: <h3 class="ion-no-margin">{{getTotalAmount()  | currency : quote.SIMBOLO_MONEDA + ' '}}</h3></span>
      </ion-col>
    </ion-row>
  </div>
</ion-content>
