<ion-content>
  <ion-item color="primary" class="header-help-section">
    <ion-icon (click)="goBack()" slot="start" class="bck-button-transparent" name="chevron-back" color="white">
    </ion-icon>
    <ion-chip color="primary" class="title-center">
      <img class="logo-seccion" [src]="logo" />
      <ion-label class="title-help">Reportar Falla</ion-label>
    </ion-chip>
    <ion-chip slot="end" color="maincardsnavigation" outline="false">
      <ion-label></ion-label>
    </ion-chip>
  </ion-item>
  <ion-grid>
    <ion-row *ngIf="!authService.bs.isDesktop()" class="ion-margin-top ion-align-items-center">
      <ion-col (click)="dismissModal()" size="2" class="ion-text-center ion-no-padding">
        <ion-icon class="bck-button" name="chevron-back" color="light"></ion-icon>
      </ion-col>
      <ion-col size="8" class="fsz-14 ion-text-center">
        <ion-icon name="warning" color="gptitle"></ion-icon>
        <ion-text class="ion-margin-start" color="gptitle">Reportar Falla</ion-text>
      </ion-col>
    </ion-row>
    <div class="ion-padding">
      <p class="gq-titulos">Reporta tu falla para brindarte un mejor servicio</p>
      <p>Elije la categoría mas adecuada:</p>

      <form [formGroup]="frm">
        <ion-list>
          <ion-radio-group formControlName="falla">
            <ion-item class="ion-text-wrap">
              <ion-label>La aplicación no actualiza correctamente</ion-label>
              <ion-radio value="2"></ion-radio>
            </ion-item>
            <ion-item class="ion-text-wrap">
              <ion-label>La aplicación no funciona en el teléfono</ion-label>
              <ion-radio value="3"></ion-radio>
            </ion-item>
            <ion-item class="ion-text-wrap">
              <ion-label>La aplicación se traba</ion-label>
              <ion-radio value="4"></ion-radio>
            </ion-item>
            <ion-item class="ion-text-wrap">
              <ion-label>La aplicación se cierra inesperadamente</ion-label>
              <ion-radio value="5"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-list>

        <ion-list>
          <ion-item>
            <ion-textarea formControlName="comentario"
                          placeholder="Detalla tu comentario"></ion-textarea>
          </ion-item>
        </ion-list>


        <div class="gp-buton-fallas ion-text-center">
          <ion-button style="width: 150px;" (click)="reportarFalla()" color="danger" class="gq-button-actualizar btn-radius">Enviar
        </ion-button>
        </div>

      </form>
    </div>
  </ion-grid>
</ion-content>
