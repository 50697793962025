import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Calendar} from '@ionic-native/calendar/ngx';
import {UtilService} from '../../../../../providers/util.service';
import {ApptHelper} from '../../../../../helpers/ApptHelper';
import {AuthService} from '../../../../../providers/auth.service';

@Component({
  selector: 'app-appt-complete-status',
  templateUrl: './appt-complete-status.component.html',
  styleUrls: ['./appt-complete-status.component.scss'],
})
export class ApptCompleteStatusComponent implements OnInit {
  code: string;
  appt: any;

  constructor(
    private calendar: Calendar,
    public authService: AuthService,
    private utilService: UtilService,
    private modalCtrl: ModalController,
    public apptHelper: ApptHelper,
  ) {
  }

  ngOnInit() {
    // Let's trigger appts list subscription
    this.apptHelper.getCitas();
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  scheduleEvent() {
    const i = this.appt.hora;
    const hour = i[0] + i[1] + ':' + i[2] + i[3];
    this.calendar.createEvent(
      this.appt.title,
      '',
      this.appt.taller,
      new Date(this.appt.fecha + ' ' + hour),
      new Date(this.appt.fecha + ' ' + hour)
    ).then((res) => {
      this.utilService.presentToast(`Cita agendada`).then(() => {
      });
    }, (err) => {
      // console.log(err);
    });
  }
}
