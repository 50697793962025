<ion-content>
  <app-header-text
    titleIcon="md-filetray"
    [sticky]="true"
    title="Estatus taller"
    (leftBtnClick)="backFn()"
    (rightBtnClick)="refreshStatuses()"
  >
  </app-header-text>

  <section class="vehicle-cnt">
    <div class="ion-margin-vertical">
      <ion-text *ngIf="currentVehicle?.DESC_MODELO" class="ion-text-capitalize car-name">
        {{currentVehicle?.DESC_MODELO.toLowerCase()}}</ion-text>
      <ion-text *ngIf="!currentVehicle?.DESC_MODELO" class="ion-text-capitalize car-name">
        {{currentVehicle?.MARCA}} {{currentVehicle?.MODELO}}</ion-text>
    </div>
    <img width="95%"
         [src]="ASSET_URL + currentVehicle?.MARCA.replace(' ', '_') + '/'  + vehiclesImages[currentVehicle.MATNR]?.image"
         alt="">

    <div class="alert car-info-cnt p-relative alert-statics-cars">
      <ion-row *ngIf="currentVehicle" class="">
        <ion-col size="12">
          <div class="ion-text-center">
            <ion-text *ngIf="currentVehicle?.DESC_MODELO" class="ion-text-capitalize car-name">
              <b>{{currentVehicle?.DESC_MODELO.toLowerCase()}}</b></ion-text>
            <ion-text *ngIf="!currentVehicle?.DESC_MODELO" class="ion-text-capitalize car-name">
              <b>{{currentVehicle?.MARCA}} {{currentVehicle?.MODELO}}</b></ion-text>
          </div>
        </ion-col>
        <ion-col size="6" class="ion-text-start">Año: <b>{{currentVehicle?.ANIO_PLACA && currentVehicle?.ANIO_PLACA || 'N/A'}}</b></ion-col>
        <ion-col size="6" class="ion-text-end">Placa:
          <b>{{currentVehicle?.NUM_PLACA && (currentVehicle?.NUM_PLACA.length > 8) ? (currentVehicle?.NUM_PLACA | slice:0:8) + '..' : (currentVehicle?.NUM_PLACA) || 'N/A'}}</b>
        </ion-col>
      </ion-row>
    </div>
  </section>

  <div *ngIf="isLoading" class="ion-padding ion-text-center">
    <div style="margin-bottom: 0;" class="alert-info alert ion-align-items-center d-flex">
      <ion-spinner style="color: #0c5460" name="lines"></ion-spinner>
      <ion-text style="padding-left: 7px">Obteniendo información...</ion-text>
    </div>
  </div>

  <div class="vehicle-status-card">
    <div class="ion-text-center legend">
      <ion-text>ESTADO DEL VEHÍCULO EN EL TALLER</ion-text>
    </div>
    <ion-card color="light">
      <ion-card-header color="primary" class="ion-text-center vh-aligned">
        <ion-icon name="ellipse" class="ion-margin-end" color="white"></ion-icon> <ion-text class="fs-18 font-weight-bold"  color="white">{{currentVehicleFinalStatus}}</ion-text>
      </ion-card-header>
      <ion-card-content class="ion-margin-top" [innerHTML]="currentVehicleFinalStatusDescription">
      </ion-card-content>
      <div class="footer ion-margin-bottom" *ngIf="vehicleStatus.isJobInAuthorization">
          <div class="quote" *ngIf="orderQuotes?.length">
            <ion-list lines="none" *ngFor="let quoteResponse of orderQuotes" class="quote-cnt">
              <ion-list-header>
                <ion-text><b>Cotizaciones</b></ion-text>
              </ion-list-header>
              <ion-item (click)="showQuoteModal($event, quote)" *ngFor="let quote of quoteResponse?.data">
                <ion-label>Cot. {{quote?.VHCLE}}</ion-label>
                <ion-badge *ngIf="quote.ESTADO === 'A'" color="warning" slot="end">Ver aprobados</ion-badge>
                <ion-badge *ngIf="quote.ESTADO !== 'A'" color="warning" slot="end">Revisar</ion-badge>
              </ion-item>
            </ion-list>
          </div>
          <div class="quote-spinner ion-text-center" *ngIf="isLoadingQuotes">
            <ion-spinner></ion-spinner>
          </div>
      </div>
    </ion-card>

    <div class="ion-padding">
      <ion-button class="btn-radius w-100" color="gpblue" *ngIf="(vehicleStatus.isJobFinish) && (showPaymentButton)" (click)="payQuote()">PAGAR</ion-button>
    </div>

  </div>



  <!--<div class="btn-job-history ion-text-center ion-margin-top" *ngIf="currentVehicleStatus?.length">-->
  <!--<div class="ion-padding-bottom">-->
  <!--<ion-label color="medium">Descargar trabajos anteriores:</ion-label>-->
  <!--</div>-->
  <!--<ion-button *ngFor="let status of currentVehicleStatus" (click)="downloadJobs(status.VBELN)" size="large"-->
  <!--fill="solid" shape="round" class="center-button-confirm">-->
  <!--Orden #{{status?.VBELN}}-->
  <!--</ion-button>-->
  <!--</div>-->

</ion-content>
