import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {UtilService} from "../../providers/util.service";
import {LoanHelper} from "../../helpers/crediq/LoanHelper";

@Component({
  selector: 'app-payment-web-add-card-frame',
  templateUrl: './payment-web-add-card-frame.component.html',
  styleUrls: ['./payment-web-add-card-frame.component.scss']
})
export class PaymentWebAddCardFrameComponent implements OnInit, OnDestroy {
  @Input() url: string;
  @ViewChild('frameTest', {static: true}) iframe: ElementRef;
  private boundReceiveMessage: any;
  constructor(private modalCtrl: ModalController,
              private utilService: UtilService,
              private loanHelper: LoanHelper
  ) {
  }

  ngOnInit() {
    this.boundReceiveMessage = this.receiveMessage.bind(this);
    window.addEventListener('message', this.boundReceiveMessage, false);
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.boundReceiveMessage, false);
  }

  receiveMessage(event: MessageEvent) {
    // You can access the message data from the event object.
    void this.utilService.dismissAllLoadingControllers();
    if (event?.data) {
      this.closeModal();
      const messageData = JSON.parse(event.data);
      if (messageData['process-status'] === 'error') {
        return this.failedAddedCard(messageData?.message || 'Error al agregar la tarjeta');
      }

      if (messageData['process-status'] === 'ok') {
        this.loanHelper.getuserCardsWS(true);
        this.loanHelper.getUserLoanCardsWS(true);
        return this.successAddedCard(messageData?.message || 'La tarjeta se agregó con éxito');
      }
    }
  }

  successAddedCard(msg: string = 'La tarjeta se agregó con éxito') {
    void this.utilService.presentAlert({
      title: 'Aviso',
      message: msg,
      backdropDismiss: false
    });
  }

  failedAddedCard(msg: string = 'Error al agregar la tarjeta') {
    void this.utilService.presentAlert({
      title: 'Error',
      message: msg,
      backdropDismiss: false
    });
  }

  onIframeLoad(event: any): void {

  }

  closeModal(): void {
    window.removeEventListener('message', this.boundReceiveMessage, false);
    void this.modalCtrl.dismiss();
  }

}
