<ion-content>
  <div class="container">
    <h1>ATENCIÓN CAMBIO EN SISTEMA DE PAGOS</h1>
    <p>Estimado Cliente, hemos ampliado nuestras opciones de pago con Tarjeta. <span class="text-important">Ahora puede realizar sus pagos
    con cualquier tarjeta de crédito</span> de la institución bancaria de su preferencia, nacional o internacional. Este
      cambio incrementa sustancialmente la seguridad y le ofrecerá una mejor experiencia de usuario para su
      comodidad. Para poder hacer uso de esta nueva función, será necesario que ingrese nuevamente sus datos de
      tarjeta una única vez, y en adelante ya no se requerirá que ingrese sus datos en cada pago. Si se le presenta
      algún inconveniente a la hora de registrar su tarjeta de crédito favor contactar a su Banco emisor.
      Agradecemos su comprensión y trabajamos constantemente en mejorar la experiencia de nuestros canales
      digitales.</p>
    <label>
      <ion-checkbox [(ngModel)]="acceptTerms"></ion-checkbox>
      Confirmo que he leído y acepto los términos y condiciones.
    </label>
    <div class="btn-container">
      <a class="btn later" (click)="closeWithAction('exit_without_accept')">Más tarde</a>
      <a class="btn accept" *ngIf="acceptTerms" (click)="closeWithAction('exit_with_accept')">Aceptar</a>
      <a class="btn accept disabled" *ngIf="!acceptTerms">Aceptar</a>
    </div>
  </div>

</ion-content>
