import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { ModalController, NavController, Platform } from "@ionic/angular";
import { Location } from "graphql";
import { Subscription } from "rxjs";
import { ServicontratosHelper } from "../../../helpers/migrupoq/ServicontratosHelper";
import { VehicleHelper } from "../../../helpers/VehicleHelper";
import { AuthService } from "../../../providers/auth.service";
import { ServicesService } from "../../../providers/services.service";
import { UtilService } from "../../../providers/util.service";
import { BaseService } from "../../../providers/base.service";
import { NewQuotationPage } from "./new-quotation/new-quotation.page";

@Component({
  selector: "app-servicontratos",
  templateUrl: "./servicontratos.page.html",
  styleUrls: ["./servicontratos.page.scss"],
})
export class ServicontratosPage implements OnInit, OnDestroy {
  backBtnSubscriber: Subscription;

  codCliente: string;
  tokenCliente: string;
  paisCliente: string;

  rows: any[] = [];
  isLoading = false;
  reactiveForm: FormGroup;

  constructor(
    private serviceService: ServicesService,
    private utilService: UtilService,
    private modalController: ModalController,
    private router: Router,
    private auth: AuthService,
    public bs: BaseService,
    public platform: Platform,
    private navCtrl: NavController,
    private servicontratosHelper: ServicontratosHelper,
    private vehicleHelper: VehicleHelper
  ) {
    this.reactiveForm = new FormGroup({
      kmInput: new FormControl("0"),
      cantServiciosInput: new FormControl("0"),
    });
  }

  ngOnInit() {
    this.backBtnSubscriber = this.platform.backButton.subscribeWithPriority(
      999999,
      () => {
        return this.navCtrl.back({ animated: true });
      }
    );
  }

  ngOnDestroy() {
    if (this.backBtnSubscriber) {
      this.backBtnSubscriber.unsubscribe();
    }
    this.servicontratosHelper.clearListadoServicontratos();
  }

  ionViewWillEnter() {
    this.servicontratosHelper.getListadoServicontratos();
  }

  get getListadoServicontratos() {
    return this.servicontratosHelper.listaServicontratos || [];
  }

  goToNewQuozitation() {
    if (this.bs.isDesktop()) {
      const width = window.screen;
      if (width.availWidth < 768) {
        this.goToNew();
        return;
      } else {
        // this.modalController.dismiss();
        this.presentModalNewQuozitation();
      }
    } else {
      this.goToNew();
      return;
    }
  }

  goToNew() {
    this.router.navigate(["/grupoq/servicontratos/new-quotation"]);
  }

  // Obtenemos la data necesaria para realizar la peticion
  getDataUser() {
    this.codCliente = this.auth.getCodigoUsuario();
    this.tokenCliente = this.auth.getToken();
    this.paisCliente = this.auth.getPaisUsuario();
  }

  PDF(url: string) {
    window.open(url, "_blank");
  }

  async presentModalNewQuozitation() {
    const modal = await this.modalController.create({
      component: NewQuotationPage,
      cssClass: "my-custom-class",
      backdropDismiss: false,
    });
    return await modal.present();
  }
}
