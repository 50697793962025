import { Injectable } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { AuthService } from './auth.service';
import { VehicleHelper } from '../helpers/VehicleHelper';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  geoIp = {
    IPv4: '',
    city: '',
    country_code: '',
    country_name: '',
    latitude: '',
    longitude: '',
    postal: '',
    state: ''
  }

  isBrowser = true;

  constructor(
    public device: Device,
    protected auth: AuthService,
    protected vehicleHelper: VehicleHelper,
    private http: HttpClient
  ) {
    //this.getIpAndGeolocation();
    this.isBrowser = this.auth.bs.isDesktop();
  }

  getDeviceInfo() {
    return {
      manufacturer: this.isBrowser ? '' : this.device.manufacturer,
      platform: this.device.platform,
      sistema_operativo: this.device.platform === 'android' ? 'android' : 'ios',
      model: this.device.model,
      uuid: this.device.uuid,
      token_phone: this.device.uuid,
      version: this.device.version,
    };
  }

  getUserInfo() {
    return {
      codigo_sap: this.auth.getCodigoUsuario(),
      fecha: new Date().toLocaleString(),
      email: this.auth.getEmailUser(),
      cliente: `${this.auth.getProfile().FirstName} ${this.auth.getProfile().LastName}`,
    };
  }

  getVehicleInfo() {
    return {
      vin: this.vehicleHelper.getCurrentVehicle()?.VIN,
    };
  }

  getLogInfo(data = {}) {
    try {
      if (this.isBrowser || this.device.manufacturer == null && this.device.platform == null) {
        return this.getLogWebInfo(data);
      }
      return {
        ...(!this.isBrowser && {...this.getDeviceInfo()}),
        ...this.getUserInfo(),
        ...this.getVehicleInfo(),
        ...data
      };
    } catch (e) {
      // console.log(e);
    }
  }

  getLogWebInfo(data = {}) {
    try {
      return {
        ...{
          sistema_operativo: navigator.userAgent,
          ip: this.geoIp.IPv4
        },
        ...this.getUserInfo(),
        ...this.getVehicleInfo(),
        ...data
      };
    } catch (e) {
      // console.log(e);
    }
  }

  getIpAndGeolocation() {
    return this.http.get('https://geolocation-db.com/json/').subscribe((arg: any) => {
      this.geoIp.IPv4 = arg.IPv4;
      this.geoIp.city = arg.city;
      this.geoIp.country_code = arg.country_code;
      this.geoIp.country_name = arg.country_name;
      this.geoIp.latitude = arg.latitude,
        this.geoIp.longitude = arg.longitude;
      this.geoIp.postal = arg.postal;
      this.geoIp.state = arg.state;
    }, error => {
      // console.log(error);
    });
  }

  getInfoSecurity(data = {descripcion: ''}) {
    try {
      if (this.isBrowser || this.device.manufacturer == null && this.device.platform == null) {
        return this.getLogWebInfo(data);
      } else {
        return {
          fecha: new Date().toLocaleString(),
          email: this.getUserInfo().email,
          codigo_sap: this.getUserInfo().codigo_sap,
          cliente: this.getUserInfo().cliente,
          vin: this.getVehicleInfo().vin,
          ip: this.geoIp.IPv4,
          sistema_operativo: this.device.platform === 'android' ? 'android' : 'ios',
          token_phone: this.getDeviceInfo().uuid,
          coordenadas: {
            latitud: this.geoIp.latitude,
            longitud: this.geoIp.longitude
          },
          descripcion: data.descripcion
        };
      }
    } catch (error) {
      // console.log(error);
    }
  }
}
