<ion-content class="no-scroll">
  <app-header-text iconRight="none" titleIcon="card" (leftBtnClick)="goOut()" title="Nueva Tarjeta" ></app-header-text>

  <ion-row #containerFrame id="containerFrame" style="visibility: hidden !important;">
<!--    <iframe id="frameCardsWeb" style="width: 100%; height: 0px; overflow: scroll; border: none;" [src]="urlIFrame"></iframe>-->
  </ion-row>

  <div class="slide-form-0" *ngIf="sideFormFront.form0">
    <ion-row [formGroup]="reactiveForm">
      <ion-list class="card-form w-100">
        <ion-row class="ion-padding-top">
          <ion-col size="12">
            <ion-item lines="none">
              <ion-label position="stacked">Número de tarjeta (*)</ion-label>
              <ion-input maxlength="19" type="text" inputmode="numeric" id="cardNumber" #card class="gq-input"
                         (ionChange)="monitorCcFormat()" formControlName="cardNumber"></ion-input>
              <small class="msg-error">
                {{textInputError('cardNumber', reactiveForm)}}
              </small>
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-item lines="none">
              <ion-label position="stacked">Alias (*)</ion-label>
              <ion-input maxlength="30" type="text" formControlName="cardOwner" class="gq-input" (ionChange)="changeCardholder()">
              </ion-input>
              <small class="msg-error">
                {{textInputError('cardOwner', reactiveForm)}}
              </small>
            </ion-item>
          </ion-col>
          <ion-col size="6">
            <ion-item lines="none">
              <ion-label position="stacked">Vencimiento (*)</ion-label>

                <ion-input maxlength="5" inputmode="numeric" type="text"  class="gq-input" (input)="addSlash($event);"
                           formControlName="cardExp" placeholder="MM/AA"></ion-input>
              <small class="msg-error">
                {{textInputError('cardExp', reactiveForm)}}
              </small>
            </ion-item>

          </ion-col>
          <ion-col size="6">
            <ion-item lines="none">
              <ion-label position="stacked">CVV (*)</ion-label>

              <ion-input class="gq-input" maxlength="4" inputmode="numeric" (ionChange)="changeCvv()" type="text" id="ccv"
                         formControlName="cardCvv">
              </ion-input>
              <small class="msg-error">
                {{textInputError('cardCvv', reactiveForm)}}
              </small>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-list>
    </ion-row>
    <ion-grid class="flex-center-buttons footer-grid">
      <ion-row>
        <ion-col size="12">
          <ion-row>
            <ion-col>
              <ion-button (click)="goBack(0)" class="ion-margin-top btn-radius" color="gpred">
                Regresar
              </ion-button>
            </ion-col>
            <ion-col *ngIf="validateForm0()">
              <ion-button (click)="selectSideForm(1)" class="ion-margin-top btn-radius" color="gpblue">
                Siguiente
              </ion-button>
            </ion-col>
            <ion-col *ngIf="!validateForm0()">
              <ion-button (click)="presentMsgErrorForm()" class="ion-margin-top btn-radius" color="medium">
                Siguiente
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>

  </div>


  <div class="slide-form-1" *ngIf="sideFormFront.form1">
    <ion-row [formGroup]="dataEbill0" class="w-100">
      <ion-list class="card-form w-100">
        <ion-item lines="none">
          <ion-label position="stacked">Nombre del titular</ion-label>
          <ion-input class="gq-input" maxlength="30" type="text" formControlName="nombre_titular"></ion-input>
          <small class="msg-error">
            {{textInputError('nombre_titular', dataEbill0)}}
          </small>
        </ion-item>
        <ion-item lines="none">
          <ion-label position="stacked">Apellidos del titular</ion-label>
          <ion-input class="gq-input" maxlength="30" type="text" formControlName="apellido_titular"></ion-input>
          <small class="msg-error">
            {{textInputError('apellido_titular', dataEbill0)}}
          </small>
        </ion-item>
        <ion-item lines="none">
          <ion-label position="stacked">Direccion #1</ion-label>
          <ion-input class="gq-input" maxlength="50" type="text" formControlName="direccion1"></ion-input>
          <small class="msg-error">
            {{textInputError('direccion1', dataEbill0)}}
          </small>
        </ion-item>
        <ion-item lines="none">
          <ion-label position="stacked">Direccion #2</ion-label>
          <ion-input class="gq-input" maxlength="50" type="text" formControlName="direccion2"></ion-input>
          <small class="msg-error">
            {{textInputError('direccion2', dataEbill0)}}
          </small>
        </ion-item>
      </ion-list>
    </ion-row>

    <ion-grid class="flex-center-buttons footer-grid">
      <ion-row>
        <ion-col size="12">
          <ion-row>
            <ion-col>
              <ion-button (click)="goBack(1)" class="ion-margin-top btn-radius" color="gpred">
                Regresar
              </ion-button>
            </ion-col>
            <ion-col *ngIf="validateForm1()">
              <ion-button (click)="selectSideForm(2)" class="ion-margin-top btn-radius" color="gpblue">
                Siguiente
              </ion-button>
            </ion-col>
            <ion-col *ngIf="!validateForm1()">
              <ion-button (click)="presentMsgErrorForm()" class="ion-margin-top btn-radius" color="medium">
                Siguiente
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>

  </div>

  <div class="slide-form-2" [hidden]="!sideFormFront.form2">
    <ion-row [formGroup]="dataEbill1" class="w-100">
      <ion-list class="card-form w-100" style="overflow: auto !important;">
        <ion-item lines="none">
          <ion-label position="stacked">Ciudad del titular</ion-label>
          <ion-input class="gq-input" maxlength="30" type="text" formControlName="ciudad"></ion-input>
          <small class="msg-error">
            {{textInputError('ciudad', dataEbill1)}}
          </small>
        </ion-item>

        <ion-item lines="none">
          <ion-label position="stacked">Codigo Zip</ion-label>
          <ion-input class="gq-input" maxlength="10" type="text" formControlName="codigo_zip"></ion-input>
          <small class="msg-error">
            {{textInputError('codigo_zip', dataEbill1)}}
          </small>
        </ion-item>

        <ion-item lines="none">
          <ion-label position="stacked">Pais</ion-label>
          <ion-input class="gq-input" readonly="true" maxlength="2" type="text" formControlName="pais"></ion-input>
          <small class="msg-error">
            {{textInputError('pais', dataEbill1)}}
          </small>
        </ion-item>
        <ion-item lines="none">
          <ion-label position="stacked">Telefono</ion-label>
          <ion-input class="gq-input" maxlength="20" type="tel" #phone  inputmode="numeric"  formControlName="telefono"></ion-input>
          <small class="msg-error">
            {{textInputError('telefono', dataEbill1)}}
          </small>
        </ion-item>

        <ion-item lines="none">
          <ion-label position="stacked">Correo</ion-label>
          <ion-input class="gq-input" inputmode="email"  maxlength="50" type="text" formControlName="correo"></ion-input>
          <small class="msg-error">
            {{textInputError('correo', dataEbill1)}}
          </small>        </ion-item>

      </ion-list>
    </ion-row>

    <ion-grid class="flex-center-buttons footer-grid">
      <ion-row>
        <ion-col size="12">
          <ion-row>
            <ion-col>
              <ion-button (click)="goBack(2)" class="ion-margin-top btn-radius" color="gpred">
                Regresar
              </ion-button>
            </ion-col>
            <ion-col *ngIf="validateForm2()">
              <ion-button (click)="saveCreditCard()" class="ion-margin-top btn-radius" color="gpblue">
                Guardar
              </ion-button>
            </ion-col>
            <ion-col *ngIf="!validateForm2()">
              <ion-button (click)="presentMsgErrorForm()" class="ion-margin-top btn-radius" color="medium">
                Guardar
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>

  </div>


</ion-content>
