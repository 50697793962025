import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AlertController, LoadingController, PopoverController, ToastController} from '@ionic/angular';
import {NavigationExtras, Router} from '@angular/router';
import {AuthService} from '../../providers/auth.service';
import {PromisesService} from '../../providers/promises.service';
import {HomeService} from '../../providers/home.service';
import {VehiculosService} from '../../providers/vehiculos.service';
import {UtilService} from '../../providers/util.service';
import {MainCarSelectorComponent} from '../main-car-selector/main-car-selector.component';
import {Subscription} from 'rxjs';
import {Collecter} from '../../models/global';
import {CollectHelper} from '../../helpers/crediq/CollectHelper';
import {LoanHelper} from '../../helpers/crediq/LoanHelper';
import {
  BarcodeScannerOptions,
  BarcodeScanner
} from '@ionic-native/barcode-scanner/ngx';

@Component({
  selector: 'app-payment-card-popover',
  templateUrl: './payment-card-popover.component.html',
  styleUrls: ['./payment-card-popover.component.scss'],
})
export class PaymentCardPopoverComponent implements OnInit, OnDestroy {

  @ViewChild(MainCarSelectorComponent, {static: true}) CarSelector: MainCarSelectorComponent;

  npeConfig: any;
  currentLoan: any;
  $collecters: Subscription;

  selectedCollector: any = -1;
  collecters: Collecter[] = [];
  isLoadingCollecters = false;
  encodeData: any;

  barcodeScannerOptions: BarcodeScannerOptions;

  constructor(
    private toastCtrl: ToastController,
    public loadingCtrl: LoadingController,
    private router: Router,
    public servicioAuth: AuthService,
    private promiseService: PromisesService,
    private utilService: UtilService,
    private homeService: HomeService,
    private vehiculosService: VehiculosService,
    public alertCtrl: AlertController,
    private collectHelper: CollectHelper,
    private loanHelper: LoanHelper,
    private barcodeScanner: BarcodeScanner,
    private popoverCtrl: PopoverController
  ) {
    // Options
    this.barcodeScannerOptions = {
      showTorchButton: true,
      showFlipCameraButton: true
    };

    this.collecters = this.collectHelper.getCollecters();
    if (this.collectHelper.isLoadingCollecters()) {
      this.utilService.presentLoading();
      this.isLoadingCollecters = true;
    }

    this.$collecters = this.collectHelper.getCollectersObservable().subscribe((data) => {
      this.collecters = this.collectHelper.getCollecters();
      if (this.isLoadingCollecters) {
        this.isLoadingCollecters = false;
        this.utilService.dismissLoading();
        this.collectHelper.setIsLoadingCollecters(false);
      }
    });
  }

  ngOnInit() {
    console.log(this.currentLoan);
    this.npeConfig = this.loanHelper.getLoanNPE(this.currentLoan);
  }

  ngOnDestroy(): void {
    if (this.$collecters) {
      this.$collecters.unsubscribe();
    }
  }

  async dismissModal() {
    await this.popoverCtrl.dismiss();
  }


}
