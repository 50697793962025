import {gql} from 'apollo-angular';

export const COUNTRIES_QUERY = gql`
    query getCountries {
      allCountries{
        id
        name
        label
        abbr
        code
        phoneCode
        isActive
      }
    }
 `;
