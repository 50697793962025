import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment as env } from "../../environments/environment";
import { BaseService } from "../services/http/base.service";

@Injectable({
  providedIn: "root",
})
export class AppConfigService {
  $configList = new BehaviorSubject<any>({});

  constructor(public bs: BaseService) {
  }

  getConfigListObservable() {
    return this.$configList.asObservable();
  }

  loadAppConfig() {
    this.bs.get([ env.endpoints.app_config ]).then((data: any) => {
      if (data?.status) {
        this.saveAppConfig(data.data);
        this.$configList.next(data.data);
      }
    });
  }

  saveAppConfig(config) {
    window.localStorage.setItem('appConfig', JSON.stringify(config));
  }

  getAppConfig() {
    const conf = window.localStorage.getItem('appConfig');
    return conf ? JSON.parse(conf) : {};
  }
}
