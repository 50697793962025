import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { VehicleHelper } from '../../../../helpers/VehicleHelper';
import { AuthService } from '../../../../providers/auth.service';
import { ServicesService } from '../../../../providers/services.service';
import { UtilService } from '../../../../providers/util.service';
import { NotificaEjecutivoSvcResponse } from "../models";

@Component({
  selector: 'app-handle-blocker',
  templateUrl: './handle-blocker.page.html',
  styleUrls: [ './handle-blocker.page.scss' ],
})
export class HandleBlockerPage implements OnInit {

  currentOffice: any;
  @Input() offices: any = [];
  @Input() message: any;
  @Input() errorID: any;

  constructor(private modalController: ModalController,
              private serviceService: ServicesService,
              private authService: AuthService,
              private vehicleHelper: VehicleHelper,
              private util: UtilService,
              private alertCtrl: AlertController,
              private router: Router) {
  }

  ngOnInit() {
  }


  cancel() {
    this.modalController.dismiss({
      dismissSuccess: false,
    });
  }

  saveAndConfirm() {

    if (!this.currentOffice) {
      return this.util.presentToast('Seleccione sucursal para continuar');
    }

    // console.log(this.moneda);
    this.util.presentLoading();
    this.serviceService.handleBlockedServiceContract(
      this.vehicleHelper.getCurrentVehicle().SOCIEDAD,
      this.authService.getCodigoUsuario(),
      this.vehicleHelper.getCurrentVehicle().NUM_INVENTARIO,
      this.currentOffice,
      this.errorID
    ).then(async (response) => {
      this.util.dismissLoading();

      const data = new NotificaEjecutivoSvcResponse(response);

      const alert = await this.alertCtrl.create({
        header: 'Aviso',
        message: data.hasError ? data.descripcion : data.data,
        cssClass: 'gq-alert-dosBotones',
        buttons: [
          {
            text: 'Aceptar',
            handler: () => {
              this.modalController.dismiss({
                submitted: !data.hasError,
              });
            }
          }
        ]
      });
      await alert.present();
    }).catch(error => {
      this.util.dismissLoading();
      console.log("err: notifica_ejecutivo_svc ", error);
    });
  }

}
