import {Component, OnInit} from '@angular/core';
import {ModalController, NavController} from "@ionic/angular";
import {ApptStatusHelper} from "../../helpers/migrupoq/ApptStatusHelper";

@Component({
  selector: 'app-quotes-list',
  templateUrl: './quotes-list.component.html',
  styleUrls: ['./quotes-list.component.scss'],
})
export class QuotesListComponent implements OnInit {
  quoteNumber = '';
  quoteList = [];

  constructor(
    public modalCtrl: ModalController,
    public navCtrl: NavController,
    public appStatusHelper: ApptStatusHelper
  ) {
  }

  ngOnInit() {
  }

  public closeModal() {
    this.navCtrl.back();
  }

  searchQuote(quote_number){
    this.appStatusHelper.searchByQuoteNumber(quote_number).then((response) => {

    });
  }
}
