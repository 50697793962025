import { Injectable } from '@angular/core';
import { BaseService } from '../../services/http/base.service';
import { environment as env } from '../../../environments/environment';
import { AuthService } from '../auth.service';
import { LogService } from '../logging.service';

@Injectable({
  providedIn: 'root'
})
export class SmartService {

  private user = env.smartq.credentials.user;
  private passwd = env.smartq.credentials.passwd;
  private country = env.smartq.credentials.pais;

  constructor(
    private authService: AuthService,
    private bs: BaseService,
    private logService: LogService) {
  }

  login() {
    return this.bs.post([`${env.smartq.endpoints.login}`.replace(/\[cr\]/gi, 'cr')], {
      usuario: this.user,
      pass: this.passwd,
      pais: this.country
    });
  }

  cleanSafePropsFromStorage() {
    Object.entries(localStorage).map(
      x => x[0]
    ).filter(
      x => x.split(':').length > 1 && x.split(':')[1].substring(0, 4) === 'TMP_'
    ).map(
      x => localStorage.removeItem(x));
  }

  getPerformance(vehicle) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    return this.bs.post([`${env.smartq.endpoints.consumo}`.replace(/\[cr\]/gi, country)], { usuario: this.user, vin }, true, 'smartq');
  }

  getVelocityLimit(vehicle, details) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    return this.bs.post([`${env.smartq.endpoints.limiteVelocidad}`.replace(/\[cr\]/gi, country)], { usuario: this.user, vin, detalle: details }, true, 'smartq');
  }

  getRoadHistory(vehicle, startDateTime, endDateTime) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    return this.bs.post([`${env.smartq.endpoints.obtenerRuta}`.replace(/\[cr\]/gi, country)], {
      usuario: this.user,
      vin,
      fechaIni: startDateTime,
      fechaFin: endDateTime
    }, true, 'smartq');
  }

  getlastPoint(vehicle) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    return this.bs.post([`${env.smartq.endpoints.ultimoPunto}`.replace(/\[cr\]/gi, country)], { usuario: this.user, vin }, true, 'smartq');
  }

  getAlertsHistory(data: any) {
    const vin = data.VIN;
    const fechaIni = data.fechaIni;
    const fechaFin = data.fechaFin;
    const country = this.authService.getSociedadPaisVehiculo(data);
    return this.bs.post([`${env.smartq.endpoints.alertasHistorial}`.replace(/\[cr\]/gi, country)], { usuario: this.user, vin, fechaIni, fechaFin }, true, 'smartq');
  }

  getAlertTypes(vehicle) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    return this.bs.post([`${env.smartq.endpoints.catalogoAlertas}`.replace(/\[cr\]/gi, country)], { usuario: this.user, vin }, true, 'smartq');
  }

  getLogsHisory(vehicle, type, initDate, endDate) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    return this.bs.post([`${env.smartq.endpoints.historialAuditoria}`.replace(/\[cr\]/gi, country)], { usuario: this.user, vin, fechaIni: initDate, fechaFin: endDate, tipo: type }, true, 'smartq');
  }

  activateAlert(vehicle, alertType) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    return this.bs.post([`${env.smartq.endpoints.activarAlerta}`.replace(/\[cr\]/gi, country)], { usuario: this.user, vin, alertas: alertType, detalle: this.logService.getInfoSecurity({ descripcion: 'Activación de alerta SQ' }) }, true, 'smartq');
  }

  deactivateAlert(vehicle, alertType) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    return this.bs.post([`${env.smartq.endpoints.desactivarAlerta}`.replace(/\[cr\]/gi, country)], { usuario: this.user, vin, alertas: alertType, detalle: this.logService.getInfoSecurity({ descripcion: 'Desactivación de alerta SQ' }) }, true, 'smartq');
  }

  enviarComando(command, vehicle, details) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    // console.log(details);
    return this.bs.post([`${env.smartq.endpoints.enviarComandos}`.replace(/\[cr\]/gi, country)], {
      usuario: this.user,
      vin,
      comando: command,
      app: 'SMARTQ',
      detalle: details
    }, true);
  }

  setSpeedLimit(limit, vehicle, detalle) {
    const vin = vehicle.VIN;
    const country = this.authService.getSociedadPaisVehiculo(vehicle);
    // console.log(detalle);
    return this.bs.post([`${env.smartq.endpoints.setLimiteVelocidad}`.replace(/\[cr\]/gi, country)], {
      usuario: this.user,
      vin,
      velocidad: limit,
      // tiempoValidez: 80,
      // intervaloEnvio: 300,
      app: 'SMARTQ',
      detalle: detalle
    }, true);
  }

}
