<div class="ion-text-center ion-padding pop-header top-left-right-border">
  <ion-text class="title-modal-emergency">Atención 24/7 Smart Q</ion-text>
</div>

<div class="content-emergency">
  <div class="card-info">
    <div class="title font-weight-bold">
      Puedes ponerte en contacto con nosotros para:
    </div>
    <div class="list-info">
      <ul class="font-weight-bold">
        <li>Recibir asistencia con el uso de Smart Q</li>
        <li>Renovar tu servicio</li>
      </ul>
    </div>
  </div>
</div>

<div class="content-emergency">
  <div class="card-info">
    <div class="title">
      Estos son los datos que debes proporcionar
    </div>
    <div class="content-client">
      <div class="ion-margin-top"></div>
      <p>Código del cliente</p>
      <span class="font-weight-bold">
        {{ currentCodeClient }}
      </span>
      <div class="ion-margin-top"></div>
      <p>N° de chasis del vehículo</p>
      <span class="font-weight-bold">
        {{ currentVehicle.VIN }}
      </span>
    </div>
  </div>
</div>

<div class="ion-text-center controls-emergency">
  <ion-text color="primary" style="cursor: pointer;" class="ion-margin-end fz-btn-emergency" (click)="callEmergencySmartQ()">Llamar</ion-text>
  <ion-text color="primary" style="cursor: pointer;" class=" fz-btn-emergency" (click)="dismissModal()">Cancelar</ion-text>
</div>

