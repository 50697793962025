export class NotificaEjecutivoSvcResponse {
  data: string;
  message: string;
  status: number;
  Error: string;
  descripcion: string;
  Funcion: string;

  constructor(props) {
    this.data = props?.data;
    this.message = props?.message;
    this.status = props?.status;
    this.Error = props?.Error;
    this.descripcion = props?.descripcion;
    this.Funcion = props?.Funcion;
  }

  get hasError() {
    return this.Error?.toUpperCase() === 'SI';
  }
}
