import { UtilService } from '../../../../../providers/util.service';

export class VehicleStatusResponse {
  cant = 0;
  data: VehicleStatus[] = [];
  message = ''; // virtual prop
  loaded = false; // virtual prop
  vehicleStatuses: VehicleStatus[] = []; // virtual prop for chaining
  finalStatus: string; // virtual prop for chaining

  constructor(props?) {
    if (props) {
      this.cant = props.cant;
      this.message = Array.isArray(props.data) ? '' : props.data;
      this.data = !!!this.message && props.data.map(x => new VehicleStatus(x)) || [];
      this.loaded = true;
    }
  }

  getStatusesByVIN(vin = ''): VehicleStatus[] {
    const grouped: VehicleStatus[] = UtilService.groupBy(this.data, 'VIN');
    this.vehicleStatuses = grouped[vin];
    return grouped[vin] || [];
  }

  filterByStatus(statuses, status) {
    return statuses.filter(x => matchAppStatus(x.Z_ESTADO) === status);
  }

  filterByStatusAuthPending(statuses: VehicleStatus[]) {
    return this.filterByStatus(statuses, CITA_AUTORIZACION);
  }

  getApptStatusByVIN(vin = '') {
    const finalStatus = this.getApptStatusObjByVIN(vin).Z_DESC;
    return matchAppStatus(finalStatus);
  }

  getApptStatusObjByVIN(vin = '') {
    const vehicleStatuses: VehicleStatus[] = this.getStatusesByVIN(vin);
    return vehicleStatuses[vehicleStatuses.length - 1];
  }

  getFinalStatusByVIN() {
    try {

      const vehicleStatuses: VehicleStatus[] = this.vehicleStatuses;
      const statuses = vehicleStatuses.map((status: VehicleStatus) => {
        return matchAppStatus(status.Z_ESTADO);
      });
      this.finalStatus = globalAppStatus(statuses);
      return this.finalStatus;
    } catch (e) {
      return CITA_NA;
    }
  }

  getFinalStatusDescriptionByVIN(status = '') {
    return matchAppStatusDescription(status ? status : this.finalStatus);
  }

  isFinalStatusNA() {
    return this.getFinalStatusByVIN() === CITA_NA;
  }

  get isJobInConfirmation() {
    return statusesUI[CITA_CONFIRMADA] <= statusesUI[this.finalStatus];
  }

  get isJobInProgress() {
    return statusesUI[CITA_PROCESO] <= statusesUI[this.finalStatus];
  }

  get isJobInAuthorization() {
    return statusesUI[CITA_AUTORIZACION] <= statusesUI[this.finalStatus];
  }

  get isJobInSpare() {
    return statusesUI[CITA_REPUESTOS] <= statusesUI[this.finalStatus];
  }

  get jobInSpareStatusDescription() {
    return ``;
  }

  get isJobFinish() {
    return statusesUI[CITA_FINALIZADA] <= statusesUI[this.finalStatus];
  }

  get isJobInWash() {
    return statusesUI[CITA_LAVADO] <= statusesUI[this.finalStatus];
  }
}

export class VehicleStatus {
  VBELN = 0; // Order #
  VHCLE = 0;
  Z_DESC = '';
  DESC_WERKS = '';
  MFRNR = '';
  Z_ESTADO = 0;
  VIN = '';
  BTN_PAGO_LINEA: false;
  BTN_PRESUPUESTO: false;
  pais = 0;
  MODELO = '';
  MATRICULA = '';

  constructor(props?) {
    if (props) {
      this.VBELN = props.VBELN;
      this.VHCLE = props.VHCLE;
      this.Z_DESC = props.Z_DESC;
      this.DESC_WERKS = props.DESC_WERKS;
      this.MFRNR = props.MFRNR;
      this.Z_ESTADO = props.Z_ESTADO;
      this.VIN = props.VIN;
      this.BTN_PAGO_LINEA = props.BTN_PAGO_LINEA;
      this.BTN_PRESUPUESTO = props.BTN_PRESUPUESTO;
      this.pais = props.pais;
      this.MODELO = props.MODELO;
      this.MATRICULA = props.MATRICULA;
    }
  }

  get getAppStatus() {
    return matchAppStatus(this.Z_ESTADO);
  }
}

export class OrderQuoteResponse {
  cant = 0;
  data: OrderQuote[] = [];

  constructor(props?) {
    if (props) {
      this.cant = props.cant;
      this.data = props.data.map(x => new OrderQuote(x));
    }
  }

}

// OrderQuoteStatuses
// A: Aprobado
// R: Rechazado
// C: Pedidos Cotizados
// P: Pedidos Pendientes

export class OrderQuote {
  CONDICION_PAGO = '';
  DESCRIPCION = '';
  DETALLE = {};
  ESTADO = '';
  FECHA_COT = '';
  HORA_COT = '';
  IVA = '';
  KUNNR = '';
  NOMBRE_CLI = '';
  NOMBRE_TALLER = null;
  PERNR = '';
  SIMBOLO_MONEDA = '';
  STATUS = '';
  SUBTOTAL = '';
  TOTALNEW = 0;
  TOTAL_ORDEN = '';
  VBELN = '';
  VHCLE = '';
  VKORG = '';
  id_form = '';
  id_zqev_dbm_func_select_cotiz_tal_encabezado = '';

  constructor(props?) {
    if (props) {
      this.CONDICION_PAGO = props.CONDICION_PAGO;
      this.DESCRIPCION = props.DESCRIPCION;
      this.DETALLE = this.setDetail(props.DETALLE);
      this.ESTADO = props.ESTADO;
      this.FECHA_COT = props.FECHA_COT;
      this.HORA_COT = props.HORA_COT;
      this.IVA = props.IVA;
      this.KUNNR = props.KUNNR;
      this.NOMBRE_CLI = props.NOMBRE_CLI;
      this.NOMBRE_TALLER = props.NOMBRE_TALLER;
      this.PERNR = props.PERNR;
      this.SIMBOLO_MONEDA = props.SIMBOLO_MONEDA;
      this.STATUS = props.STATUS;
      this.SUBTOTAL = props.SUBTOTAL;
      this.TOTALNEW = props.TOTALNEW;
      this.TOTAL_ORDEN = props.TOTAL_ORDEN;
      this.VBELN = props.VBELN;
      this.VHCLE = props.VHCLE;
      this.VKORG = props.VKORG;
      this.id_form = props.id_form;
      this.id_zqev_dbm_func_select_cotiz_tal_encabezado = props.id_zqev_dbm_func_select_cotiz_tal_encabezado;
    }
  }

  setDetail(details) {
    const newObj = {};
    const keys = Object.keys(details);
    keys.forEach((key, index) => {
      newObj[key] = new OrderQuoteDetails(details[key]);
    });
    return newObj;
  }

}


export class OrderQuoteDetails {
  COTIZADO = '';
  DESCR1 = '';
  DESCUENTO = '';
  EXISTENCIAS = '';
  KBETR = '';
  KMEIN = '';
  LABVAL = '';
  LBRVLGRP = '';
  MATNR18 = '';
  Monto = 0.00;
  NETWR = '';
  PORCENTAJE_DESC = '';
  POSNR = '';
  PRIORIDAD = '';
  TEXT_GRP = '';
  VBELN = '';
  WAERK = '';
  ZMENG = '';
  id_form = '';
  id_zqev_dbm_func_select_cotiz_tal_detalle = '';
  expanded = true; // virtual prop
  checked = true; // virtual prop

  constructor(props?) {
    if (props) {
      this.COTIZADO = props.COTIZADO;
      this.DESCR1 = props.DESCR1;
      this.DESCUENTO = props.DESCUENTO;
      this.EXISTENCIAS = props.EXISTENCIAS;
      this.KBETR = props.KBETR;
      this.KMEIN = props.KMEIN;
      this.LABVAL = props.LABVAL;
      this.LBRVLGRP = props.LBRVLGRP;
      this.MATNR18 = props.MATNR18;
      this.Monto = props.Monto;
      this.NETWR = props.NETWR;
      this.PORCENTAJE_DESC = props.PORCENTAJE_DESC;
      this.POSNR = props.POSNR;
      this.PRIORIDAD = props.PRIORIDAD;
      this.TEXT_GRP = props.TEXT_GRP;
      this.VBELN = props.VBELN;
      this.WAERK = props.WAERK;
      this.ZMENG = props.ZMENG;
      this.id_form = props.id_form;
      this.id_zqev_dbm_func_select_cotiz_tal_detalle = props.id_zqev_dbm_func_select_cotiz_tal_detalle;
    }
  }
}

export class ApproveQuoteResponse {
  Error = '';
  descripcion = '';
  Funcion = '';

  constructor(props) {
    if (props) {
      if (typeof props === 'object') {
        this.Error = props.Error;
        this.descripcion = props.descripcion;
        this.Funcion = props.Funcion;
      } else {
        this.Error = 'no';
        this.descripcion = props;
      }
    }
  }

  hasError() {
    return this.Error === 'si';
  }
}

export class SearchQuoteResponse {
  status = '';
  dataPDF = '';

  Error = '';
  descripcion = '';
  Funcion = '';

  constructor(props) {
    if (props) {
      if (props.status) {
        this.status = props.status;
        this.dataPDF = props.dataPDF;
      } else {
        this.Error = props.Error;
        this.descripcion = props.descripcion;
        this.Funcion = props.Funcion;
      }
    }
  }

  hasError() {
    return this.Error === 'si';
  }
}

export class DownloadJobResponse {
  status = '';
  dataPDF = '';

  Error = '';
  descripcion = '';
  Funcion = '';

  constructor(props) {
    if (props) {
      if (props.status) {
        this.status = props.status;
        this.dataPDF = props.dataPDF;
      } else {
        this.Error = props.Error;
        this.descripcion = props.descripcion;
        this.Funcion = props.Funcion;
      }
    }
  }

  hasError() {
    return this.Error === 'si';
  }
}

export const CITA_CONFIRMADA = 'CITA CONFIRMADA';
export const CITA_FINALIZADA = 'FINALIZADA';
export const CITA_AUTORIZACION = 'AUTORIZACIÓN DEL CLIENTE';
export const CITA_REPUESTOS = 'EN ESPERA DE REPUESTOS';
export const CITA_PROCESO = 'EN PROCESO';
export const CITA_LAVADO = 'EN LAVADO';
export const CITA_NA = 'N/A';

const STATUSES_DESCRIPTION = {
  CITA_CONFIRMADA: `<p>Cita de taller ha sido confirmada</p><p>Recomendamos puntualidad al momento de llevar su vehículo a taller</p>`,
  CITA_FINALIZADA: `<p>Tu vehículo se encuentra finalizado. Puedes retirarlo en taller</p>`,
  CITA_AUTORIZACION: `<p>Tu vehículo se encuentra con presupuesto de trabajo adicionales pendientes de aprobación</p>`,
  CITA_REPUESTOS: `<p>En este momento tu vehículo espera una pieza para poder continuar con los servicios</p>`,
  CITA_LAVADO: `<p>Estamos preparando tu vehículo para realizar entrega.</p>`,
  CITA_PROCESO: `<p>Estamos trabajando en su vehículo. En caso de dudas puedes comunicarte con tu asesor de servicio</p>`,
  CITA_NA: `<p>N/A</p>`,
};

const matchAppStatusDescription = (status) => {
  switch (status) {
    case CITA_CONFIRMADA:
      return STATUSES_DESCRIPTION.CITA_CONFIRMADA;
    case CITA_FINALIZADA:
      return STATUSES_DESCRIPTION.CITA_FINALIZADA;
    case CITA_AUTORIZACION:
      return STATUSES_DESCRIPTION.CITA_AUTORIZACION;
    case CITA_REPUESTOS:
      return STATUSES_DESCRIPTION.CITA_REPUESTOS;
    case CITA_LAVADO:
      return STATUSES_DESCRIPTION.CITA_LAVADO;
    case CITA_PROCESO:
      return STATUSES_DESCRIPTION.CITA_PROCESO;
    default:
      return STATUSES_DESCRIPTION.CITA_NA;
  }
};

const matchAppStatus = (status) => {
  let finalStatus = CITA_NA;
  switch (status) {
    case '01':
    case '02':
      finalStatus = CITA_CONFIRMADA;
      break;
    case '7':
      finalStatus = CITA_FINALIZADA;
      break;
    case '3':
      finalStatus = CITA_AUTORIZACION;
      break;
    case '1':
    case '2':
    case '11':
    case '20':
    case '21':
    case '23':
    case '24':
    case '25':
    case '26':
    case '27':
    case '28':
    case '29':
    case '30':
    case '31':
    case '32':
    case '33':
    case '34':
    case '4':
    case '61':
    case '73':
    case '77':
    case 'RT':
      finalStatus = CITA_PROCESO;
      break;
    case '5':
    case '50':
    case '51':
    case '52':
    case '53':
    case '54':
    case '55':
    case '57':
    case '58':
      finalStatus = CITA_REPUESTOS;
      break;
    case '22':
    case '72':
      finalStatus = CITA_LAVADO;
      break;
    default:
      finalStatus = CITA_NA;
  }
  return finalStatus;
};

export const statusesByPriority = {
  [CITA_REPUESTOS]: 1,
  [CITA_AUTORIZACION]: 2,
  [CITA_PROCESO]: 3,
  [CITA_LAVADO]: 4,
  [CITA_FINALIZADA]: 5,
  [CITA_CONFIRMADA]: 6,
  [CITA_NA]: 7
};

export const statusesUI = {
  [CITA_CONFIRMADA]: 1,
  [CITA_PROCESO]: 2,
  [CITA_REPUESTOS]: 3,
  [CITA_AUTORIZACION]: 4,
  [CITA_LAVADO]: 5,
  [CITA_FINALIZADA]: 6,
};

const globalAppStatus = (statusList = []) => {
  statusList.sort((a, b) => {
    if (statusesByPriority[a] > statusesByPriority[b]) {
      return 1;
    }
    if (statusesByPriority[a] < statusesByPriority[b]) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });
  return statusList.length ? statusList[0] : CITA_NA;
};
