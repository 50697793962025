export class ConsultaFacturasCliente {
  Error = '';
  descripcion: ConsultaFacturasClienteDescripcion;
  Funcion: "";

  constructor(props) {
    if (props) {
      this.Error = props.Error;
      this.descripcion = typeof props.descripcion === 'object' ? new ConsultaFacturasClienteDescripcion(props.descripcion) : props.descripcion;
    }
  }

  hasError() {
    return this.Error === 'si';
  }
}

export class ConsultaFacturasClienteDescripcion {
  Cedula = '';
  NumeroCliente = '';
  CodigoEjecucion = '';
  MensajeEjecucion = '';
  Facturas: Facturas[];
  Estado = '';

  constructor(props) {
    if (props) {
      const propsRespuesta = props.Respuesta;
      this.Cedula = propsRespuesta.Cedula;
      this.NumeroCliente = propsRespuesta.NumeroCliente;
      this.CodigoEjecucion = propsRespuesta.CodigoEjecucion;
      this.MensajeEjecucion = propsRespuesta.MensajeEjecucion;
      this.Facturas = Array.isArray(propsRespuesta.Facturas) ? propsRespuesta.Facturas.map(x => x) : [propsRespuesta.Facturas];
      this.Estado = propsRespuesta.Estado;
    }
  }
  hasNullData() {
    return this.MensajeEjecucion === 'Sin resultado para los datos de consulta';
  }
}

export class Facturas {
  Clave = '';
  NumeroProcesoFacturaElectronica = '';
  FechaEmision = '';
  Monto = '';
  HoraEmision = '';
  selected: boolean; // virtual prop
  constructor(props) {
    if (props) {
      this.Clave = props.clave;
      this.NumeroProcesoFacturaElectronica = props.NumeroProcesoFacturaElectronica;
      this.FechaEmision = props.FechaEmision;
      this.Monto = props.Monto;
      this.HoraEmision = props.HoraEmision;
      this.selected = false;
    }
  }
}

export class DescargarFacturasCliente {
  Error = '';
  descripcion: DescargarFacturasClienteDescripcion;
  Funcion: "";

  constructor(props) {
    if (props) {
      this.Error = props.Error;
      this.descripcion = typeof props.descripcion === 'object' ? new DescargarFacturasClienteDescripcion(props.descripcion) : props.descripcion;
    }
  }

  hasError() {
    return this.Error === 'si';
  }
}

export class DescargarFacturasClienteDescripcion {
  Estado = '';
  download_link = '';
  Respuesta = ArchivosFacturaElectronica;

  constructor(props) {
    this.Estado = props.Estado;
    this.download_link = props.download_link;
    this.Respuesta = typeof props.Respuesta === 'object' ? new ArchivosFacturaElectronica(props.Respuesta) : props.Respuesta;
  }
}

export class ArchivosFacturaElectronica {
  CodigoEjecucion = '';
  MensajeEjecucion = '';
  NumeroProcesoFacturaElectronica = '';
  constructor(props) {
    const propsArchivosFacturaElectronica = props.ArchivosFacturaElectronica;
    this.CodigoEjecucion = propsArchivosFacturaElectronica.CodigoEjecucion;
    this.MensajeEjecucion = propsArchivosFacturaElectronica.MensajeEjecucion;
    this.NumeroProcesoFacturaElectronica = propsArchivosFacturaElectronica.NumeroProcesoFacturaElectronica;
  }
}


export class QuoteClientInfoResponse {
  datos_cliente: QuoteClientInfo;
  tipo_cambio: QuoteTaxRate[];
  tipo_cambio_usd: QuoteTaxRateUSD[];
  tipo_pago: QuotePaymentType[];

  constructor(props) {
    if (props) {
      this.datos_cliente = new QuoteClientInfo(props.datos_cliente);
      this.tipo_cambio = props.tipo_cambio.map(x => new QuoteTaxRate(x));
      this.tipo_cambio_usd = props.tipo_cambio_usd.map(x => new QuoteTaxRateUSD(x));
      this.tipo_pago = props.tipo_pago.map(x => new QuotePaymentType(x));
    }
  }
}

export class QuoteClientInfo {
  DATOS_CLIENTE: QuoteClient[] = [];
  DOC_CON_SALDO: QuoteClientDoc[] = [];

  constructor(props) {
    if (props) {
      this.DATOS_CLIENTE = props.DATOS_CLIENTE ? props.DATOS_CLIENTE.map(x => new QuoteClient(x)) : [];
      this.DOC_CON_SALDO = props.DOC_CON_SALDO ? props.DOC_CON_SALDO.map(x => new QuoteClientDoc(x)) : [];
    }
  }
}

export class QuoteClient {
  NOMBRE_CLIENTE: string;
  DIRECCION: string;
  TIPO_CUENTA: string;
  LIMITE_CREDITO: string;
  CELULAR: string;
  TELEFONO: string;
  FAX: string;
  CORREO: string;
  CREDITO_DISPONIBLE: string;
  MONEDA_CLIENTE: string;
  TOTAL_CREDITOS: string;
  TOTAL_MORATORIOS: string;
  SALDO_A_HOY: string;
  KUNNR: string;

  constructor(props) {
    if (props) {
      this.NOMBRE_CLIENTE = props.NOMBRE_CLIENTE;
      this.DIRECCION = props.DIRECCION;
      this.TIPO_CUENTA = props.TIPO_CUENTA;
      this.LIMITE_CREDITO = props.LIMITE_CREDITO;
      this.CELULAR = props.CELULAR;
      this.TELEFONO = props.TELEFONO;
      this.FAX = props.FAX;
      this.CORREO = props.CORREO;
      this.CREDITO_DISPONIBLE = props.CREDITO_DISPONIBLE;
      this.MONEDA_CLIENTE = props.MONEDA_CLIENTE;
      this.TOTAL_CREDITOS = props.TOTAL_CREDITOS;
      this.TOTAL_MORATORIOS = props.TOTAL_MORATORIOS;
      this.SALDO_A_HOY = props.SALDO_A_HOY;
      this.KUNNR = props.KUNNR;
    }
  }
}

export class QuoteClientDoc {
  BLART: string;
  LTEXT: string;
  ZUONR: string;
  XBLNR: string;
  BLDAT: string;
  WRBTR: string;
  DMBTR: string;
  WAERS: string;
  SHKZG: string;
  SGTXT: string;
  PREIMPRESO: string;
  FECHA_VENCE: string;
  DIAS_VENCIDO: string;
  MONTO_MORATORIOS: string;
  TIPO_TRANS: number;
  MONTO_TOTAL: string;
  KUNNR: string;

  constructor(props) {
    if (props) {
      this.BLART = props.BLART;
      this.LTEXT = props.LTEXT;
      this.ZUONR = props.ZUONR;
      this.XBLNR = props.XBLNR;
      this.BLDAT = props.BLDAT;
      this.WRBTR = props.WRBTR;
      this.DMBTR = props.DMBTR;
      this.WAERS = props.WAERS;
      this.SHKZG = props.SHKZG;
      this.SGTXT = props.SGTXT;
      this.PREIMPRESO = props.PREIMPRESO;
      this.FECHA_VENCE = props.FECHA_VENCE;
      this.DIAS_VENCIDO = props.DIAS_VENCIDO;
      this.MONTO_MORATORIOS = props.MONTO_MORATORIOS;
      this.TIPO_TRANS = props.TIPO_TRANS;
      this.MONTO_TOTAL = props.MONTO_TOTAL;
      this.KUNNR = props.KUNNR;
    }
  }
}

export class QuoteTaxRate {
  CLIENTE: string;
  TIPO: string;
  CODIGO: string;
  NOMBRE: string;
  ZTRANSPORTE: string;
  NTRANSPORTE: string;
  CONDICIONPAGO: string;
  COD_ZONA_CLIENTE: string;
  ZONA_CLIENTE: string;
  TIPO_CAMBIO: string;
  MONEDA_CLIENTE: string;

  constructor(props) {
    if (props) {
      this.CLIENTE = props.CLIENTE;
      this.TIPO = props.TIPO;
      this.CODIGO = props.CODIGO;
      this.NOMBRE = props.NOMBRE;
      this.ZTRANSPORTE = props.ZTRANSPORTE;
      this.NTRANSPORTE = props.NTRANSPORTE;
      this.CONDICIONPAGO = props.CONDICIONPAGO;
      this.COD_ZONA_CLIENTE = props.COD_ZONA_CLIENTE;
      this.ZONA_CLIENTE = props.ZONA_CLIENTE;
      this.TIPO_CAMBIO = props.TIPO_CAMBIO;
      this.MONEDA_CLIENTE = props.MONEDA_CLIENTE;
    }
  }
}

export class QuoteTaxRateUSD {
  valor: string;

  constructor(props) {
    if (props) {
      this.valor = props.valor;
    }
  }
}

export class QuotePaymentType {
  BEZEI: string;
  WAERS: string;
  PAYMENT_TYPE: string;

  constructor(props) {
    if (props) {
      this.BEZEI = props.BEZEI;
      this.WAERS = props.WAERS;
      this.PAYMENT_TYPE = props.PAYMENT_TYPE;
    }
  }
}
