<app-header-text iconRight="none" titleIcon="card" (leftBtnClick)="backFn()" title="Seleccionar Tarjeta" ></app-header-text>
<ion-content>
<ion-grid>
  <div class="ion-padding ion-text-center">
  </div>
  <ion-list class="mtn-30">
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-radio-group [(ngModel)]="selectedCard">
            <ion-item class="item-card" *ngFor="let card of arrCards; index as i;">
              <ion-icon [name]="'card'"></ion-icon>
              <span>
                  <ion-label> {{card?.alias}} </ion-label>
                  <span class="number-credit-card">
                    **** {{card?.ultimos_digitos}}
                  </span>
                </span>
              <ion-radio slot="end" [value]="card"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-list>

  <div class="footer">
    <ion-button
      [disabled]="!selectedCard"
      color="buttonupdateprofile"
      expand="block"
      class="gq-button-actualizar btn-rounded ion-margin-horizontal"
      (click)="confirmData()"
    >Continuar
    </ion-button>
  </div>
</ion-grid>
</ion-content>
