import {Injectable} from '@angular/core';
import {SoapService} from '../services/soap/soap.service';
import {environment as env} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class VehiculosService {

    constructor(private soapService: SoapService) {
    }


    getVehiculosCliente(sociedad, codigoCliente) {
        const url = env.API_ENDPOINT;
        const atributos = {
            cod: env.WS_CODIGO,
            placa: '',
            tipo_placa: '',
            codigo_cliente: codigoCliente,
            sociedad,
            inventario: '',
            gps: 'X'
        };
        return this.soapService.post(url, 'consultar_vehiculos_cliente', atributos)
            .then((response: any) => {
              // convierto el response a un objeto JSON y si viene vacio le asigno un array vacio
                const vehiculos = JSON.parse(response.return);
                if (vehiculos.length === 0) {
                  localStorage.setItem("loadUserVehicles", "yes");
                }
                return JSON.parse(response.return);
            }).catch(error => {
                return error;
            });
    }


    async getAseguradora(codigo_sap, vin, tipo) {
        const url = env.API_ENDPOINT;
        const atributos = {
            cod: env.WS_CODIGO,
            codigo_sap,
            vin,
            tipo
        };
        return this.soapService.post(url, 'consulta_aseguradora', atributos)
            .then((response: any) => {
                return JSON.parse(response.return);
            }).catch(error => {
                return error;
            });
    }

    desligarVehiculo(sociedad, num_inventario, slc_justificacion, txt_justificacion, otra_justificacion, cod_cliente, placa, tipo_placa, chasis, marca, bapi, grupoq, tipo_desvinculacion) {

        const url = env.API_ENDPOINT;
        const atributos = {
            cod: env.WS_CODIGO,
            sociedad,
            num_inventario,
            slc_justificacion,
            txt_justificacion,
            otra_justificacion,
            cod_cliente,
            placa,
            tipo_placa,
            chasis,
            marca,
            bapi,
            grupoq,
            tipo_desvinculacion
        };
        return this.soapService.post(url, 'desligar_vehcle', atributos)
            .then((response: any) => {
                return JSON.parse(response.return);
            }).catch(error => {
                return error;
            });
    }

}
