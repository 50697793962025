import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { environment as env } from '../../environments/environment';
import { BaseService } from './base.service';
import { SoapService } from '../services/soap/soap.service';
import { ISociety, QCar, QUser } from '../models/global';
import { CatalogService } from './catalog.service';
import { FacebookLoginProvider, SocialAuthService } from "angularx-social-login";
import { HttpClient } from '@angular/common/http';
import { WorkshopsHelper } from '../helpers/workshops/workshopsHelper';
import { GeologoutService } from '../services/http/geologout.service';
import { PushNotificationService } from './push-notification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private userProfile: QUser;
  private userProfileDetail: any;
  private userMemberships: any;
  private token;
  private cantones: any;
  private distritos: any;
  private societies: any;
  private isRenewingToken: any;
  private isCrediQLogin = false;
  public appClass: any;
  public isAnyPopupActive = false;
  public crediQUserCode: any;
  public crediQUserProfile: any;
  public hasCrediQProfile = null;

  $userProfileDetail = new Subject<any>();
  $userCrediQ = new BehaviorSubject<any>(null);
  isLogged = new Subject<any>();
  $cantones = new Subject<any>();
  $distritos = new Subject<any>();
  $userMemberships = new Subject<any>();
  $societies = new Subject<any>();

  public _sesionIniciada: boolean = false;

  constructor(
    public bs: BaseService,
    protected soapService: SoapService,
    protected socialAuthService: SocialAuthService,
    protected geoLogout: GeologoutService) {
    this.token = this.getToken();
    this.userProfile = this.getProfile();
  }

  getLogInObservable() {
    return this.isLogged.asObservable();
  }

  getProfileDetailObservable() {
    return this.$userProfileDetail.asObservable();
  }

  getUserCrediQObservable() {
    return this.$userCrediQ;
  }

  pokeLogIn(data) {
    this.isLogged.next(data);
  }

  getUserCronosData() {
    const d = window.localStorage.getItem("httpUserData");
    return (d && JSON.parse(d).data) || null;
  }

  setUserCronosData(data) {
    window.localStorage.setItem("httpUserData", data);
  }

  pokeProfileDetail(data) {
    this.$userProfileDetail.next(data);
  }

  pokeUserCrediQ(data) {
    this.$userCrediQ.next(data);
  }

  pokeCantones(data) {
    this.$cantones.next(data);
  }

  pokeDistritos(data) {
    this.$distritos.next(data);
  }

  pokeMembership(data) {
    this.$userMemberships.next(data);
  }

  getMembershipsObservable() {
    return this.$userMemberships.asObservable();
  }

  isCrediQUser() {
    return (
      (this.getUserCrediQ() &&
        this.getUserCrediQ().hasOwnProperty("codigoCliente")) ||
      false
    );
  }

  // isFirstInstall() {
  //   const lc = window.localStorage.getItem("is_new_install");
  //   return !!!lc || lc !== "0" || false;
  // }

  // setFirstInstall(val: "1" | "0" = "1") {
  //   return window.localStorage.setItem("is_new_install", val);
  // }

  getCantonesObservable() {
    return this.$cantones.asObservable();
  }

  getDistritosObservable() {
    return this.$distritos.asObservable();
  }

  getToken() {
    return window.localStorage.getItem(
      env.production ? "prod:v5.2:token" : "qa:v5.2:token"
    );
  }

  getUserCrediQ(): any {
    try {
      return JSON.parse(
        window.localStorage.getItem(
          env.production ? "prod:crediq:user" : "qa:crediq:user"
        )
      );
    } catch (e) {
      return {};
    }
  }

  getCrediQUserEmail() {
    try {
      return this.getUserCrediQ().email;
    } catch (e) {
      return "";
    }
  }

  getMemberships() {
    return (
      this.userMemberships ||
      JSON.parse(
        window.localStorage.getItem(
          env.production ? "prod:memberships" : "qa:memberships"
        )
      )
    );
  }

  getProfile(): QUser {
    return (
      (this.userProfile && this.userProfile) ||
      JSON.parse(window.localStorage.getItem("usuario"))
    );
  }

  getUserCrediQCode(): any {
    return (this.getUserCrediQ() && this.getUserCrediQ().codigoCliente) || null;
  }

  getProfileDetail() {
    try {
      return (
        (this.userProfileDetail && this.userProfileDetail) ||
        JSON.parse(window.localStorage.getItem(`usuarioDetalle`))
      );
    } catch (e) {
      return {};
    }
  }

  getSocieties() {
    try {
      return (
        (this.societies && this.societies) ||
        JSON.parse(
          window.localStorage.getItem(
            env.production ? `prod:societies` : `qa:societies`
          )
        )
      );
    } catch (e) {
      return [];
    }
  }

  getSociedadUsuario() {
    return this.getProfile().sociedad;
  }

  getMaritalStatus(idsocieti: string = 'S206') {
    let response = "";
    try {
      return JSON.parse(localStorage.getItem('estadosCiviles'));
    } catch (error) {
      return response;
    }
  }

  getSociedadPaisUsuario() {
    try {
      const societyObj: ISociety = this.getSocieties()[this.getProfile().sociedad];
      return societyObj.COD_PAIS || societyObj.PAIS.toLowerCase();
    } catch {
      return 'sv';
    }
  }

  getSociedadNombrePaisUsuario() {
    try {
      return this.getSociedadPaisUsuarioObj().PAIS.toLowerCase().replace(' ', '_');
    } catch (error) {
      // console.log(error);
    }
  }

  getSociedadPaisVehiculo(vehicle: QCar) {
    try {
      const societyObj: ISociety = this.getSocieties()[vehicle.SOCIEDAD];
      return societyObj.COD_PAIS.toLowerCase() || societyObj.PAIS.toLowerCase();
    } catch (e) {
      // console.warn(e);
      return this.getSociedadPaisUsuario().toLowerCase();
    }
  }

  getSociedadPaisUsuarioObj() {
    return this.getSocieties()[this.getProfile().sociedad];
  }

  getCodigoUsuario() {
    return this.getProfile().codigo;
  }

  getEmailUser() {
    return this.getProfile().correo;
  }

  getPaisUsuario() {
    try {
      return this.getProfile().pais.toUpperCase();
    } catch (e) {
      return '';
    }
  }

  getUserTypeAbbr() {
    try {
      return this.getProfile().tipo_cliente;
    } catch (e) {
      return '';
    }
  }

  getUsuarioDocNumber() {
    try {
      return this.getProfile().numero_documento;
    } catch (e) {
      return '';
    }
  }

  isMembershipUser() {
    return !!!this.getUsuarioDocNumber();
  }

  getPaisCodeUsuario() {
    try {
      const codes = {
        salvador: "SV",
        nicaragua: "NI",
        honduras: "HN",
        guatemala: "GT",
        costa_rica: "CR",
        grupo_q_panama: "PA",
      };
      return codes[this.getProfile().pais.toLowerCase()] || "SV";
    } catch (e) {
      return "SV";
    }
  }

  setMemberships(memberships) {
    this.userMemberships = memberships;
    window.localStorage.setItem(
      env.production ? "prod:memberships" : "qa:memberships",
      JSON.stringify(memberships)
    );
  }

  setIsRenewingToken(status) {
    this.isRenewingToken = status;
  }

  setIsLoginCrediQ(status) {
    this.isCrediQLogin = status;
  }

  getIsLoginCrediQ() {
    return this.isCrediQLogin;
  }

  getIsRenewingToken() {
    return this.isRenewingToken;
  }

  setToken(token) {
    this.token = token;
    window.localStorage.setItem(env.production ? 'prod:v5.2:token' : 'qa:v5.2:token', token);
  }

  setCrediQUser(crediQProfile, persist = true) {
    this.crediQUserProfile = crediQProfile;
    if (persist) {
      window.localStorage.setItem(env.production ? 'prod:crediq:user' : 'qa:crediq:user', JSON.stringify(crediQProfile));
    }
  }

  setPasswdCrypted(passwd) {
    const result = [];
    const passLen = env.passcode.length;
    for (let i = 0; i < passwd.length; i++) {
      const passOffset = i % passLen;
      const calAscii = (passwd.charCodeAt(i) + env.passcode.charCodeAt(passOffset));
      result.push(calAscii);
    }
    window.localStorage.setItem(env.production ? 'prod:pcode' : 'qa:pcode', JSON.stringify(result));
  }

  getPasswd() {
    return window.localStorage.getItem(env.production ? 'prod:pcode' : 'qa:pcode');
  }


  getPasswdCrypted() {
    return this.decryptPassWd(window.localStorage.getItem(env.production ? 'prod:pcode' : 'qa:pcode'));
  }

  getPasswdCronosCrypted() {
    return this.decryptPassWd(this.getUserCronosData().salt);
  }

  decryptPassWd(pcode) {
    const result = [];
    let str = '';
    const codesArr = JSON.parse(pcode);
    const passLen = env.passcode.length;
    for (let i = 0; i < codesArr.length; i++) {
      const passOffset = i % passLen;
      const calAscii = (codesArr[i] - env.passcode.charCodeAt(passOffset));
      result.push(calAscii);
    }
    for (let i = 0; i < result.length; i++) {
      const ch = String.fromCharCode(result[i]);
      str += ch;
    }
    return str;
  }

  setProfile(user: QUser) {
    this.userProfile = null;
    this.userProfile = user;
    window.localStorage.setItem('usuario', JSON.stringify(user));
  }

  setProfileDetail(user) {
    this.userProfileDetail = user;
    window.localStorage.setItem(`usuarioDetalle`, JSON.stringify(user));
  }

  setSociedades(societies) {
    this.societies = societies;
    window.localStorage.setItem(env.production ? `prod:societies` : `qa:societies`, JSON.stringify(societies));
  }

  login(usuario: any, password: any, pushToken?: any) {
    const url = env.API_ENDPOINT;

    const atributos = {
      cod: env.WS_CODIGO,
      usuario,
      clave: password,
      sociedad: '',
      plataforma: '',
      tokenPush: pushToken === undefined ? "" : pushToken
    };

    return this.soapService.post(url, 'consulta_usuario', atributos)
      .then((response: any) => {
        this.saveUsername(usuario);
        return JSON.parse(response.return);
      }).catch(error => {
        // console.log(error);
        return error;
      });
  }

  saveUsername(usuario) {
    window.localStorage.setItem(env.settings.username, usuario);
  }

  getUsername() {
    return window.localStorage.getItem('username');
  }

  logout(dispatch = true) {
    this.userProfile = this.token = null;
    const imgs = localStorage.getItem('vehiclesImages');
    const fbDate = localStorage.getItem('fbDate');
    // window.localStorage.clear();
    this.geoLogout.obtenerPosicionActual();

    try {

      this.setProfile(null);
      this.setProfileDetail(null);

      const url = env.API_ENDPOINT;

      const atributos = {
        cod: env.WS_CODIGO,
        codCliente: this.getCodigoUsuario(),
      };

      this.soapService.post(url, 'desligar_push', atributos)
        .then((response: any) => {
          return JSON.parse(response.return);
        }).catch(error => {
          // console.log(error);
          return error;
        });
    } catch (e) {

    }


    if (dispatch) {
      // window.localStorage.clear();
    }

    if (imgs && imgs != 'null') {
      localStorage.setItem('vehiclesImages', imgs);
    }
    localStorage.setItem('fbDate', fbDate);

    // if (this.bs.isDesktop() && (!movil)) {
    //   return window.dispatchEvent(new CustomEvent('user:logout-web'));
    window.dispatchEvent(new CustomEvent('user:logout-web'));
    // }
    // localStorage.setItem('cookiesTrueGQ', cookiesTrueGQ);
    // localStorage.setItem('fbDate', fbDate);

    // if (dispatch) {
    //   window.dispatchEvent(new CustomEvent('user:logout-web'));
    // }
  }

  // consultaPerfil(codigoCliente) {
  //   const url = env.API_ENDPOINT;
  //   const atributos = {
  //     cod: env.WS_CODIGO,
  //     codigo_sap: codigoCliente
  //   };
  //   return this.soapService.post(url, 'consulta_perfil', atributos)
  //     .then((response: any) => {
  //       return JSON.parse(response.return);
  //     }).catch(error => {
  //       return error;
  //     });
  // }

  consultaPerfil(codigoCliente, sociedad, numDoc, tipoCliente) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      sociedad,
      codigoCliente,
      numDoc,
      tipo_evento: tipoCliente,
      prospecto: 'No',
      lead: 'No',
      id_area: 18,
    };
    return this.soapService.post(url, 'getDatosCliente_codigo', atributos)
      .then((response: any) => {

        return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  actualizaPerfil(tipo, sociedad, data_cliente, contactos) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      // tipo: tipo,
      sociedad: sociedad,
      data_cliente: data_cliente,
      // contactos: contactos
    };
    return this.soapService.post(url, 'solicitud_actualizacion_mdg', atributos)
      // return this.soapService.post(url, 'actualiza_perfil', atributos)
      .then((response: any) => {
        return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  updatePassword(sociedad, correo, cod_cliente, old_password, new_password, tipo_cliente) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      sociedad: sociedad,
      correo: correo,
      codigo_cliente: cod_cliente,
      old_password: old_password,
      new_password: new_password,
      tipo_cliente: tipo_cliente.toUpperCase()[0]
    };
    return this.soapService.post(url, 'cambiar_password', atributos)
      .then((response: any) => {
        return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  cargarMembresias(codigoCliente) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      codigo: codigoCliente
    };
    return this.soapService.post(url, 'consulta_membresia_adicional', atributos)
      .then((response: any) => {
        return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  deleteMembresia(sociedad, id_membresia, codigo) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      id_membresia: id_membresia,
      cod_sociedad: sociedad,
      cod_cliente: codigo
    };
    return this.soapService.post(url, 'delete_membresia', atributos)
      .then((response: any) => {
        return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  incluirMembresia(sociedad, tipo_cliente, cod_cliente, data_membresia, data_correo) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      sociedad: sociedad,
      tipo_cliente: tipo_cliente.toLowerCase()[0],
      cod_cliente: cod_cliente,
      data_membresia: data_membresia,
      data_correo: data_correo
    };
    return this.soapService.post(url, 'agregar_membresia_adicional', atributos)
      .then((response: any) => {
        return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  agregarAseguradora(codigo_cliente, id_aseguradora, poliza, placa, marca, modelo, vin, cod_marca, sociedad) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      codigo_cliente: codigo_cliente,
      id_aseguradora: id_aseguradora,
      poliza: poliza,
      placa: placa,
      marca: marca,
      modelo: modelo,
      vin: vin,
      cod_marca: cod_marca,
      sociedad: sociedad
    };
    return this.soapService.post(url, 'aseguradora_cliente', atributos)
      .then((response: any) => {
        return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  forgotPassword(email) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      sociedad: '',
      correo: email
    };
    return this.soapService.post(url, 'recuperar_clave', atributos)
      .then((response: any) => {
        response = JSON.parse(response.return);
        return response[0];
      }).catch(error => {
        return error;
      });
  }

  activarCuenta(email) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      codigo_sap: '',
      correo: email
    };
    return this.soapService.post(url, 'activacion', atributos)
      .then((response: any) => {
        response = JSON.parse(response.return);
        return response[0];
      }).catch(error => {
        return error;
      });
  }

  validateEmail(mail: any) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(mail).toLowerCase());
  }

  saveFbID(fbID, userID) {
    return this.bs.post([env.cronos.updateFbID], { uuid: userID, user_fb_id: fbID })
  }

  fbLogin(fbID) {
    return this.bs.post([env.cronos.fbLogin], { user_fb_id: fbID });
  }

  signInWithFB() {
    return this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  getWSCode() {
    // const sociedad = this.getSociedadUsuario();
    // const codes = {
    //   S101: "815d15bb21dc21df8205085cbf0c85dc",
    //   C101: "fa2bbd11d52109cf0a39bd590beab7eb",
    //   G101: "fcafc19e57273bd81d6cf7d573c1e220",
    //   S106: "815d15bb21dc21df8205085cbf0c85dc",
    //   N105: "276695fb56f5200193216e028df0f864",
    //   C105: "fa2bbd11d52109cf0a39bd590beab7eb"
    // };
    // return codes[sociedad];
    try {
      const societyObj: ISociety = this.getSocieties()[this.getProfile().sociedad];
      return societyObj.token_FacturaElectronica;
    } catch (error) {
      // console.log("error, try-catch, getWSCode", error);
    }
  }

}
