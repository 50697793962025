<div class="ion-text-center ion-padding pop-header">
  <ion-text>EDITAR INFORMACIÓN</ion-text>
</div>
<ion-list>

  <ion-item>
    <ion-label position="stacked">Aseguradora:</ion-label>
    <ion-select [(ngModel)]="insurance.aseguradora" interface="action-sheet" cancelText="Cancelar" okText="Aceptar">
      <ion-select-option [disabled]="valueAseg.id_lealtad_aseguradora == '0'" *ngFor="let valueAseg of aseguradoras"
                         [value]="valueAseg.id_lealtad_aseguradora">{{valueAseg.nombre}}</ion-select-option>
    </ion-select>
  </ion-item>

  <ion-item>
    <ion-label position="stacked">No de póliza:</ion-label>
    <ion-input type="text" [(ngModel)]="insurance.poliza"
               class="ion-text-left"></ion-input>
  </ion-item>
</ion-list>

<ion-row>
  <ion-col class="ion-no-padding">
    <ion-button class="ion-no-margin" (click)="agregarAseguradora(vehiculo)" expand="full" fill="clear">Guardar</ion-button>
  </ion-col>
  <ion-col class="ion-no-padding">
    <ion-button (click)="dismissModal()" class="ion-no-margin" expand="full" fill="clear">Cancelar</ion-button>
  </ion-col>
</ion-row>
<ion-button (click)="desasociarVehiculo(vehiculo)" class="ion-no-margin btn-desasociar" expand="full" fill="clear">DESASOCIAR VEHÍCULO</ion-button>
