import {Injectable} from '@angular/core';
import {
  NotifyCrediqTokenizationComponent
} from '../../components/notify-crediq-tokenization/notify-crediq-tokenization.component';
import {ModalController, PopoverController} from '@ionic/angular';
import {UtilService} from '../../providers/util.service';
import {scheduled} from "rxjs";
import {Location} from "@angular/common";
import {LoanService} from "../../providers/crediq/loan.service";
import {AuthService} from "../../providers/auth.service";

@Injectable({
  providedIn: 'root'
})

export class NotifyCrediqTokenizationHelper {
  constructor(private popoverController: PopoverController, private utilService: UtilService, private location: Location, private loanService: LoanService, private authService: AuthService, private modalCtrl: ModalController) {
  }

   setStatusNotifyCrediqTokenization(result: any) {
    if (result.hasOwnProperty('notificar_tokenizacion')) {
      const notifyCrediqTokenization = {
        notifyCrediqTokenizationLocal: result?.notificar_tokenizacion,
        notifyCrediqTokenizationWs: result?.notificar_tokenizacion,
      };
      window.localStorage.setItem('notifyCrediqTokenization', JSON.stringify(notifyCrediqTokenization));
    } else {
      void this.utilService.presentToast('Crediq Tokenization flag not found');
    }
  }

  getStatusNotifyCrediqTokenization() {
    return window.localStorage.getItem('notifyCrediqTokenization');
  }

  setHardStatusNotifyCrediqTokenization(status: boolean = true) {
    const notifyTokenization = this.getStatusNotifyCrediqTokenization();
    const notifyTokenizationJson = JSON.parse(notifyTokenization);
    const response = {
      ...notifyTokenizationJson
    };
    response.notifyCrediqTokenizationLocal = status;
    window.localStorage.setItem('notifyCrediqTokenization', JSON.stringify(response));
  }

  validateStatusNotifyCrediqTokenization() {
    const notifyTokenization = this.getStatusNotifyCrediqTokenization();
    const notifyTokenizationJson = JSON.parse(notifyTokenization);
    const response = {
      ...notifyTokenizationJson
    };
    if (response.notifyCrediqTokenizationLocal) {
      void this.presentPopoverNotifyCrediqTokenization();
    }
  }

  async presentPopoverNotifyCrediqTokenization() {
    const popover = await this.popoverController.create({
      id: 'popover_notifyCrediqTokenization',
      component: NotifyCrediqTokenizationComponent,
      cssClass: 'my-custom-class',
      backdropDismiss: false,
      componentProps: {},
      translucent: false
    });
    popover.onDidDismiss().then((data) => {
      // Accept: exit_with_accept
      // No Accept: exit_without_accept
      if (data.data === 'exit_without_accept') {
        this.setProcessFailed(data.data);
      } else if (data.data === 'exit_with_accept') {
        this.utilService.dismissLoading();
        this.loanService.attendNotificationOfChangeOfGateway(this.authService.getPaisCodeUsuario(), this.authService.getUsername())
          .then((resp: any) => {
            if (resp !== null) {
              if (resp['process-status'] && resp['process-status'] === 'success') {
                this.setHardStatusNotifyCrediqTokenization(false);
                this.utilService.presentToast(resp.message);
              } else {
                this.utilService.presentToast(resp.message);
                this.setProcessFailed(data.data);
              }
            } else {
              this.utilService.presentToast('Error al obtener estado de notificación.');
              this.setProcessFailed(data.data);
            }
          }).catch((error: any) => {
          this.setHardStatusNotifyCrediqTokenization(true);
          console.log('Error ', error);
        });
      } else {
        this.utilService.presentToast('Status not found.');
      }
    });
    return await popover.present();
  }

  setProcessFailed(action: string) {
    this.setHardStatusNotifyCrediqTokenization(true);
    setTimeout(() => {
      // Comprobamos que el modal con el id exista
      if (document.getElementById('payment-web-modal')) {
        // Cerramos el modal
        void this.modalCtrl.dismiss(null, null, 'payment-web-modal');
      }
    } , 0);
    if (action !== 'exit_without_accept') {
      this.validateStatusNotifyCrediqTokenization();
    }
  }
}
