<ion-header class="ion-no-border" [ngClass]="{'sticky-header': sticky}">
  <ion-toolbar>
    <ion-button (click)="btnLeftClicked()" *ngIf="!isIconDefault" fill="clear" slot="start">
      <ion-icon [name]="iconLeft"></ion-icon>
    </ion-button>
    <ion-button *ngIf="isIconDefault" (click)="btnLeftClicked()" fill="clear" slot="start" class="ion-text-center ion-no-padding ion-margin-start no-ripple">
      <ion-icon class="bck-button" name="chevron-back" color="light"></ion-icon>
    </ion-button>
    <ion-title class="ion-text-center">
      <ion-icon *ngIf="titleIcon" [name]="titleIcon"></ion-icon><span class="ion-margin-start font-weight-bold fs-18">{{title}}</span>
    </ion-title>
    <ion-button class="right-btn ion-margin-end" (click)="btnRightClicked()" *ngIf="(iconRight) && (iconRight != 'none')" fill="clear" slot="end">
      <ion-icon [name]="iconRight"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>
