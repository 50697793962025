<ion-app [class.dark-theme]="false" [ngClass]="authService.appClass">

  <ion-menu *ngIf="authService.getToken()" contentId="content" menuId="content" side="end">
    <ion-content>
      <ion-list lines="none">
        <div class="ion-text-center">
          <img src="assets/imgs/logo_home_re.webp" alt="">
        </div>
        <!--        <div class="ion-text-center ion-margin-top">-->
        <!--          <circle-progress-->
        <!--            [units]="' / 100'"-->
        <!--            [unitsFontSize]="'15'"-->
        <!--            [percent]="getGlobalScore(vehicleHelper.getCurrentVehicle())"-->
        <!--            [radius]="55"-->
        <!--            [backgroundPadding]="0"-->
        <!--            [titleFontSize]="'25'"-->
        <!--            [subtitleFontSize]="'15'"-->
        <!--            showBackground="false"-->
        <!--            [subtitle]="[-->
        <!--              'Calificación',-->
        <!--              'Global'-->
        <!--            ]"-->
        <!--            [outerStrokeWidth]="8"-->
        <!--            [space]="-8"-->
        <!--            [outerStrokeColor]="getGlobalScoreColor()"-->
        <!--            [innerStrokeColor]="'#e1e1e1'"-->
        <!--            [showInnerStroke]="true"-->
        <!--            [innerStrokeWidth]="8"-->
        <!--            [animation]="true"-->
        <!--            [animationDuration]="600"-->
        <!--          ></circle-progress>-->
        <!--        </div>-->
        <ion-list-header>
          Menu
        </ion-list-header>
        <ion-menu-toggle autoHide="true" *ngFor="let p of appPages; let i = index">
          <ion-item *ngIf="checkMembershipRestriction(p.title)" [routerLink]="p.url" routerLinkActive="selected" routerDirection="root" detail="false">
            <ion-icon slot="start" [name]="p.icon + '-outline'"></ion-icon>
            <ion-label>
              {{p.title}}
            </ion-label>
            <ion-text class="total-points-benefits" *ngIf="p.title === 'Beneficios'">
              {{pointsClient}} Pts
            </ion-text>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle autoHide="true">
          <ion-item (click)="logout()">
            <ion-icon slot="start" [name]="'power-outline'"></ion-icon>
            <ion-label>
              Cerrar Sesión
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>

    </ion-content>
  </ion-menu>

  <ion-router-outlet id="content"
                     style="pointer-events: auto !important; touch-action: auto !important;"></ion-router-outlet>


</ion-app>
