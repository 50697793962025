import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WorkshopsHelper } from '../helpers/workshops/workshopsHelper';

@Injectable({
  providedIn: 'root'
})
export class SqlandingGuard implements CanActivate {

  constructor(public workshopHelper: WorkshopsHelper, private router: Router) { }

  async canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    try {
      // if (this.workshopHelper.selectedCountry !== 'CR' && this.workshopHelper.selectedCountry !== 'SV' && this.workshopHelper.selectedCountry !== 'HN' && this.workshopHelper.selectedCountry !== 'NI') {
      //   this.router.navigate(["/landing"]);
      //   return false;
      // } else {
      //   return true;
      // }
      return true;
    } catch (error) {
      console.log(error);
      this.router.navigate(["/landing"]);
      return false;
    }
  }
}
