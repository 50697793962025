import { Injectable } from '@angular/core';
import SOAPClient from './soapclient.js';
import { environment, environment as env } from '../../../environments/environment';
import { UtilService } from "../../providers/util.service";
import { AppConfigService } from "../../providers/app-config.service";
import { Router } from "@angular/router";

@Injectable()
export class SoapService {

  public soapParams: any;
  public soapClient: any;

  constructor(private utilService: UtilService,
              private appConf: AppConfigService,
              private router: Router) {
  }

  post(url, action, params) {

    var sr = '<?xml version="1.0" encoding="utf-8"?>' +
      '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns="http://rxmpws.com/">' +
      '<soap:Header>' +
      '<Authentication xmlns="http://domain.com/">' +
      '<Password>password</Password>' +
      '+<UserName>username</UserName>' +
      '</Authentication>' +
      '</soap:Header>' +
      '<soapenv:Body>' +
      '' +
      '</soapenv:Body>' +
      '</soapenv:Envelope>';


    this.soapParams = new SOAPClient.SOAPClientParameters;
    this.soapClient = SOAPClient.SOAPClient;
    return new Promise((resolve, reject) => {
      //Create SOAPClientParameters
      for (var param in params) {
        this.soapParams.add(param, params[param]);
      }
      //Create Callback
      var soapCallback = (e, status) => {
        if (e == null || e.constructor.toString().indexOf('function Error()') != -1) {
          const errorList = this.appConf.getAppConfig();

          if (action in errorList.migrupoq) {
            const error = errorList.migrupoq[action];
            this.utilService.presentToast(error.message, 'bottom', 'danger', `Código ${error.code}`, [{
              text: 'Ayuda',
              handler: () => this.router.navigateByUrl('/help')
            }], 'interceptor-toast');
          }

          reject('No se puede conectar con el servidor' + status);
        } else {
          if (action == 'recuperar_clave' || action == 'activacion' || action == 'desligar_push' || params.clave || window.localStorage.getItem(env.production ? 'prod:pcode' : 'qa:pcode')) {
            resolve(e);
          } else {
            // backup images
            const appVersion = localStorage.getItem('appVersion');
            const imgs = localStorage.getItem('vehiclesImages');
            const fbDate = localStorage.getItem('fbDate');
            const isNewInstall = localStorage.getItem('isNewInstall');
            const smartToken = window.localStorage.getItem(environment.smartq.storagekeys.smartToken);
            const cookiesTrueGQ = window.localStorage.getItem('cookiesTrueGQ');
            window.localStorage.clear();
            localStorage.setItem('appVersion', appVersion);
            localStorage.setItem('vehiclesImages', imgs);
            localStorage.setItem('fbDate', fbDate);
            localStorage.setItem('isNewInstall', isNewInstall === 'null' ? '1' : isNewInstall);
            localStorage.setItem('cookiesTrueGQ', cookiesTrueGQ);
            if (smartToken) {
              localStorage.setItem(environment.smartq.storagekeys.smartToken, smartToken);
            }
          }
        }
      };
      this.soapClient.invoke(url, action, this.soapParams, true, soapCallback);
    });
  }

  setCredentials(username, password) {
    this.soapClient.username = username;
    this.soapClient.password = password;
  }
}
