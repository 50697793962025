<ion-content style="box-shadow: none; border: none;">
<!--  // Div centrado para poner mi spinner de ionic-->
  <div class="spinner" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
    <ion-grid class="vh-aligned ion-no-padding">
      <ion-card class="payment-processing-card">
        <ion-card-header>
          <ion-card-subtitle><strong>Procesando tu tarjeta</strong></ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
          <div class="processing-content">
            <ion-spinner name="crescent"></ion-spinner>
            <p>Por favor, no cierres esta ventana hasta que recibas un mensaje de confirmación.</p>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-grid>
  </div>
  <iframe #frameAddNewCardFoyPayment id="frameAddNewCardFoyPayment" [src]="url" width="100%" height="50%"
          (load)="onIframeLoad($event)" frameborder="0"></iframe>
</ion-content>
