<ion-content>
  <div class="mt-5" style="margin-top: 20px"></div>
  <ion-row class="ion-align-items-center bck-btn-cnt w-100">
    <ion-col size="2" class="ion-text-center ion-no-padding">
      <ion-icon class="bck-button" name="chevron-back" color="light"></ion-icon>
    </ion-col>
  </ion-row>
  <div class="spinner" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
    <ion-grid class="vh-aligned ion-no-padding">
      <ion-card class="payment-processing-card">
        <ion-card-header>
          <ion-card-subtitle><strong>Procesando tu pago</strong></ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
          <div class="processing-content">
            <ion-spinner name="crescent"></ion-spinner>
            <p>Por favor, no cierres esta ventana hasta que recibas un mensaje de confirmación.</p>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-grid>
  </div>
  <iframe #frameTest
          id="frameTest"
          [src]="url"
          width="100%"
          height="100%"
          (load)="onIframeLoad($event)"
          frameborder="0"
          allowfullscreen
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-modals"
  >
  </iframe>
</ion-content>
