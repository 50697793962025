import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Geolocation, Geoposition} from '@ionic-native/geolocation/ngx';
import {WorkshopsHelper} from '../../helpers/workshops/workshopsHelper';
import {GeoService} from './geo.service';
import {BenefitHelper} from "../../helpers/benefits/BenefitHelper";

@Injectable({
  providedIn: 'root'
})
export class GeologoutService {

  paisUsuario: any;
  geolocationUser: any;
  latitudActual: any;
  longitudActual: any;

  constructor(
    private http: HttpClient,
    private workshopHelper: WorkshopsHelper,
    private geolocation: Geolocation,
    private benefitHelper: BenefitHelper,
  ) {

  }


  setearPais(pais: string = "SV") {
    window.localStorage.setItem("paisVisita", pais);
  }

  paisPermitido(pais: any) {
    let paises = ["SV", "CR", "NI", "HN", "GT", "PA"];
    if (paises.includes(pais)) {
      return pais;
    }
    return "SV";
  }

  obtenerPosicionActual() {
    // const cookiesTrueGQ = localStorage.getItem('cookiesTrueGQ') === null ? JSON.stringify(true) : JSON.stringify(false);
    return this.http.get("https://geolocation-db.com/json/").subscribe((arg: any) => {
      const country = arg.country_code;
      if (country) {
        this.workshopHelper.selectedCountry = this.paisPermitido(country);
        this.updateBenefitsGlobal(this.paisPermitido(country));
        this.setearPais(this.paisPermitido(country));
      } else {
        this.obtenerPaisWithGPS();
      }
      localStorage.setItem('cookiesTrueGQ', JSON.stringify(true));
    }, err => {
      this.obtenerPaisWithGPS();
      localStorage.setItem('cookiesTrueGQ', JSON.stringify(true));
    });
  }

  updateBenefitsGlobal(country: string) {
    this.workshopHelper.handler(country);
  }

  obtenerPaisWithGPS() {
    this.geolocationUser = this.geolocation.getCurrentPosition().then((arg: Geoposition) => {
      this.latitudActual = arg.coords.latitude;
      this.longitudActual = arg.coords.longitude;
      const geocoder = new google.maps.Geocoder();
      let location;
      location = new google.maps.LatLng(this.latitudActual, this.longitudActual);
      geocoder.geocode({location: location}, (result, status) => {
        this.processLocation(result);
      });
    }, err1 => {
      //this.utilService.presentToast("Para mostrar resultados de acuerdo a tu ubicación debes concedernos permisos.");
      this.workshopHelper.selectedCountry = "SV";
      this.updateBenefitsGlobal("SV");
      this.setearPais();
    });
  }

  processLocation(location) {
    if (location[1]) {
      for (var i = 0; i < location.length; i++) {
        if (location[i].types[0] === "locality") {
          let city = location[i].address_components[0].short_name;
          let state = location[i].address_components[2].short_name;
          let country = location[i].address_components[2].short_name;
          this.workshopHelper.selectedCountry = this.paisPermitido(country);
          this.setearPais(this.paisPermitido(country));
          this.updateBenefitsGlobal(this.paisPermitido(country));
        }
      }
    }
  }
}
