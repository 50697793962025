import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { DriverScoreHelper } from '../../helpers/smartq/DriverScoreHelper';
import { VehicleHelper } from '../../helpers/VehicleHelper';
import { calificacionConductorSQ } from '../../pages/smartq/models';
import { UtilService } from "../../providers/util.service";
import { Subject, Subscription } from 'rxjs';
import { delay, takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-score-driver',
  templateUrl: './score-driver.component.html',
  styleUrls: [ './score-driver.component.scss' ],
})
export class ScoreDriverComponent implements OnInit, OnDestroy {

  selectedMonthText = '';
  selectedYearText = '';
  graphColor: string = '#000000';
  arrayResume: any[] = [];
  currentScore: calificacionConductorSQ;
  backBtnSubscriber: Subscription;
  currentDate = new Date();
  selectedMonth = `${this.currentDate.getFullYear()}, ${(this.currentDate.getMonth() + 1).toString().padStart(2, '0')}`;
  isFetching = false;

  private destroy$: Subject<any> = new Subject<any>();

  sampleResponse = {
    calificacion: '100/100',
    formattedScore: '100',
    scoreMessage: '',
    dias: 10,
    error: '',
    eventos: [],
    resumen: {
      "arrancadas_fuertes": 2,
      "aceleradas_bruscas": 0,
      "frenadas_bruscas": 0,
      "giros_bruscos": 0,
      "excesos_velocidad": 0,
      "segundos_en_exceso_de_velocidad": 0,
      "posibles_colisiones": 0,
      "accidentes": 0
    }
  };

  constructor(
    private vehicleHelper: VehicleHelper,
    public platform: Platform,
    private driverScoreHelper: DriverScoreHelper,
    private utilService: UtilService,
    private modalCtrl: ModalController) {
    this.currentScore = driverScoreHelper.getUserScoreByVIN(vehicleHelper.getCurrentVehicle());
    // this.currentScore = new calificacionConductorSQ(this.sampleResponse);
    // [this.currentScore.calificacion] = this.currentScore.calificacion.split("/");

    this.selectedYearText = `${new Date().getFullYear()}`;
    this.arrayResume = !this.currentScore.hasError() && Object.entries(this.currentScore.resumen) || [];

    this.selectedMonthText = this.getFormattedMonth();

    this.driverScoreHelper.getUserScoresAsObservable()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((data: calificacionConductorSQ) => {
        this.isFetching = false;
        if (typeof data !== 'string') {
          this.currentScore = data;
          this.arrayResume = Object.entries(data.resumen);
        } else {
          this.arrayResume = [];
          // this.utilService.presentToast('No se han encontrado resultados');
        }
        this.utilService.dismissLoading();
      });
  }

  getFormattedMonth(date = '') {
    const d = date ? new Date(date) : new Date();
    const currentMonth = d.toLocaleDateString('es-ES', {month: 'long'});
    const result = `${currentMonth.charAt(0).toUpperCase()}${currentMonth.slice(1)}`;
    return result;
  }

  getFormattedYear(date = '') {
    const d = date ? new Date(date) : new Date();
    return d.getFullYear();
  }

  ngOnInit() {
    this.backBtnSubscriber = this.platform.backButton.subscribeWithPriority(999999, () => {
      this.modalCtrl.dismiss({});
    });
    this.fetchScoreByMonth();
  }

  ngOnDestroy() {
    this.backBtnSubscriber.unsubscribe();
    this.destroy$.next({});
    this.destroy$.complete();
  }

  getCurrentScore() {
    return this.currentScore;
  }

  getCurrentScoreAmount() {
    // tslint:disable-next-line:no-bitwise
    return ~~(this.getCurrentScore()?.calificacion || 0);
  }

  getColorGraph() {
    return UtilService.getColorGraphByScore(this.getCurrentScore().calificacion);
  }

  formatTextResume(text: string) {
    text = text.replace(/_/g, " ").toUpperCase();
    return text;
  }

  async fetchScoreByMonth() {
    let test: any = this.selectedMonth;
    if (typeof test !== 'object') {
      if (test.length < 8) {
        test = new Date(`${test}-01`.replace(/-/g,'/'));
      } else {
        test = new Date(this.selectedMonth);
      }
      // test = `${this.selectedMonth.getFullYear()}-${(this.selectedMonth.getMonth() + 1).toString().padStart(2, '0')}`;
    }
    this.selectedMonthText = this.getFormattedMonth(`${test}`);
    this.selectedYearText = this.getFormattedYear(`${test}`).toString();
    if(!this.isFetching){
      this.isFetching = true;
      await this.utilService.presentLoading();
      this.driverScoreHelper.getUserScoreWS(this.vehicleHelper.getCurrentVehicle(), true, test, true);
      // this.driverScoreHelper.pokeUserScores(new calificacionConductorSQ(this.driverScoreHelper.fakeCalificacion));
    }
  }

  closeModal(data) {
    this.modalCtrl.dismiss(data);
  }

}
