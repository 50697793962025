import { Injectable } from '@angular/core';
import {Storage} from '@ionic/storage';
import {environment as env} from '../../environments/environment';
import {Observable, Subject} from 'rxjs';

const CURRENT_THEME = env.settings.key_current_theme;

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  darkMode = false;
  darkMode$ = new Subject<boolean>();

  constructor(private storage: Storage) {
    this.loadTheme().then();
  }

  getObservableTheme(): Observable<boolean> {
    return this.darkMode$.asObservable();
  }

  /**
   * load current theme
   */
  async loadTheme() {
    // load theme
    this.darkMode = await this.storage.get(CURRENT_THEME);

    if (this.darkMode == null) {
      this.darkMode = false;
      this.darkMode$.next(false);
    } else {
      this.darkMode$.next(this.darkMode);
      document.body.classList.toggle('dark', this.darkMode);
    }
  }

  /**
   * change theme
   * @param dark
   */
  changeTheme(dark = false) {
    // set dark mode and send changes throw next() method
    this.darkMode = dark;
    this.darkMode$.next(this.darkMode);

    // change theme and save it in storage
    document.body.classList.toggle('dark', this.darkMode);
    this.storage.set(CURRENT_THEME, this.darkMode).then(() => { });
  }
}
