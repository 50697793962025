<ion-content>
  <ion-item color="primary" class="header-help-section">
    <ion-icon (click)="goBack()" slot="start" class="bck-button-transparent" name="chevron-back" color="white">
    </ion-icon>
    <ion-chip color="primary" class="title-center">
      <img class="logo-seccion" [src]="logo" />
      <ion-label class="title-talleres">FAQ</ion-label>
    </ion-chip>
    <ion-chip slot="end" color="maincardsnavigation" outline="false">
      <ion-label></ion-label>
    </ion-chip>
  </ion-item>
  <ion-grid>
    <ion-row *ngIf="!authService.bs.isDesktop()" class="ion-margin-top ion-align-items-center">
      <ion-col (click)="dismissModal()" size="2" class="ion-text-center ion-no-padding">
        <ion-icon class="bck-button" name="chevron-back" color="light"></ion-icon>
      </ion-col>
      <ion-col size="10" class="fsz-14 ion-text-center">
        <ion-icon name="help-circle" color="gptitle"></ion-icon>
        <ion-text class="ion-margin-start" color="gptitle">Preguntas frecuentes</ion-text>
      </ion-col>
    </ion-row>

    <div class="ion-padding">
      <p class="gq-titulos">¿Qué es MiGrupoQ?</p>
      <p>Es el programa de lealtad creado para agradecer tu preferencia por la compra de tu(s) vehículo(s) nuevo(s) en
        Grupo Q.</p>

      <br>
      <p class="gq-titulos">¿Cuáles son los tipos de membresía MiGrupoQ?</p>
      <p>Actualmente MiGrupoQ cuenta con 3 tipos de membresía: Plata, Oro y Platinum. Cada segmento o cada tipo de
        membresía se calculan cada año según estos criterios:</p>

      <ion-thumbnail>
        <ion-img [src]="'/assets/imgs/ayuda/pregunta1.png'"></ion-img>
      </ion-thumbnail>

      <br>
      <p class="gq-titulos">¿Qué beneficios me brinda Grupo Q por la compra de mi vehículo nuevo?</p>
      <p>En agradecimiento a tu preferencia en Grupo Q, te otorgamos, según tu tipo de membresía MiGrupoQ</p>
      <p>En el mes de tu cumpleaños:</p>
      <ion-thumbnail>
        <ion-img [src]="'/assets/imgs/ayuda/pregunta2.png'"></ion-img>
      </ion-thumbnail>
      <p>En tus mantenimientos preventivos y servicios de taller:</p>
      <ion-thumbnail>
        <ion-img [src]="'/assets/imgs/ayuda/pregunta3.png'"></ion-img>
      </ion-thumbnail>

      <br>
      <p class="gq-titulos">¿Tengo descuentos con aliados afiliados a MiGrupoQ?</p>
      <p>Si, con tu membresía MiGrupoQ también disfrutás de descuentos y promociones especiales para miembros del
        programa
        de lealtad, aliados en la región centroamericana. Para hacer uso de estos beneficios, es indispensable
        identificarse siempre con la membresía MiGrupoQ electrónica o física.</p>

      <br>
      <p class="gq-titulos">¿Aún puedo utilizar mi membresía física?</p>
      <p>Si podés utilizar tu membresía física, tanto en talleres Grupo Q como en aliados en la región
        centroamericana,
        son embargo, tomá en cuenta que tu membresía MiGrupoQ física tiene fecha de vencimiento, la cual va descrita
        en
        ella, por lo que te recomendamos acceder a tu membresía electrónica desde la APP MiGrupoQ. Para soporte podés
        escribirnos a migrupoq@grupoq.com</p>

      <br>
      <p class="gq-titulos">¿Tiene costo mi membresía MiGrupoQ?</p>
      <p>No, la membresía MiGrupoQ es un beneficio gratuito para nuestros miembros, accedé a ella descargando la APP
        MiGrupoQ. Para soporte escribinos a migrupoq@grupoq.com</p>

      <br>
      <p class="gq-titulos">¿Tengo que cambiar o renovar mi membresía MiGrupoQ?</p>
      <p>No, para el caso de la membresía electrónica se actualizará automáticamente una vez al año. En el caso que
        tengás
        membresía física te recomendamos acceder a la membresía electrónica disponible en la APP MiGrupoQ para tu
        facilidad y conveniencia.</p>

      <br>
      <p class="gq-titulos">¿Qué es la APP MiGrupoQ?</p>
      <p>Es la nueva aplicación móvil exclusiva para nuestros miembros MiGrupoQ. En ella tenés acceso a toda tu
        información personal y de tu vehículo, además la plataforma de servicios en línea con la que podrás realizar
        cita de taller, consultar estatus de tu vehículo cuando esté en taller, consultar el historial de
        mantenimientos, consultar las promociones disponibles en talleres Grupo Q, entre muchas más. La nueva APP
        MiGrupoQ se encuentra disponible en teléfonos para versión Android y Apple.</p>

      <br>
      <p class="gq-titulos">¿Necesito usuario y contraseña para ingresar a la APP MiGrupoQ?</p>
      <p>Sí, es necesario. Para tu seguridad debés crear un usuario personal con el que podrás acceder a tu
        información y
        la de tu vehículo. La activación de usuario la podés realizar fácilmente con tu correo electrónico registrado
        en
        Grupo Q ya sea desde la APP o desde migurpoq.com</p>

      <br>
      <p class="gq-titulos">¿Cómo hago una cita de taller en línea?</p>
      <p>Para tu comodidad, tenemos disponibles dos canales para realizar tu próxima cita de taller sin necesidad de
        hablar a un call center. Podés hacerla desde la APP MiGrupoQ y desde la página web migrupoq.com, en ambos
        canales ingresá tu usuario y contraseña con la que se realizó tu registro.</p>

      <br>
      <p class="gq-titulos">¿Cómo puedo actualizar mis datos personales y los de mi vehículo?</p>
      <p>Lo podés hacer fácil y al instante desde la APP MiGrupoQ o desde la web migrupoq.com, una vez confirmés la
        actualización realizada a estos, quedarán registrados automáticamente en nuestro sistema.</p>

    </div>
  </ion-grid>
</ion-content>