import StorageHelper from './StorageHelper';
import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService} from '../providers/auth.service';
import {ServicesService} from '../providers/services.service';
import {UtilService} from '../providers/util.service';
import {CatalogService} from '../providers/catalog.service';
import {BenefitService} from '../services/firebase/benefit.service';
import {ISociety} from '../models/global';

@Injectable({
  providedIn: 'root'
})

export class CatalogHelper extends StorageHelper {

  constructor(
    public authService: AuthService,
    private servicesService: ServicesService,
    private catalogService: CatalogService,
    private benefitService: BenefitService,
    private utilService: UtilService,
  ) {
    super();
  }

  cargarEstadosCiviles() {
    if (!this.isValidKeyETA('estadosCiviles', 10)) {
      return this.catalogService.cargarEstadosCiviles(this.authService.getSociedadUsuario())
        .then(result => {
          localStorage.setItem('estadosCiviles', JSON.stringify(result));
        }).catch(error => {
          // console.log(error);
          this.utilService.presentToast('Problemas de conexión: CTLG');
        });
    }
  }

  getEstadosCiviles() {
    return this.getItem('estadosCiviles') && this.getItem('estadosCiviles')[this.authService.getPaisUsuario()] || [];
  }

  cargarProvincias() {
    if (!this.isValidKeyETA('provincias', 5)) {
      return this.catalogService.cargarProvincias(this.authService.getSociedadUsuario())
        .then(result => {
          localStorage.setItem('provincias', JSON.stringify(result));
        }).catch(error => {
          // console.log(error);
          this.utilService.presentToast('Problemas de conexión: CTLG');
        });
    }
  }

  getProvincias() {
    return this.getItem('provincias') && this.getItem('provincias')[this.authService.getPaisUsuario()] || [];
  }

  cargarFallas() {
    if (!this.isValidKeyETA('fallas', 5)) {
      return this.catalogService.cargarFallas(this.authService.getSociedadUsuario())
        .then(result => {
          localStorage.setItem('fallas', JSON.stringify(result));
        }).catch(error => {
          // console.log(error);
          this.utilService.presentToast('Problemas de conexión: CTLG');
        });
    }
  }

  getFallas() {
    return this.getItem('fallas') || [];
  }


  cargarJustificaciones() {
    if (!this.isValidKeyETA('justificaciones', 5)) {
      return this.catalogService.cargarJustificaciones()
        .then(result => {
          localStorage.setItem('justificaciones', JSON.stringify(result));
        }).catch(error => {
          // console.log(error);
          this.utilService.presentToast('Problemas de conexión: CTLG');
        });
    }
  }

  getJustificaciones() {
    return this.getItem('justificaciones') || [];
  }

  async procesoCargarCatalogo() {
    if (!this.isValidKeyETA('catalogo', 5)) {
      return this.catalogService.obtenerCatalogo()
        .then(result => {
          localStorage.setItem('catalogo', JSON.stringify(result));
        }).catch(error => {
          // console.log(error);
          this.utilService.presentToast('Problemas de conexió0: CTLGn');
        });
    }
  }

  getCatalogo() {
    return this.getItem('catalogo') || [];
  }

  async cargarCantonesInicial(provincia, canton) {
    if (!this.isValidKeyETA(`${this.authService.getPaisUsuario()}:cantones`, 15)) {
      if (provincia != '' && provincia != null && provincia != '0') {
        return this.catalogService.cargarCantones(this.authService.getSociedadUsuario(), provincia)
          .then(result => {
            localStorage.setItem(`${this.authService.getPaisUsuario()}:cantones`, JSON.stringify(result[this.authService.getPaisUsuario().toUpperCase()]));
            this.cargarDistritosInicial(provincia, canton);
          }).catch(error => {
            // console.log(error);
            this.utilService.presentToast('Problemas de conexión: CTLG');
          });
      }
    }
  }

  getCantonesInicial() {
    return this.getItem(`${this.authService.getPaisUsuario()}:cantones`) && this.getItem(`${this.authService.getPaisUsuario()}:cantones`)[this.authService.getPaisUsuario()] || [];
  }

  cargarDistritosInicial(provincia, canton) {

    if (!this.isValidKeyETA(`${this.authService.getPaisUsuario()}:cantones`, 15)) {
      if (canton != '' && canton != null && canton != '0') {
        return this.catalogService.cargarDistritos(this.authService.getSociedadUsuario(), provincia, canton)
          .then(result => {
            localStorage.setItem(`${this.authService.getPaisUsuario()}:distritos`, result[this.authService.getPaisUsuario().toUpperCase()]);
          }).catch(error => {
            // console.log(error);
            this.utilService.presentToast('Problemas de conexión: CTLG');
          });
      }
    }
  }

  getDistritosInicial() {
    return this.getItem(`${this.authService.getPaisUsuario()}:distritos`) && this.getItem(`${this.authService.getPaisUsuario()}:distritos`)[this.authService.getPaisUsuario()] || [];
  }

  cargarMoneda() {
    if (!this.isValidKeyETA('moneda', 30)) {
      return this.catalogService.cargarMoneda(this.authService.getSociedadUsuario())
        .then(result => {
          localStorage.setItem('moneda', JSON.stringify(result));
        }).catch(error => {
          // console.log(error);
          this.utilService.presentToast('Problemas de conexión: CTLG');
        });
    }
  }

  getMoneda() {
    return this.getItem('moneda') || [];
  }

  cargarImagenesVehiculos() {
    if (!this.isValidInitKeyETA('vehiclesImages', 2)) {
      return this.benefitService.getVehiclesImages()
        .valueChanges().subscribe(result => {
          localStorage.setItem('vehiclesImages', JSON.stringify(result));

          const d = new Date();
          const dia = d.getDate();
          localStorage.setItem('fbDate', dia.toString());
        });
    }
  }

  getVehiclesImages() {
    return this.getItem('vehiclesImages') && JSON.parse(this.getItem('vehiclesImages')) || {};
  }

  cargarAseguradora(sociedad) {
    if (!this.isValidKeyETA(`aseguradoras:${sociedad}`, 30)) {
      return this.catalogService.cargarAseguradoras(sociedad)
        .then(result => {
          localStorage.setItem(`aseguradoras:${sociedad}`, JSON.stringify(result));
        }).catch(error => {
          // console.log(error);
          this.utilService.presentToast('Problemas de conexión: CTLG');
        });
    }
  }

  getAseguradora(sociedad) {
    return this.getItem(`aseguradoras:${sociedad}`) || [];
  }

}
