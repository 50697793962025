<ion-content>
  <ion-grid fixed>
    <ion-row>
      <ion-col size="12">
        <app-header-car [sticky]="true" [backFn]="closeModal.bind(this)"></app-header-car>
      </ion-col>
    </ion-row>

    <ion-row class="mtn-35">
      <ion-col size="12" class="ion-no-padding ion-margin-top">
        <div class="driver-score-card">
          <app-circle-progress
            [title]="getCurrentScoreAmount().toString()"
            [title2]="' / 100'"
            [titleClass2]="'titleClass2'"
            [titleClass]="'titleClass'"
            [subtitle]="selectedMonthText"
            [description]="selectedYearText"
            [color]="getColorGraph()"
            size="160"
            [stroke]="8"
            [percentage]="getCurrentScoreAmount()"
          ></app-circle-progress>
        </div>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="ion-text-center ion-margin-bottom">
        <ion-text color="medium" class="fs-18">{{getCurrentScore()?.scoreMessage}}</ion-text>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="ion-text-center ion-margin-bottom">
        <ion-item class="month-selector" lines="none">
          <ion-label color="medium">Seleccione mes y año</ion-label>
          <ion-datetime
            monthShortNames="Enero, Febrero, Marzo, Abril, Mayo, Junio, Julio, Agosto, Septiembre, Octubre, Noviembre, Diciembre"
            [displayFormat]="'MMM, YYYY'"
            [(ngModel)]="selectedMonth"
            (ionChange)="fetchScoreByMonth()"
            [doneText]="'Seleccionar'"
            [cancelText]="'Cancelar'"
            pickerFormat="MMM, YYYY" min="2022"></ion-datetime>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row class="ion-padding-bottom" *ngIf="arrayResume.length > 0">
      <ion-col size="12">
        <div class="driver-resumen-cnt">
          <div class="driver-resumen header">
            <ion-label class="ion-margin-start">DESCRIPCIÓN</ion-label>
            <ion-label class="ion-margin-end">CANTIDAD</ion-label>
          </div>
          <div class="driver-resumen" *ngFor="let item of arrayResume; i as index;">
            <ion-label class="ion-margin-start">{{formatTextResume(item[0])}}</ion-label>
            <ion-label class="ion-margin-end text-gray">{{item[1]}}</ion-label>
          </div>
        </div>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <div *ngIf="arrayResume && arrayResume?.length <= 0" style="margin-bottom: 0;"
             class="alert-info alert ion-align-items-center d-flex">
          <ion-text class="ion-text-center" style="padding-left: 7px">No hay registros disponibles</ion-text>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
