import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {COUNTRIES_QUERY} from "./queries/countries.query";

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  queriCountries: any = COUNTRIES_QUERY;

  constructor(private apollo: Apollo) {
  }

  // Obtenemos los paises con la query
  getCountries() {
    return this.apollo.watchQuery({
      query: this.queriCountries,
      fetchPolicy: 'no-cache'
    }).valueChanges;
  }
}
