import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilService} from '../../../providers/util.service';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  Platform,
  ToastController
} from '@ionic/angular';
import {AyudaService} from '../../../providers/ayuda.service';
import {environment as env} from '../../../../environments/environment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Device} from '@ionic-native/device/ngx';
import {AuthService} from '../../../providers/auth.service';

@Component({
  selector: "app-bugs",
  templateUrl: "./bugs.component.html",
  styleUrls: ["./bugs.component.scss"],
})
export class BugsComponent implements OnInit, OnDestroy {
  usuario: any;
  falla: any;
  comentario: any;
  fallaLoading: any;
  backBtnSubscriber: Subscription;
  public frm: FormGroup;
  isSendingRequest = false;
  logo: string = "./assets/imgs/logoq-line.png";
  constructor(
    public utilService: UtilService,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    private formBuilder: FormBuilder,
    private ayudaService: AyudaService,
    private platform: Platform,
    private navCtrl: NavController,
    private device: Device,
    public authService: AuthService
  ) {
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.frm = this.formBuilder.group({
      falla: ['2', Validators.required],
      comentario: ['', Validators.required],
    });
  }

  ngOnDestroy() {
    if (this.backBtnSubscriber) {
      this.backBtnSubscriber.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.initPage();
  }

  initPage() {
    this.backBtnSubscriber = this.platform.backButton.subscribeWithPriority(999999, () => {
      if (!this.isSendingRequest) {
        return this.modalCtrl.dismiss();
      }
    });
  }

  reportarFalla() {
    const falla = this.frm.get('falla').value;
    const comentario = this.frm.get('comentario').value;
    if (falla == undefined || falla == '' || comentario == undefined || comentario == '') {
      this.presentToast('Falla y comentario requeridos');
    } else {
      this.procesoReportarFalla();
    }
  }

  procesoReportarFalla() {
    let descripcionAsunto = '';
    const deviceCompleto = 'Web/Pruebas';
    switch (this.falla) {
      case '1':
        descripcionAsunto = 'La aplicacion no me permite ingresar';
        break;
      case '2':
        descripcionAsunto = 'La aplicacion no actualiza correctamente';
        break;
      case '3':
        descripcionAsunto = 'La aplicacion no funciona en el teléfono';
        break;
      case '5':
        descripcionAsunto = 'La aplicacion se traba';
        break;
      default:
        descripcionAsunto = 'La aplicacion se cierra inesperadamente';
    }
    var data = {
      tipo_mensaje: 'falla',
      id_cliente: this.usuario.codigo,
      sociedad: this.usuario.sociedad,
      asunto: this.utilService.cleanSpecialChars(descripcionAsunto),
      mensaje: this.utilService.cleanSpecialChars(this.frm.get('comentario').value),
      version: env.APP_VERSION,
      version_code: env.APP_VERSION,
      device: deviceCompleto,
      pais: this.usuario.pais,
      segmento: this.usuario.segmento,
    };
    this.presentLoading();
    this.isSendingRequest = true;
    this.ayudaService.enviarFalla(data)
      .then(async (result) => {
        this.isSendingRequest = false;
        this.comentario = '';
        this.utilService.dismissLoading();
        const confirm = await this.alertCtrl.create({
          header: 'Mensaje:',
          message: result,
          cssClass: 'buttonCss',
          buttons: [
            {
              text: 'Aceptar'
            }
          ]
        });
        await confirm.present();
      }).catch(error => {
      this.isSendingRequest = false;
      this.utilService.dismissLoading();
      // console.warn(error);
      this.presentToast('Problemas de conexión: BGS');
    });
  }

  async presentToast(mensaje) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 3000,
      position: 'bottom'
    });

    await toast.present();
  }

  async presentLoading() {
    await this.utilService.presentLoading();
  }

  dismissModal() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  goBack(){
    this.modalCtrl.dismiss();
  }


}
