<ion-content>
  <!-- Inicio header vehiculo -->
  <app-header-car sticky="true" [allowCarSelection]="false"></app-header-car>

  <!-- Titulo  -->
  <div class="ion-margin-top fsz-14 ion-text-center">
    <ion-icon name="trending-up-outline" color="gptitle"></ion-icon>
    <ion-text class="ion-margin-start font-weight-bold fs-18" color="gptitle">Lista de Cotizaciones</ion-text>
  </div>

  <div *ngIf="getListadoServicontratos.length <= 0" class="ion-padding ion-text-center">
    <div style="margin-bottom: 0; padding: 10px;" class="alert-info alert ion-align-items-center d-flex">
      <ion-text style="padding-left: 7px">No hemos encontrado cotizaciones...</ion-text>
    </div>
  </div>

  <!-- Seleccion de kilometraje  -->

  <div [hidden]="false" class="ion-margin">
    <ion-card style="border-radius: 20px; padding: 10px;" class="card-appts ion-margin-top" *ngFor="let item of getListadoServicontratos">
      <ion-card-content class="ion-no-padding" style="padding: 0;">
        <ion-row>
          <ion-col size="12" class="appt-date">
            <div>
              <span class="date">Numero de oferta SQC: <strong> {{ item.codigo_serviq_contrato }} </strong></span>
            </div>
            <div>
              <span>Tipo: <strong> {{ item.nombre_serviq_tipo_contrato }} </strong> </span>
            </div>
          </ion-col>
          <ion-col size="12">
            <div>
              <ion-text class="workshop">Vehiculo: <strong> {{ item.vehiculo }} </strong></ion-text>
            </div>
            <div>
              <ion-text class="appt-number">Modelo: <strong> {{ item.modelo }} </strong></ion-text>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" class="ion-no-padding">
            <!-- <ion-button size="small" shape="round" color="gpblue">
              Contrato
            </ion-button> -->
            <ion-button (click)="PDF(item.pdf)" size="small" shape="round" color="danger">
              PDF
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>

    <!-- <div *ngIf="!isLoading && rows?.length <= 0" style="margin-bottom: 0;"
         class="alert-info alert ion-text-center">
      <ion-text class="ion-text-center" style="padding-left: 7px">No hay registros disponibles</ion-text>
    </div> -->
  </div>

  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button (click)="goToNewQuozitation()">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>


</ion-content>
