<ion-content>
  <!-- Header page -->
  <app-header-car [allowCarSelection]="false"></app-header-car>

  <div class="ion-margin-top fsz-14 ion-text-center">
    <ion-icon name="card" color="gptitle"></ion-icon>
    <ion-text class="ion-margin-start font-weight-bold fs-18" color="gptitle">Pagar Cuota</ion-text>
  </div>

  <div class="ion-padding">
    <ion-grid class="vh-aligned ion-no-padding">
      <ion-row [formGroup]="payForm" >
        <ion-col size="12" >
          <ion-label>Detalle de Pago</ion-label>
          <!-- Inicio Recuadro Detalle Pago-->
          <ion-row class="payment-detail ion-text-center">
<!--            {{ loanDetailsCar | json }}-->
            <ion-col size="6">
              <div>
                <small>Fecha vencimiento:</small>
              </div>
              <div>
                <ion-text>{{loanDetailsCar.fechaVencimiento}}</ion-text>
              </div>
            </ion-col>
            <ion-col size="6" >
              <div>
                <small>Cuota mensual:</small>
              </div>
              <div>
                <ion-text>${{loanDetailsCar.valorCuota | number}}</ion-text>
              </div>
            </ion-col>
            <ion-col size="6">
              <div>
                <small>Saldo vencido:</small>
              </div>
              <div>
                <ion-text>${{loanDetailsCar.totalVence | number}}</ion-text>
              </div>
            </ion-col>
            <ion-col size="6">
              <div>
                <small>Total a pagar:</small>
              </div>
              <div>
                <ion-text>${{loanDetailsCar.totalPagar | number}}</ion-text>
              </div>
            </ion-col>
          </ion-row>
          <!-- Fin Recuadro Detalle Pago-->
          <ion-label>Datos de Pago</ion-label>
          <div class="mt-10">
            <ion-icon [ngClass]="{'qa': getamountest() }" class="icon-check-xd" name="checkmark-circle-sharp"></ion-icon>
            <ion-row class="align-items-row" >
              <ion-col size="5">
                <ion-text>Monto sugerido:</ion-text>
              </ion-col>
              <ion-col size="7">
                <span class="dollar-sing" style="font-size: 16px">$</span>
                <small style="margin-left: 64px" class="suggested-amount">{{suggestedAmount | number }}</small>
              </ion-col>
            </ion-row>
            <ion-row class="align-items-row payment-data">
              <ion-col size="5" class="amounts">
                <ion-text>Otro monto:</ion-text>
              </ion-col>
              <ion-col size="7">
                <span class="dollar-sing">$</span>
                <ion-input #monto class="gq-input w-custom" inputmode="decimal" formControlName="amount" type="text"></ion-input>
              </ion-col>
              <ion-col size="12" class="ion-text-right">
                <small class="msg-error">
                  {{textInputError('amount', payForm)}}
                </small>
              </ion-col>
            </ion-row>
          </div>
        </ion-col>

        <ion-col size="12" class="mt-20">
          <ion-label>Tarjeta</ion-label>
          <div class="card-container" (click)="showBillingDetail()" >
            <img [src]="getBrandCard(selectedCard?.marca) || getBrandCard('DEFAULT') "  alt="">
            <div class="content" *ngIf="selectedCard">
              <div class="card-number">
                **** {{selectedCard?.ultimos_digitos }}
              </div>
              <div>
                <span class="action-edit">Cambiar</span>
              </div>
              <div>
                <span class="card-alias">{{selectedCard?.alias}}</span>
              </div>
            </div>
            <div *ngIf="!selectedCard" class="placeholder">
              <small>Seleccionar...</small>
            </div>
          </div>
        </ion-col>
        <ion-col size="12" class="mt-20" *ngIf="requiereCodigo">
          <ion-label class="ion-margin-start">CVV</ion-label>
          <ion-input class="gq-input" pattern="[0-9]+" formControlName="cvv" #cvv inputmode="numeric" type="text" maxlength="4"
                     minlength="3" placeholder="123"  (keypress)="isNumber($event)"></ion-input>
          <small class="msg-error">
            {{textInputError('cvv', payForm)}}
          </small>
        </ion-col>

        <ion-col size="12">
          <div class="footer">
            <ion-button class="gq-button-actualizar btn-rounded ion-margin-horizontal" expand="block" [disabled]="!selectedCard || payForm.invalid || requiereCodigo && !payForm.value.cvv " (click)="submitPay()">
              Pagar
            </ion-button>
          </div>
        </ion-col>

      </ion-row>
    </ion-grid>
  </div>

</ion-content>
