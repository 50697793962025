import {gql} from "apollo-angular";

export const HIGHLIGHTS_QUERY = gql`
  query getHighlights($country: String!){
    higlightedBenefitsByCountry(country: $country) {
      id
      benefit
      header
      description
      benefitArtUrl
      bannerImage
      canShare
      isExclusive
      business {
        id,
        logoUrl,
        facebookUrl,
        twitterUrl,
        whatsappUrl,
        instagramUrl,
        business,
        geolocation,
        phoneNumber
      }
      tags {
        name
      }
    }
  }`;

export const EventsQueryBenefits = gql`
query getEventsByCountry($country: String!) {
    eventosByCountry(country: $country) {
    id,
    event,
    restrictions,
    activationDate,
    deactivationDate,
    usePrintorder,
    benefitArtUrl,
    bannerImageUrl,
    header,
    description,
    benefit {
      id,
      isExclusive,
      business {
      id,
      logoUrl,
      business,
      whatsappUrl,
      facebookUrl,
      twitterUrl,
      instagramUrl,
      geolocation,
      phoneNumber,
      country {
        id,
        name
      },
      officeSet {
        id,
        geolocation,
        latitude,
        longitude,
        phoneNumber,
        office,
        logo,
        charAddress,
        address,
        __typename
      }
    },
    }
    __typename
  }
}
`;

export const PromoQueryBenefits = gql`
query getPromoByCountry($country: String!) {
    promocionesByCountry(country: $country) {
    id,
    promo,
    restrictions,
    activationDate,
    deactivationDate,
    benefitArtUrl,
    bannerImageUrl,
    header,
    description
    __typename
  }
}
`;

export const BusinessByCategory = gql `
query getBusinessByCategory($categoryId: Int!) {
  businessByCategoryId(categoryId: $categoryId) {
    id, business, logoUrl, category{ id }, latitude, longitude, geolocation, officeSet {  office, latitude, longitude }
      benefits {
        benefit, id, header, description,
        benefitArtUrl, canShare, isExclusive, restrictions,
        business  {
          id, logoUrl, business, officeSet { id, office, geolocation, phoneNumber, latitude, longitude, __typename}, geolocation, phoneNumber, facebookUrl, whatsappUrl, instagramUrl, twitterUrl
        }
      }
    }
  }
`;

export const BusinessWithCategoriesByCountry = gql `
query getBusinessWithCategoriesByCountry($country: String!) {
  categoriesByCountry(country: $country) {
    id
    name
    description
    backgroundColor
    webIcon
    business {
      id
      business
      logoUrl
      longitude
      latitude
      geolocation
      benefits {
        id
        benefitArtUrl
        benefit
        header
        description
        isExclusive
        business {
          business
          geolocation
          phoneNumber
          logoUrl
          facebookUrl
          instagramUrl
          twitterUrl
          whatsappUrl
        }
        offices {
          id,
          office
          latitude
          longitude
          geolocation
        }
      }
      officeSet {
        id
        office
        latitude
        longitude
        geolocation
        office
        address
        phoneNumber
      }
    }
  }
}
`;
