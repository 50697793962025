import StorageHelper from './StorageHelper';
import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService} from '../providers/auth.service';
import {ServicesService} from '../providers/services.service';
import {UtilService} from '../providers/util.service';

@Injectable({
  providedIn: 'root'
})

export class ApptHelper extends StorageHelper {
  private loadingCarsAppt = false;
  appointments: any;
  hayCitas: any;
  sinCitas: any;
  mensaje: any;
  $userAppts = new Subject<any>();
  fetchLimitTries = 3;
  fetchLimitCount = 0;

  constructor(
    public authService: AuthService,
    private servicesService: ServicesService,
    private utilService: UtilService,
  ) {
    super();
  }

  setIsLoadingCarsAppt(status: boolean) {
    this.loadingCarsAppt = status;
  }

  setUserAppts(userAppts) {
    this.appointments = userAppts;
    window.localStorage.setItem('userAppts', JSON.stringify(userAppts));
  }

  getUserAppts(): any {
    return this.appointments && this.appointments || JSON.parse(window.localStorage.getItem('userAppts')) || [];
  }

  pokeUserAppts(data) {
    this.$userAppts.next(data && data || this.getUserAppts());
  }

  getUserApptsObservable() {
    return this.$userAppts.asObservable();
  }

  isLoadingCarsAppt() {
    return this.loadingCarsAppt;
  }

  getCitas() {
    this.hayCitas = false;
    this.sinCitas = false;

    this.setIsLoadingCarsAppt(true);

    this.servicesService.obtenerCitas(this.authService.getCodigoUsuario())
      .then(result => {
        this.setIsLoadingCarsAppt(false);
        if (Array.isArray(result)) {
          this.setUserAppts(result);
          this.hayCitas = true;
          this.pokeUserAppts(result);
        } else {
          if (result == 'No posees citas de taller para reprogramar') {
            this.setUserAppts([]);
            this.sinCitas = true;
            this.servicesService.mensaje = result;
            this.hayCitas = false;
            this.pokeUserAppts([]);
          } else {
            this.sinCitas = true;
            this.servicesService.mensaje = result;
            this.setUserAppts(this.getUserAppts());
            this.pokeUserAppts(this.getUserAppts());
            if (this.fetchLimitCount < this.fetchLimitTries) {
              this.fetchLimitCount++;
              this.getCitas();
            }
          }
        }
      }).catch(error => {

      this.setIsLoadingCarsAppt(false);
      // console.warn(error);
      this.utilService.presentToast('Problemas de conexión: APPTH');
    });
  }
}
