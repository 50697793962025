import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AlertController, IonInput, IonSlides, ModalController, NavController, Platform} from '@ionic/angular';
import {LoanHelper} from '../../../../helpers/crediq/LoanHelper';
import {UtilService} from '../../../../providers/util.service';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {guardarTarjeta} from './models';
import {Router} from "@angular/router";
import {BASE_CREDIQ_URL, environment} from "../../../../../environments/environment";
import {InAppBrowser} from "@ionic-native/in-app-browser/ngx";
import {DomSanitizer} from "@angular/platform-browser";
import {utils} from "protractor";
import * as util from "util";
import {PaymentWebComponent} from "../../../../components/payment-web/payment-web.component";
import {
  PaymentWebAddCardFrameComponent
} from "../../../../components/payment-web-add-card-frame/payment-web-add-card-frame.component";

@Component({
  selector: 'app-add-new-card',
  templateUrl: './add-new-card.component.html',
  styleUrls: ['./add-new-card.component.scss'],
})
export class AddNewCardComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("slider", {static: true}) public slideWithNav: IonSlides;
  @ViewChild('phone', {static: true}) public numberInput: IonInput;
  guardarTarjeta: guardarTarjeta;
  textBtnCancel: string = "Cancelar";
  textBtnConfirm: string = "Siguiente";
  closeScreen: boolean = true;

  urlIFrame: any;

  cardFlipped = false;
  // Card Form data
  cardNumber = '';
  cardName;
  cardExpDate;
  cardCvv;
  cardClass = 'cc_type_unknown';
  cardLength = 16;
  cardType;

  backBtnSubscriber: Subscription;

  cardTypesImg: any = {
    VISA: {
      img: '/assets/icon/cards/visa.svg'
    },
    MASTERCARD: {
      img: '/assets/icon/cards/mastercard.svg'
    },
  };

  transaction3dsResponse;

  // mask
  monthAndSlashRegex = /^\d\d\/$/; // regex to match "MM / "
  monthRegex = /^\d\d$/; // regex to match "MM"

  // Card Types
  cardTypes = {
    'American Express': {
      name: 'American Express',
      code: 'ax',
      security: 4,
      pattern: /^3[47]/,
      valid_length: [15],
      formats: {
        length: 15,
        format: 'xxxx xxxxxxx xxxx'
      }
    },
    'Visa': {
      name: 'Visa',
      code: 'vs',
      security: 3,
      pattern: /^4/,
      valid_length: [16],
      formats: {
        length: 16,
        format: 'xxxx xxxx xxxx xxxx'
      }
    },
    'Default': {
      name: 'Visa',
      code: 'vs',
      security: 3,
      pattern: /^4/,
      valid_length: [16],
      formats: {
        length: 16,
        format: 'xxxx xxxx xxxx xxxx'
      }
    },
    'Maestro': {
      name: 'Maestro',
      code: 'ma',
      security: 3,
      pattern: /^(50(18|20|38)|5612|5893|63(04|90)|67(59|6[1-3])|0604)/,
      valid_length: [16],
      formats: {
        length: 16,
        format: 'xxxx xxxx xxxx xxxx'
      }
    },
    'Mastercard': {
      name: 'Mastercard',
      code: 'mc',
      security: 3,
      pattern: /^5[1-5]/,
      valid_length: [16],
      formats: {
        length: 16,
        format: 'xxxx xxxx xxxx xxxx'
      }
    }
  };
  reactiveForm: FormGroup;
  dataEbill0: FormGroup;
  dataEbill1: FormGroup;

  slidesOptions: {
    initialSlide: 0,
    preloadImages: true,
    updateOnImagesReady: true,
    grabCursor: false,
    pager: false,
    draggable: false,
    allowTouchMove: false,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    on: {},
  };

  intervalTest: any;

  sideFormFront = {
    form0: true,
    form1: false,
    form2: false
  };

  crediqUrl = '';

  constructor(
    public navCtrl: NavController,
    public utilService: UtilService,
    public platform: Platform,
    public loanHelper: LoanHelper,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public router: Router, private inApp: InAppBrowser, private sanitizer: DomSanitizer, private el: ElementRef, private renderer: Renderer2) {

    this.reactiveForm = new FormGroup({
      cardNumber: new FormControl('', [Validators.maxLength(19), Validators.required, Validators.minLength(18)]),
      cardOwner: new FormControl('', [Validators.maxLength(22), Validators.required]),
      cardExp: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]),
      cardCvv: new FormControl('', [Validators.required, Validators.maxLength(4), Validators.minLength(3)]),
    });

    this.dataEbill0 = new FormGroup({
      nombre_titular: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      apellido_titular: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      direccion1: new FormControl('', Validators.maxLength(50)),
      direccion2: new FormControl('', Validators.maxLength(50)),
    });

    this.dataEbill1 = new FormGroup({
      ciudad: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      codigo_zip: new FormControl('', Validators.maxLength(10)),
      estado_facturacion: new FormControl('', [Validators.maxLength(5)]),
      pais: new FormControl({
        value: this.loanHelper.authService.getPaisCodeUsuario(),
        disabled: true
      }, [Validators.required, Validators.maxLength(3)]),
      telefono: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      correo: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    });

    this.crediqUrl = BASE_CREDIQ_URL;
  }

  ngOnInit() {
    this.backBtnSubscriber = this.platform.backButton.subscribeWithPriority(999999, () => {
      return this.navCtrl.back({animated: true});
    });
  }

  ngOnDestroy() {
    if (this.backBtnSubscriber) {
      this.backBtnSubscriber.unsubscribe();
    }
  }

  formatCard() {
    if (this.cardNumber.length > 16) {
      this.cardNumber.slice(0, this.cardNumber.length - 1);
    }
    this.cardNumber = this.cardNumber.replace(/\s+/g, '').replace(/(\d{4})/g, '$1 ').trim();
  }

  formatExp() {
    if (this.cardExpDate.length === 2) {
      this.cardExpDate = this.cardExpDate.splice(2, 0, '/');
    }
  }

  changeCardholder() {
    this.cardName = this.reactiveForm.get('cardOwner').value.slice(0, 30);
  }

  changeCvv() {
    this.cardCvv = this.reactiveForm.get('cardCvv').value;
  }

  addSlash(e: any) {
    const hasSlash = (this.cardExpDate || '').includes('/');
    this.cardExpDate = e.target.value;

    this.cardExpDate = this.cardExpDate.replace(/\./g, '').replace(/,/g, '');

    if (this.cardExpDate.length === 3 && hasSlash) {
      this.cardExpDate = this.cardExpDate.replace('/', '');
    }

    if (e.target.value.length > 1 && !hasSlash) {
      if (e.target.value.length > 2) {
        this.cardExpDate = this.cardExpDate[0] + this.cardExpDate[1] + '/' + this.cardExpDate[2];
      } else {
        this.cardExpDate = this.cardExpDate + '/';
      }
    }

    const month = this.cardExpDate.split("/")[0];
    const year = this.cardExpDate.split("/")[1];

    // Get last 2 digits of current year
    const last2 = new Date().getFullYear().toString().slice(-2);
    const last2Num = Number(last2);

    if (parseInt(month) > 12 || month.toString() === '00') {
      this.reactiveForm.get('cardExp').setValue('');
    } else {
      this.reactiveForm.get('cardExp').setValue(this.cardExpDate.replace('undefined', ''));
    }

    if (e.target.value.length > 4) {
      if (parseInt(year) >= last2Num) {
        this.reactiveForm.get('cardExp').setValue(this.cardExpDate.replace('undefined', ''));
      } else {
        this.reactiveForm.get('cardExp').setValue(`${month}/`.replace('undefined', ''));
      }
    }

  }

  removeSlash(e) {
    this.cardExpDate = this.reactiveForm.get('cardExp').value;
    const isMonthAndSlashEntered = this.monthAndSlashRegex.exec(this.cardExpDate);
    if (isMonthAndSlashEntered && e.key === 'Backspace') {
      this.cardExpDate = this.cardExpDate.slice(0, -3);
      this.reactiveForm.get('cardExp').setValue(this.cardExpDate);
    }
  }

  isValidLength(cc_num, card_type) {
    for (var i in card_type.valid_length) {
      if (cc_num.length <= card_type.valid_length[i]) {
        return true;
      }
    }
    return false;
  }

  getCardType(cc_num) {
    for (var i in this.cardTypes) {
      let card_type = this.cardTypes[i];
      if (cc_num.match(card_type.pattern) && this.isValidLength(cc_num, card_type)) {
        return card_type;
      }
    }
    return this.cardTypes['Default'];
  }

  getCardFormatString(cc_num, card_type) {
    for (var i in card_type.formats) {
      var format = card_type.formats[i];
      if (cc_num.length <= format.length) {
        return format;
      }
    }
  }

  monitorCcFormat() {
    var cc_num = this.reactiveForm.get('cardNumber').value.replace(/\D/g, '');
    var card_type = this.getCardType(cc_num);
    this.cardType = card_type;
    this.cardNumber = this.formatCardNumber(cc_num, card_type);
    this.reactiveForm.get('cardNumber').setValue(this.cardNumber);
    this.addCardClassIdentifier(card_type);
  }

  isInteger(x) {
    return (typeof x === 'number') && (x % 1 === 0);
  }

  setMaxLength($elem, length = null) {
    if ($elem.length && this.isInteger(length)) {
      $elem.attr('maxlength', length);
    } else if ($elem.length) {
      $elem.attr('maxlength', '');
    }
  }

  addCardClassIdentifier(card_type) {
    var classIdentifier = 'cc_type_unknown';
    if (card_type) {
      classIdentifier = 'cc_type_' + card_type.code;
      this.cardLength = card_type.formats.lengh;
    } else {
      this.cardLength = 16;
    }

    if (this.cardClass != classIdentifier) {
      var classes = '';
      for (var i in this.cardTypes) {
        classes += 'cc_type_' + this.cardTypes[i].code + ' ';
      }
      this.cardClass = classIdentifier;
    }
  }

  formatCardNumber(cc_num, card_type) {
    var numAppendedChars = 0;
    var formattedNumber = '';
    var cardFormatIndex;

    if (!card_type) {
      return cc_num;
    }

    var cardFormatString = this.getCardFormatString(cc_num, card_type);
    for (var i = 0; i < cc_num.length; i++) {
      cardFormatIndex = i + numAppendedChars;
      if (!cardFormatString || cardFormatIndex >= cardFormatString.length) {
        return cc_num;
      }

      if (cardFormatString.charAt(cardFormatIndex) !== 'x') {
        numAppendedChars++;
        formattedNumber += cardFormatString.charAt(cardFormatIndex) + cc_num.charAt(i);
      } else {
        formattedNumber += cc_num.charAt(i);
      }
    }

    return formattedNumber;
  }

  public getCardImage() {
    if (this.cardType && this.cardType.name.toUpperCase() in this.cardTypesImg) {
      return this.cardTypesImg[this.cardType.name.toUpperCase()].img;
    }
    return 'BestBank';
  }

  goOut() {
    this.modalCtrl.dismiss();
  }

  goBack(sideFormCurrent: number) {
    if (this.closeScreen) {
      this.goOut();
    } else {
      let formSelect = sideFormCurrent;
      if (formSelect === 0) {
        this.goOut();
      } else {
        formSelect = (sideFormCurrent - 1);
        this.changeStatusSideForm(formSelect);
      }
    }
  }

  changeStatusSideForm(sideFormActive: number = 0) {
    if (sideFormActive === 0) {
      this.sideFormFront.form0 = true;
      this.sideFormFront.form1 = false;
      this.sideFormFront.form2 = false;
    } else if (sideFormActive === 1) {
      this.sideFormFront.form0 = false;
      this.sideFormFront.form1 = true;
      this.sideFormFront.form2 = false;
    } else {
      this.sideFormFront.form0 = false;
      this.sideFormFront.form1 = false;
      this.sideFormFront.form2 = true;
    }
  }

  selectSideForm(sideForm: number = 0) {
    if (sideForm > 0) {
      this.closeScreen = false;
      if (sideForm === 1) {
        if (!this.validateForm0()) {
          this.presentMsgErrorForm();
        } else {
          this.changeStatusSideForm(sideForm);
        }
      } else if (sideForm === 2) {
        if (!this.validateForm1()) {
          this.presentMsgErrorForm();
        } else {
          this.changeStatusSideForm(sideForm);
        }
      }
    } else {
      this.closeScreen = true;
      if (!this.validateForm0()) {
        this.presentMsgErrorForm();
      } else {
        this.changeStatusSideForm(sideForm);
      }
    }
  }

  ngAfterViewInit(): void {
    this.numberInput.getInputElement().then(input => {
      input.onkeyup = ((val: any) => {
        this.validatePhoneFormat(val.key);
        val.preventDefault();
        val.stopImmediatePropagation();
        val.cancelBubble = true;
      });
    });
  };

  validatePhoneFormat(_char) {
    const val = this.numberInput.value.toString();
    const validNumber = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(_char);

    if (!validNumber) {
      this.numberInput.value = val.replace(_char, '').replace(',', '').replace('.', '').replace(/[^\d.]/g, '');
    }
  }

  presentMsgErrorForm() {
    return this.utilService.presentToast("Complete el formulario con los campos requeridos", 'bottom');
  }

  textInputError(nameControl: string = "NULL", form: any = "NULL") {
    const invalid = form.get(nameControl)?.invalid;
    if (invalid || form?.errors) {

      const errors = form.get(nameControl).errors;
      const touched = form.get(nameControl).touched;

      if (errors || form?.errors) {
        if (errors?.required && touched) {
          return "Este campo es obligatorio";
        }

        if (errors?.maxlength && nameControl) {
          return "El máximo de carácteres de este campo es de " + errors?.maxlength?.requiredLength;
        }

        if (errors?.minlength && nameControl) {
          if (nameControl === 'cardNumber') {
            return "El mínimo de carácteres de este campo es de " + (errors?.minlength?.requiredLength - 3);
          }

          if (nameControl === 'cardExp') {
            return "El valor que ha ingresado no es válido.";
          }

          return "El mínimo de carácteres de este campo es de " + (errors?.minlength?.requiredLength);
        }
        if (errors?.pattern && nameControl) {
          return 'Ingrese un correo válido';
        }
      } else {
        return "El campo no es válido";
      }
    }
  }

  validateForm0() {
    if (this.reactiveForm.valid) {
      return true;
    } else {
      return false;
    }
  }

  validateForm1() {
    if (this.dataEbill0.valid) {
      return true;
    } else {
      return false;
    }
  }

  validateForm2() {
    if (this.dataEbill1.valid) {
      return true;
    } else {
      return false;
    }
  }

  async openIframeModalAddNewCard(url: string) {
    const trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    const modal = await this.modalCtrl.create({
      component: PaymentWebAddCardFrameComponent,
      componentProps: {url: trustedUrl},
      id: 'frame-payment-add-card-modal',
      backdropDismiss: false,
    });
    return await modal.present();
  }

  saveCreditCard() {

    if (!this.validateForm0()) {
      this.goBack(0);
      return;
    }

    if (!this.validateForm1()) {
      this.goBack(0);
      return;
    }

    if (!this.validateForm2()) {
      this.goBack(1);
      return;
    }

    const datos_tarjeta = {
      alias: this.reactiveForm.get('cardOwner').value,
      codigo_seguridad: this.reactiveForm.get('cardCvv').value,
      fecha_expiracion: this.reactiveForm.get('cardExp').value,
      num_tarjeta: this.reactiveForm.get('cardNumber').value
    }
    const datos_factura0 = {
      nombre_titular: this.dataEbill0.get('nombre_titular').value,
      apellido_titular: this.dataEbill0.get('apellido_titular').value,
      direccion1: this.dataEbill0.get('direccion1').value,
      direccion2: this.dataEbill0.get('direccion2').value
    }
    const datos_factura1 = {
      ciudad: this.dataEbill1.get('ciudad').value,
      codigo_zip: this.dataEbill1.get('codigo_zip').value,
      estado_facturacion: this.dataEbill1.get('estado_facturacion').value,
      pais: this.dataEbill1.get('pais').value,
      telefono: this.dataEbill1.get('telefono').value,
      correo: this.dataEbill1.get('correo').value
    };

    const formData = {
      alias: datos_tarjeta.alias,
      apellidoTitular: datos_factura0.apellido_titular,
      ciudad: datos_factura1.ciudad,
      codigoSeguridad: datos_tarjeta.codigo_seguridad,
      codigoZip: datos_factura1.codigo_zip,
      correo: datos_factura1.correo,
      direccion1: datos_factura0.direccion1,
      direccion2: datos_factura0.direccion2,
      estadoFacturacion: datos_factura1.estado_facturacion,
      fechaExpiracion: datos_tarjeta.fecha_expiracion.replace('/', ''),
      marca: this.utilService.getCardType(datos_tarjeta.num_tarjeta).toUpperCase(),
      nombreTitular: datos_factura0.nombre_titular,
      numeroTarjeta: datos_tarjeta.num_tarjeta,
      pais: datos_factura1.pais,
      telefono: datos_factura1.telefono,
    };

    void this.utilService.presentLoading();

    this.loanHelper.generarTokenTarjetaCronosLH(formData).then((response: any) => {
      if (!response.status) {
        void this.utilService.presentToast("Ocurrió un error el momento de procesar su tarjeta.");
        return;
      }

      if (!response.data) {
        void this.utilService.presentToast("No se han encontrado datos válidos.");
        return;
      }

      const args = {
        pais: datos_factura1.pais,
        tokenTarjeta: response.data,
      };

      this.loanHelper.addCreditCardWS(args).then((responseCard: any) => {
        if (responseCard['process-status'] === 'error') {
          void this.utilService.dismissLoading();
          if (responseCard.message) {
            return this.utilService.presentToast(responseCard.message);
          }
          return this.utilService.presentToast('Algo salió mal.');
        }

        if (responseCard['process-status'] === 'pendiente' && responseCard.status === 'pendiente') {
          this.loanHelper.createTxCardValidator3DS({
            user_id: this.loanHelper.authService.getCodigoUsuario(),
            body: responseCard?.htmlForm
          }).then((response: any) => {
            const data = JSON.parse(response.data);
            const url = `${environment.endpoints.card_validator}${data.uid}`;
            // this.urlIFrame = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            this.modalCtrl.dismiss().then(() => {
              void this.utilService.dismissAllLoadingControllers();
              void this.openIframeModalAddNewCard(url);
              // console.log('URL:', url)
            });
          });
        }

        if (responseCard['process-status'] === 'ok' && responseCard.status === 'ok') {
          let currentCards = this.loanHelper.getUserCards();
          currentCards.push({
            alias: formData.alias,
            ultimos_digitos: datos_tarjeta.num_tarjeta.substring(datos_tarjeta.num_tarjeta.length - 4),
            marca: this.utilService.getCardType(datos_tarjeta.num_tarjeta).toUpperCase(),
            token: responseCard.token ? responseCard.token : responseCard['token-tarjeta'],
            fecha_expiracion: datos_tarjeta.fecha_expiracion,
            valida: responseCard['requiere-validacion'] === 'true' ? 'false' : 'true'
          });
          this.loanHelper.setUserCards(currentCards);
          this.loanHelper.pokeUserCards(currentCards);

          // Refetch cards for loan payments
          this.loanHelper.getUserLoanCardsWS(true);

          void this.utilService.presentToast('Tarjeta agregada con éxito.');

          if (responseCard['requiere-validacion'] === 'true') {
            // this.utilService.presentToast('Es necesario validar la tarjeta');
          }

          setTimeout(() => {
            void this.utilService.dismissLoading();
            void this.router.navigate(['/crediq/credit-cards']);
          }, 700);
        }
      }).catch(errorCard => {
        void this.utilService.dismissLoading();
        void this.utilService.presentToast('Algo salió mal.');
      });

    }).catch(error => {
      void this.utilService.dismissLoading();
      void this.utilService.presentToast('Algo salió mal.');
    });
  }

}
