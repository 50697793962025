import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Storage} from '@ionic/storage';
import {Platform} from '@ionic/angular';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {


  constructor(
    private http: HttpClient,
    private platform: Platform
    // private storage: Storage
  ) {

  }


  async getHeaders(nToken, multipart = false, app: 'smartq' | 'crediq' | 'cronos' = 'cronos') {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers
      .append('Cache-control', 'no-cache')
      .append('Expires', '0')
      .append('Pragma', 'no-cache');

    if (multipart) {
      headers = headers.append('enctype', 'multipart/form-data; boundary=WebAppBoundary');
      // headers = headers.append('Content-Type', 'multipart/form-data; boundary=WebAppBoundary');
    } else {
      headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    }

    if (nToken) {
      switch (app) {
        case 'smartq':
          const tokenSQ = window.localStorage.getItem(environment.settings.token_smart);
          headers = headers
            .append('access-token', tokenSQ);
          break;
        case 'crediq':
          const tokenCQ = window.localStorage.getItem('tokenDriverSQ');
          headers = headers
            .append('access-token', tokenCQ);
          break;
        default:
          const token = window.localStorage.getItem('token');
          const username = JSON.parse(window.localStorage.getItem('userProfile')).username;
          headers = headers
            .append('Authorization', `ApiKey ${username}:${token}`);
          break;
      }

    }
    return headers;
  }


  // POST
  async post<T>(url: string[], body, nToken = false, multipart = false, app: 'smartq' | 'crediq' | 'cronos' = 'cronos'): Promise<T> {
    // let final ?
    // console.log(body);
    const uri = url.join('/');
    const headers = await this.getHeaders(nToken, multipart, app);

    if (!multipart) {
      body = this.formData(body);
    }

    // console.log(body);
    // console.log(headers);

    return new Promise((resolve, reject) => {
      this.http.post<T>(uri, body, {headers}).subscribe(d => resolve(d), err => reject({
        status: err.status,
        message: err.error.message
      }));
    });
  }


  // GET
  async get<T>(url: string[], nToken = false, resType = false): Promise<T> {
    const uri = url.join('/');
    const headers = await this.getHeaders(nToken);
    const responseType = resType ? 'text' as 'json' : 'json';

    return new Promise((resolve, reject) => {
      this.http.get<T>(uri, {headers}).subscribe(d => resolve(d), err => reject({
        status: err.status,
        message: err.error.message
      }));
    });
  }


  formData(body) {
    return Object.keys(body).map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(body[key])).join('&');
  }


  public isDesktop() {
    return this.platform.is('desktop') || !this.platform.is('mobileweb');
  }

}
