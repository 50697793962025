import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ServicontratosHelper } from '../../../../helpers/migrupoq/ServicontratosHelper';
import { VehicleHelper } from '../../../../helpers/VehicleHelper';
import { AuthService } from '../../../../providers/auth.service';
import { BaseService } from '../../../../providers/base.service';
import { ServicesService } from '../../../../providers/services.service';
import { UtilService } from '../../../../providers/util.service';
import {AnalyticsService} from "../../../../providers/analytics.service";
@Component({
  selector: 'app-confirm-quotation',
  templateUrl: './confirm-quotation.page.html',
  styleUrls: ['./confirm-quotation.page.scss'],
})
export class ConfirmQuotationPage implements OnInit {

  sociedadVehiculo: string;
  codigoUsuario: string;
  moneda: string;
  vehiculo: string;
  rango_km: string;
  marca: string;
  prima: string;
  tipo_paquete: string;

  @Input() paquetes: any;
  @Input() sucursal: any;
  @Input() sucursal_id: any;
  @Input() currency: any;

  constructor(private modalController: ModalController,
    private serviceService: ServicesService,
    private auth: AuthService,
    private vehicle: VehicleHelper,
    private util: UtilService,
    private router: Router,
    private bs: BaseService,
    private servicontratoHelper: ServicontratosHelper, private analyticsService: AnalyticsService) { }

  ngOnInit() {
    this.getPaquetes();
  }

  getPaquetes() {
    return this.paquetes.PAQUETES;
  }

  cancel() {
    this.modalController.dismiss({
      dismissSuccess: false,
    });
  }

  saveAndConfirm() {
    this.modalController.dismiss();
    this.getDataUSer();
    this.util.presentLoading();
    this.serviceService.generarCotizacionServicontrato(
      this.sociedadVehiculo,
      this.codigoUsuario,
      this.moneda,
      this.vehiculo,
      this.rango_km,
      this.marca,
      this.prima,
      this.tipo_paquete,
      this.sucursal_id).then(response => {
        this.util.dismissLoading();

        this.servicontratoHelper.getListadoServicontratos();

        if (Array.isArray(response)) {
          const Error = response[0].Error;
          if (Error === "si") {
            this.util.presentToast(response[0].descripcion || 'Error al generar cotización ServiContrato.');
            return;
          }
          return;
        }
        this.analyticsService.logEvent(AnalyticsService.CQ_QUOTE_CONFIRMAR_GENERAR_SERVICONTRATO, 'confirmar_generar_cotizacion_servicontrato');
        if (this.bs.isDesktop()) {
          const width = window.screen;
          if (width.availWidth < 768) {
            this.isBackMovil(response.mensaje);
            return;
          } else {
            this.modalController.dismiss();
            const alert = {
              header: "Cotización generada",
              message: response.mensaje,
            };
            this.util.presentAlert(alert);
          }
        } else {
          this.isBackMovil(response.mensaje);
          return;
        }

      }).catch(error => {
        this.util.dismissLoading();
        // console.log("err: generar_cotizacion_servicontrato ", error);
      });
  }

  isBackMovil(msg: string) {
    this.util.presentToast(msg);
    this.router.navigate(["/grupoq/servicontratos"]);
    this.modalController.dismiss();
  }

  getDataUSer() {
    this.sociedadVehiculo = this.vehicle.getCurrentVehicle().SOCIEDAD;
    this.codigoUsuario = this.auth.getCodigoUsuario();
    this.moneda = this.currency;
    this.vehiculo = this.vehicle.getCurrentVehicle().MATNR + "/" + this.vehicle.getCurrentVehicle().NUM_INVENTARIO;
    this.rango_km = this.paquetes.RANGOKM;
    this.marca = this.vehicle.getCurrentVehicle().COD_MARCA;
    this.prima = "";
    this.tipo_paquete = "A";
  }

}
