import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../providers/auth.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit, OnDestroy {

  backBtnSubscriber: Subscription;
  logo: string = "./assets/imgs/logoq-line.png";
  constructor(
    private modalCtrl: ModalController,
    private platform: Platform,
    private navCtrl: NavController,
    public authService: AuthService
  ) {
  }

  ngOnDestroy() {
    if (this.backBtnSubscriber) {
      this.backBtnSubscriber.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.initPage();
  }

  initPage() {
    this.backBtnSubscriber = this.platform.backButton.subscribeWithPriority(999999, () => {
      this.modalCtrl.dismiss();
    });
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  goBack() {
    this.modalCtrl.dismiss();
  }

}
