import {Component, OnInit, ViewChild} from '@angular/core';
import {AlertController, LoadingController, PopoverController, ToastController} from '@ionic/angular';
import {NavigationExtras, Router} from '@angular/router';
import {AuthService} from '../../providers/auth.service';
import {PromisesService} from '../../providers/promises.service';
import {HomeService} from '../../providers/home.service';
import {VehiculosService} from '../../providers/vehiculos.service';
import {UtilService} from '../../providers/util.service';
import {MainCarSelectorComponent} from '../main-car-selector/main-car-selector.component';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-account-report-popover',
  templateUrl: './account-report-popover.component.html',
  styleUrls: ['./account-report-popover.component.scss'],
})
export class AccountReportPopoverComponent implements OnInit {
  usuario: any;
  vehiculos = [];
  vehicleInsurance: any;
  aseguradoras: any = [];
  selectedVehiculos: any;
  tipo: any;

  insurancesCompaniesLoaded: Subscription;

  @ViewChild(MainCarSelectorComponent, {static: true}) CarSelector: MainCarSelectorComponent;

  constructor(
    private toastCtrl: ToastController,
    public loadingCtrl: LoadingController,
    private router: Router,
    private servicioAuth: AuthService,
    private promiseService: PromisesService,
    private utilService: UtilService,
    private homeService: HomeService,
    private vehiculosService: VehiculosService,
    public alertCtrl: AlertController,
    private popoverCtrl: PopoverController
  ) {
  }

  ngOnInit() {
  }

  async dismissModal() {
    await this.popoverCtrl.dismiss();
  }


}
