import StorageHelper from './StorageHelper';
import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {QCar} from '../models/global';
import {environment} from '../../environments/environment';
import {VehiculosService} from '../providers/vehiculos.service';
import {CatalogService} from '../providers/catalog.service';
import {PromisesService} from '../providers/promises.service';
import {ApptHistoryHelper} from './ApptHistoryHelper';


@Injectable({
  providedIn: 'root'
})


export class VehicleHelper extends StorageHelper {

  $sliderVehicles = new Subject<any>();
  $deleteVehicle = new Subject<any>();
  $currentVehicle = new Subject<any>();
  currentVehicle: any;

  vehicleFake: QCar = {
    TIPO: "cronos",
    BAPI: "cronos",
    NUM_INVENTARIO: "cronos",
    NUM_PLACA: "cronos",
    ANIO_PLACA: "cronos",
    TIPO_PLACA: "cronos",
    CILINDRADA: "cronos",
    KM_UM: "cronos",
    MODELO: "cronos",
    MARCA: "cronos",
    COMBUSTIBLE: "cronos",
    ESTILO: "cronos",
    CODIGO_SAP_CLIENTE: "cronos",
    TIPO_VEH: "cronos",
    COD_TIPO_VEH: "cronos",
    VIN: "cronos",
    LABVAL_TYPE: "cronos",
    DBM_LICEXT: "cronos",
    ANO_VEH: 0,
    LBRCAT: "cronos",
    MFRNR: "cronos",
    DESC_MODELO: "cronos",
    VHCLE: "cronos",
    SOCIEDAD: "cronos",
    BD_QRM: "cronos",
    NOM_CLIENTE: "cronos",
    MATNR: "cronos",
    MARK: "cronos",
    LABVAL: "cronos",
    CLASE_PED: "cronos",
    KMS: 0,
    ITOBJID: "cronos",
    SPART: "cronos",
    MAN: "cronos",
    REP: "cronos",
    WERKS: "cronos",
    DBM_BUSTYPE: "cronos",
    MATKL: "cronos",
    COD_MARCA: "cronos",
    PAQUETE: "cronos",
    TIENE_SQC: "cronos",
    VARIANTE: "cronos",
    HEADER_GUID: "cronos",
    VARIANT_GUID: "cronos",
    VARIANTH_GUID: "cronos",
    OFERTA_SQC: "cronos",
    JOB_SQC: "cronos",
    JOB_DESC: "cronos",
    SIN_VEH: "cronos",
    KUNNR: "cronos",
    TIPO_VEHICULO: "cronos",
    ACCESORIO_ACTIVO: "cronos",
    tiene_citas_ap: false,
    grupoq: "cronos",
    recepcion: "cronos",
    isGQ: true,
    isCQ: false,
    isSQ: false,
  };

  constructor(
    private apptHistoryHelper: ApptHistoryHelper,
    private vehicleService: VehiculosService,
    private catalogService: CatalogService,
    private promisesProvider: PromisesService
  ) {
    super();
  }

  pokeVehicles(data) {
    this.$sliderVehicles.next(data);
  }

  pokeDeleteVehicle(data) {
    this.$deleteVehicle.next(data);
  }

  getDeleteVehicleAsObservable() {
    return this.$deleteVehicle.asObservable();
  }

  getVehiclesAsObservable() {
    return this.$sliderVehicles.asObservable();
  }

  getCurrentVehicleAsObservable() {
    return this.$currentVehicle.asObservable();
  }

  pokeCurrentVehicle(data) {
    this.$currentVehicle.next(data);
  }

  public getCurrentVehicle(): QCar {
    try {
      // Intenta obtener el vehículo de la propiedad actual o de localStorage
      let vehicle =
        this.currentVehicle ||
        window.localStorage.getItem("currentGlobalVehicle");

      if (vehicle !== null || vehicle !== undefined) {
        // Verifica si 'vehicle' es una cadena y, de ser así, intenta parsearlo como JSON
        if (typeof vehicle === "string") {
          vehicle = JSON.parse(vehicle);
        }

        // Retorna el vehículo (objeto fake)
        return vehicle;
      } else {
        // Retorna el vehículo fake
        this.setCurrentVehicle(this.vehicleFake);
        return this.vehicleFake;
      }
    } catch (error) {
      // Retorna el vehículo fake
      this.setCurrentVehicle(this.vehicleFake);
      return this.vehicleFake; // TODO manejar el error de otra manera
    }
  }

  public isCurrentVehicleCQ(): boolean {
    return this.getCurrentVehicle() && this.getCurrentVehicle().isCQ || false;
  }

  public setCurrentVehicle(vehicle) {
    this.currentVehicle = vehicle;
    window.localStorage.setItem(
      "currentGlobalVehicle",
      JSON.stringify(vehicle)
    );
  }

  async getUserVehicles() {
    this.vehicleService.getVehiculosCliente(this.apptHistoryHelper.authService.getSociedadUsuario(), this.apptHistoryHelper.authService.getCodigoUsuario())
      .then(async (result) => {
        // this.vehiculos = result;
        var d = new Date();
        var dia = d.getDate();
        // localStorage.removeItem('vehiculos');
        localStorage.removeItem('fechaDeCarga');
        // localStorage.setItem('vehiculos', JSON.stringify(this.vehiculos));
        localStorage.setItem('fechaDeCarga', dia.toString());
        // this.vehiculos.forEach(vehiculo => {
        //   this.validarAseguradora(vehiculo);
        // });

        const storedVehicles = this.promisesProvider.getUserVehicles();
        this.promisesProvider.setUserVehiclesBase(result);

        // Preload history for first vehicle
        if (result && result.length > 0) {
          this.promisesProvider.setUserCurrentVehicle(storedVehicles[0], 0);
          this.setCurrentVehicle(storedVehicles[0]);
          this.pokeCurrentVehicle(storedVehicles[0]);
          this.apptHistoryHelper.loadCarHistory(result[0]);
        }

        if (!storedVehicles || JSON.stringify(storedVehicles) != JSON.stringify(result) && Array.isArray(result)) {
          this.promisesProvider.setUserVehicles(result);
          this.promisesProvider.setUserCurrentVehicle(result[0], 0);
          this.setCurrentVehicle(result[0]);
          this.pokeCurrentVehicle(result[0]);
          this.promisesProvider.pokeUserVehicles(result);
        }

        // PRE-LOAD ALL INSURANCE COMPANIES FOR EACH SOCIETY (Using cache helper)
        Object.keys(this.apptHistoryHelper.authService.getSocieties()).map((society) => {
          this.cargarAseguradora(society); // Cache helper method
        });

        // GONNA LOAD INSURANCE COMPANIES
        this.catalogService.cargarAseguradoras(result);

      }).catch(error => {
      if (!environment.production) {
        // this.utilService.presentAlert({message: error});
      }
      // console.warn(error);
      // this.utilService.presentToast('Sin vehiculos asociados');
    });
  }


  cargarAseguradora(sociedad) {
    if (!this.isValidKeyETA(`aseguradoras:${sociedad}`, 30)) {
      return this.catalogService.cargarAseguradoras(sociedad)
        .then(result => {
          localStorage.setItem(`aseguradoras:${sociedad}`, JSON.stringify(result));
        }).catch(error => {
          // console.log(error);
          this.apptHistoryHelper.utilService.presentToast('Problemas de conexión: CTLG');
        });
    }
  }


}
