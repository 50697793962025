import {Component, OnInit} from '@angular/core';
import {ApproveQuoteResponse, OrderQuote, OrderQuoteDetails} from "../../../pages/migrupoq/appt/appt-status/models";
import {ApptStatusHelper} from "../../../helpers/migrupoq/ApptStatusHelper";
import {VehicleHelper} from "../../../helpers/VehicleHelper";
import {UtilService} from "../../../providers/util.service";
import {ModalController, PopoverController} from "@ionic/angular";

@Component({
  selector: 'app-quote-status-modal',
  templateUrl: './quote-status-modal.component.html',
  styleUrls: ['./quote-status-modal.component.scss'],
})
export class QuoteStatusModalComponent implements OnInit {

  comment = '';
  quote: OrderQuote;
  items: OrderQuoteDetails[] = [];
  groups;
  Object = Object;
  approveQuoteResponse: ApproveQuoteResponse = null;

  errorTestMsg = [{"Error": "si", "descripcion": "Usuario de consumo no es valido", "Funcion": "aprobar_presupuesto"}];
  successTestMsg = "Aprobado";

  constructor(
    protected apptStatusHelper: ApptStatusHelper,
    protected vehicleHelper: VehicleHelper,
    protected utilService: UtilService,
    protected popoverCtrl: PopoverController,
    protected modalCtrl: ModalController) {
  }

  expandItem(group, item): void {
    if (item.expanded) {
      group.map((x => x.checked = true));
    } else {
      group.map((x => x.checked = false));
    }
  }

  ngOnInit() {
    const keys = Object.keys(this.quote.DETALLE);

    // Get details from objects
    keys.forEach((key, index) => {
      this.items.push(new OrderQuoteDetails(this.quote.DETALLE[key]));
    });


    // Group by LBRVLGRP
    // title: TEXT_GRP
    this.groups = UtilService.groupBy(this.items, 'LBRVLGRP');

    this.comment = this.quote.DESCRIPCION;

  }

  getGroupAmount(orderDetails: OrderQuoteDetails[]) {
    let total = 0;
    orderDetails.map(x => {
      if (x.checked) {
        total += x.Monto;
      }
    });
    return total.toFixed(2);
  }

  getTotalAmount() {
    let total = 0;
    const keys = Object.keys(this.groups);
    // Get details from objects
    keys.forEach((key, index) => {
      total += +this.getGroupAmount(this.groups[key]);
    });
    return total.toFixed(2);
  }

  getUnCheckedGroups() {
    let uncheckedGroups = [];
    const keys = Object.keys(this.groups);
    // Get details from objects
    keys.forEach((key, index) => {
      if (this.groups[key].every(x => !x.checked)) {
        uncheckedGroups.push(key);
      }
    });
    return uncheckedGroups;
  }

  getCheckedGroups() {
    let uncheckedGroups = [];
    const keys = Object.keys(this.groups);
    // Get details from objects
    keys.forEach((key, index) => {
      if (this.groups[key].every(x => x.checked)) {
        uncheckedGroups.push(key);
      }
    });
    return uncheckedGroups;
  }

  getUnCheckedJobs() {
    let uncheckedJobs = [];
    const keys = Object.keys(this.groups);
    // Get details from objects
    keys.forEach((key, index) => {
      this.groups[key].map((x) => {
        if (!x.checked) {
          uncheckedJobs.push(x);
        }
      });
    });
    return uncheckedJobs;
  }

  get isQuoteReadOnly() {
    return this.quote.STATUS !== 'C';
  }

  sendQuoteStatus() {
    // console.log('unchecked jobs');
    // console.log(this.getUnCheckedJobs());
    // console.log('unchecked groups');
    // console.log(this.getUnCheckedGroups());

    this.utilService.presentLoading();
    this.apptStatusHelper.saveQuoteResponse(this.quote.VBELN, this.getUnCheckedGroups(), {}, this.comment).then((response) => {
      this.utilService.dismissLoading();

      this.approveQuoteResponse = new ApproveQuoteResponse(response);
      if (this.approveQuoteResponse.hasError()) {
        return this.utilService.presentToast(this.approveQuoteResponse.descripcion);
      }
      this.closeModal({response: this.approveQuoteResponse});
    }, (err) => {
      this.utilService.dismissLoading();
      // console.log(err);
    });
    // this.closeModal({response: this.approveQuoteResponse});
    // this.closeModal({response: new ApproveQuoteResponse(this.errorTestMsg[0])});
    // this.closeModal({response: new ApproveQuoteResponse(this.successTestMsg)});
  }

  closeModal(data) {
    return this.modalCtrl.dismiss(data);
  }

}
