import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-header-text',
  templateUrl: './header-text.component.html',
  styleUrls: [ './header-text.component.scss' ],
})
export class HeaderTextComponent implements OnInit {
  @Input() title: string;
  @Input() titleIcon: string;
  @Input() iconRight = 'refresh-outline';
  @Input() iconLeft = 'chevron-back-outline';
  @Input() public sticky = false;
  @Output() leftBtnClick = new EventEmitter<any>();
  @Output() rightBtnClick = new EventEmitter<any>();

  isIconDefault = this.iconLeft === 'chevron-back-outline';

  constructor(
    private navCtrl: NavController,
  ) {
  }

  ngOnInit() {
  }

  btnLeftClicked() {
    if (this.leftBtnClick) {
      return this.leftBtnClick.emit();
    }
    this.navCtrl.back();
  }

  btnRightClicked() {
    if (this.rightBtnClick) {
      return this.rightBtnClick.emit();
    }
  }
}
