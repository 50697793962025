import {Component, Input, OnInit, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import {AlertController, ModalController, NavController, Platform, PopoverController} from '@ionic/angular';
import {LoanHelper} from '../../../helpers/crediq/LoanHelper';
import {Subscription} from 'rxjs';
import {UtilService} from '../../../providers/util.service';
import {VehicleHelper} from '../../../helpers/VehicleHelper';
import {IPaymentResponse} from './models';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {exampleResponse} from './example_response';
import {BASE_CREDIQ_URL, environment} from '../../../../environments/environment';
import {
  ConfirmPaymentCreditcardComponent
} from '../../../components/confirm-payment-creditcard/confirm-payment-creditcard.component';
import {IonInput} from '@ionic/angular';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SelectCardComponent} from '../../../components/select-card/select-card.component';
import {PaymentWebComponent} from "../../../components/payment-web/payment-web.component";
import {DomSanitizer} from "@angular/platform-browser";
import {NotifyCrediqTokenizationHelper} from "../../../helpers/crediq/NotifyCrediqTokenizationHelper";

// import {NotifyCrediqTokenizationHelper} from "../../../helpers/crediq/NotifyCrediqTokenizationHelper";

@Component({
  selector: 'app-payment',
  templateUrl: './payment.page.html',
  styleUrls: ['./payment.page.scss'],
})

export class PaymentPage implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('monto', {static: true}) public numberInput: IonInput;

  inputFormatted: any;
  public loanDetails: any;
  public loanDetailsCar: any;
  payForm: FormGroup;

  isPaymentInProgress = false;

  flagIcon: boolean;
  flagBtn: boolean;
  suggestedAmount: any;
  backBtnSubscriber: Subscription;
  userCardsSubscription: Subscription;
  fechaVecCardSelected: any;


  userCards: any[] = [];
  isLoadingUserLoanCards = false;

  currentVehicle;
  selectedCard;

  transaction3dsResponse;
  response;
  cardsImages = {
    VISA: 'assets/imgs/crediq/cards/logo-visa.png',
    MASTERCARD: 'assets/crediq/cards/imgs/logo-mastercard.png',
    AMEX: 'assets/imgs/crediq/cards/logo-amex.png',
    DEFAULT: 'assets/imgs/crediq/cards/default-logo-credit-card.png'
  };

  requiereCodigo: any;

  previousAmountValue = '';

  crediqUrl = '';

  showControls = false;

  constructor(
    public navCtrl: NavController,
    public loanHelper: LoanHelper,
    public utilHelper: UtilService,
    public platform: Platform,
    public vehicleHelper: VehicleHelper,
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public inApp: InAppBrowser,
    public popoverCtrl: PopoverController,
    public sanitizer: DomSanitizer,
    private notifyCrediqTokenization: NotifyCrediqTokenizationHelper
  ) {

  }

  init(): void {
    this.currentVehicle = this.vehicleHelper.getCurrentVehicle();
    this.userCards = this.loanHelper.getUserLoanCards();
    this.isLoadingUserLoanCards = this.loanHelper.isLoadingUserLoanCards();
    this.loanDetails = JSON.parse(window.localStorage.getItem('carInf'));
    if (this.loanDetails.detalleUS) {
      this.loanDetailsCar = this.loanDetails.detalleUS.find(x => x.numeroPlaca == this.currentVehicle?.NUM_PLACA);
      // tslint:disable-next-line:triple-equals
      if (this.loanDetailsCar == undefined || this.loanDetailsCar == null) {
        // tslint:disable-next-line:triple-equals
        this.loanDetailsCar = this.loanDetails.detalleUS.find(x => x.numeroChasis == this.currentVehicle?.VIN);
        // tslint:disable-next-line:triple-equals
        if (this.loanDetailsCar == undefined || this.loanDetailsCar == null) {
          this.utilHelper.presentToast('No se encontraron detalles del préstamo para este vehículo');
        }

      }
    } else {
      this.loanDetailsCar = [];
    }
    this.suggestedAmount = this.loanDetailsCar.totalVence + this.loanDetailsCar.valorCuota;
    this.response = exampleResponse;
    this.payForm = new FormGroup({
      amount: new FormControl('', Validators.min(1)),
      cvv: new FormControl('', [Validators.maxLength(4), Validators.minLength(3)])
    });
    this.crediqUrl = BASE_CREDIQ_URL;
  }

  ngOnInit() {
    this.init();
    this.notifyCrediqTokenization.validateStatusNotifyCrediqTokenization();
    this.validateStatusNotifyCrediqTokenization();
    if (this.isLoadingUserLoanCards) {
      this.utilHelper.presentLoading();
    }
    this.userCardsSubscription = this.loanHelper.getUserLoanCardsObservable().subscribe((data) => {
      if (this.isLoadingUserLoanCards) {
        this.utilHelper.dismissLoading();
      }
      if (!this.userCards) {
        this.userCards = this.loanHelper.getUserLoanCards();
      }
    });

    this.backBtnSubscriber = this.platform.backButton.subscribeWithPriority(999999, () => {
      return this.navCtrl.back({animated: true});
    });
  }

  ngOnDestroy() {
    this.isPaymentInProgress = false;
  }

  getamountest() {
    return !!this.payForm.get('amount').value;
  }

  validateStatusNotifyCrediqTokenization() {
    const testStatusNotifyCrediqTokenization = this.notifyCrediqTokenization.getStatusNotifyCrediqTokenization();
    if (testStatusNotifyCrediqTokenization) {
      const testJson = JSON.parse(testStatusNotifyCrediqTokenization);
      if (testJson.notifyCrediqTokenizationLocal) {
        this.showControls = false;
      } else {
        this.showControls = true;
      }
    }
  }

  // Manejo de Errores Formulario
  textInputError(nameControl: string = 'NULL', form: any = 'NULL') {
    const invalid = form.get(nameControl)?.invalid;
    if (invalid || form?.errors) {

      const errors = form.get(nameControl).errors;
      const touched = form.get(nameControl).touched;

      if (errors || form?.errors) {
        if (errors?.required && touched) {
          return 'Este campo es obligatorio';
        }

        if (errors?.maxlength && nameControl) {
          return 'El máximo de carácteres de este campo es de ' + errors?.maxlength?.requiredLength;
        }

        if (errors?.minlength && nameControl) {
          return 'El mínimo de carácteres de este campo es de ' + errors?.minlength?.requiredLength;
        }
        if (errors?.min && nameControl) {
          return 'El monto tiene que ser mayor que 0. ';
        }
      } else {
        return 'El campo no es válido';
      }
    }
  }

  // Obtener Marca de la card
  getBrandCard(brand: any) {
    try {
      return this.cardsImages[brand];
    } catch (ex) {
      // return  'assets/imgs/default-logo-credit-card.png';
      return false;

    }

  }

  // Validar solo numeros en CVV
  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  isNumberAmount(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    // console.log(!(charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46));
    return !(charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46);
  }

  ngAfterViewInit() {
    this.numberInput.getInputElement().then(input => {
      input.onkeyup = ((val: any) => {
        this.onKeyUp(val.key);
        val.preventDefault();
        val.stopImmediatePropagation();
        val.cancelBubble = true;
      });
      input.onclick = ((val: any) => {
        input.setSelectionRange(0, val.target.value.length);
      });
    });
  }

  // Manejador modal seleccionar Tarjeta
  async showBillingDetail() {
    this.validateStatusNotifyCrediqTokenization();
    if (!this.showControls) {
      return;
    }
    const modal = await this.modalCtrl.create({
      component: SelectCardComponent,
      cssClass: 'modal-credit-cards-web-crediq',
      componentProps: {
        arrCards: this.userCards
      },
    });
    modal.onDidDismiss().then(data => {
      let tempCard = '';
      const responseCard = data?.data?.card;
      if (responseCard) {
        this.requiereCodigo = responseCard.pago_requiere_codigo === 'true';
        if (!this.selectedCard) {
          if (!responseCard.close) {

            this.selectedCard = responseCard;
            const tempVec = responseCard.fecha_expiracion.split('');
            this.fechaVecCardSelected = `${tempVec[0]}${tempVec[1]}/${tempVec[2]}${tempVec[3]}`;
            this.getBrandCard(responseCard.marca);

            // console.log(data);
          }
        } else {
          if (!responseCard.close) {
            tempCard = this.selectedCard;
            const tempVec = responseCard.fecha_expiracion.split('');
            this.fechaVecCardSelected = `${tempVec[0]}${tempVec[1]}/${tempVec[2]}${tempVec[3]}`;
            this.getBrandCard(responseCard.marca);
            if (tempCard !== responseCard) {
              this.payForm.patchValue({cvv: null});
            }
            this.selectedCard = responseCard;
          }
        }
      }
    });
    return await modal.present();
  }

  submitPay() {
    const requiereCodigo = this.selectedCard.pago_requiere_codigo === 'true';
    let amount;
    const cvv = this.payForm.value.cvv;
    if (this.payForm.value.amount) {
      amount = this.payForm.value.amount;
    } else {
      amount = this.suggestedAmount;
    }
    this.submitPaymentWS(amount, requiereCodigo && cvv);
  }

  // Proceso de Pago

  async openIframeModal(url: string) {
    const trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    const modal = await this.modalCtrl.create({
      component: PaymentWebComponent,
      componentProps: {url: trustedUrl},
      id: 'frame-payment-modal',
      backdropDismiss: false,
    });
    return await modal.present();
  }

  dsPayment(body: any) {
    // Create Transaction Payment
    this.loanHelper.createTransaction({
      user_id: this.loanHelper.authService.getCodigoUsuario(),
      body
    }).then((response: any) => {
      const data = JSON.parse(response.data);
      this.isPaymentInProgress = true;
      void this.modalCtrl.dismiss();
      this.openIframeModal(`${environment.endpoints.transactions}${data.uid}`);
      // console.log('response de this.loanHelper.createTransaction ::: ', `${environment.endpoints.transactions}${data.uid}`);
    }).catch(err => console.log(err));
  }

  renderTransaction3dsResult(html) {
    this.transaction3dsResponse = JSON.parse(html[0]);
    // console.log(this.transaction3dsResponse);

    if (this.transaction3dsResponse['process-status'] === 'error' || this.transaction3dsResponse.status === 'error') {
      this.utilHelper.presentAlert({
        message: this.transaction3dsResponse.message || 'Error al procesar pago.',
        confirmMethod: this.goBack.bind(this)
      });
    }

    if (this.transaction3dsResponse['process-status'] === 'ok' || this.transaction3dsResponse.status === 'ok') {
      this.utilHelper.presentAlert({
        message: 'Su pago ha sido procesado con éxito',
        confirmMethod: this.goBack.bind(this)
      });
    }
  }

  async submitPaymentWS(amount, cvv) {
    const paymentParams: PaymentParams = {
      codigoSeguridad: cvv,
      prestamo: this.loanHelper.getUserCurrentLoan(this.currentVehicle).numeroPrestam,
      monto: amount.replace(/,/g, ''),
      pais: this.loanHelper.authService.getPaisCodeUsuario(),
      // tokenTarjeta: '433333_0CPES2222'
      tokenTarjeta: this.selectedCard.token
    };

    void this.utilHelper.presentLoading();

    setTimeout(() => {
      if (this.isPaymentInProgress) {
        this.isPaymentInProgress = false;
        this.utilHelper.dismissLoading();
        this.utilHelper.presentToast('Estimado Cliente, la transacción no respondió en el tiempo esperado, favor intente nuevamente en 15 minutos.');
      }
    }, 300000); // 5 minutes

    this.isPaymentInProgress = true;
    this.loanHelper.submitPayment(paymentParams).then((response: IPaymentResponse) => {

      this.isPaymentInProgress = false;
      this.utilHelper.dismissLoading();

      // console.log('response de this.loanHelper.submitPayment ::: ', response);

      const errorList = ['user-not-found', 'invalid-token', 'token-missing', 'restriccion'];
      if (errorList.includes(response['process-status'])) {
        return this.utilHelper.presentAlert({message: response.message || 'Su pago no pudo ser aplicado'});
      }

      if (response['process-status'] === 'error' && response.status === 'error') {
        return this.utilHelper.presentToast(response.message || 'Su pago no pudo ser aplicado');
      }

      if (response['process-status'] === 'tarjeta_no_permitida') {
        return this.utilHelper.presentToast(response.status || 'Tarjeta no permitida');
      }

      if (response['process-status'] === 'ok' && response.status === 'ok') {
        this.utilHelper.presentAlert({
          message: 'Su pago ha sido procesado con éxito',
          confirmMethod: this.goBack.bind(this)
        });
      }

      if (response.htmlForm) {
        this.dsPayment(response.htmlForm);
      }

      this.loanHelper.getloanDetailsLightWS(true); // loan details light (to load vehicles faster)
      this.loanHelper.getloanDetailsWS(true, true); // loan details full (to load full loan details)

    }).catch((error) => {
      this.utilHelper.presentToast('Se ha excedido el tiempo de espera para esta solicitud');
      this.utilHelper.dismissLoading();
    });
  }

  goBack() {
    this.navCtrl.back();
  }

  onKeyUp(val) {
    // TODO: Fix this tmp solution
    const _str = this.numberInput.value.toString();
    if (val != 'Backspace') {
      val = _str[_str.length - 1];
      const raw = this.numberInput.value.toString().replace(/,/g, '');
      let amount = '';
      if (raw) {
        const regex = /^[0-9.,]+$/;
        if (val.toString().match(regex)) {
          const raw = this.numberInput.value.toString().replace(/,/g, '');
          amount = this.formatNumberBank(raw);
          this.numberInput.value = amount;
          this.previousAmountValue = amount;
        } else {
          this.numberInput.value = this.previousAmountValue;
        }
      }
    }
  }

  formatNumberBank(numStr) {
    // @ts-ignore
    const isPoint = numStr[numStr.length - 1] === '.';
    const subStr = numStr.substr(0, numStr.length - 1);
    const hasAlreadyPoint = subStr.includes('.');
    // const parsed = parseFloat(numStr, 10);

    if (subStr.includes('.') && isPoint) {
      numStr = numStr.substr(0, numStr.length - 1);
    }
    // if (isNaN(numStr)) {
    //   return null;
    // }
    const nf = new Intl.NumberFormat('en-US');
    const formatted = numStr;
    const decimal = formatted.split('.');
    const hasDecimal = decimal.length > 1;
    // @ts-ignore
    this.inputFormatted = nf.format(formatted);
    if (hasDecimal && formatted[formatted.length - 1] == '0' && (decimal.length > 0)) {
      if (formatted[formatted.length - 2] == '0') {
        this.inputFormatted = `${this.inputFormatted}.0`;
      }

      if (formatted[formatted.length - 2] == '.') {
        this.inputFormatted = `${this.inputFormatted}.`;
      }

      this.inputFormatted = `${this.inputFormatted}0`;
    }

    const hasMoreThan2Decimals = decimal && decimal.length > 1 && decimal[1].length > 2;
    if (hasMoreThan2Decimals) {
      this.inputFormatted = this.inputFormatted.substr(0, this.inputFormatted.length - 1);
    }

    if (isPoint && !hasAlreadyPoint && hasDecimal) {
      this.inputFormatted = `${this.inputFormatted}.`;
    }

    return this.inputFormatted;
  }


}

interface PaymentParams {
  tokenTarjeta: string;
  monto: number;
  codigoSeguridad: string;
  prestamo: string;
  pais: string;
}
