<div class="billing-cnt ion-padding fs-RAJ">
  <div class="ion-text-center pop-header">
    <ion-text color="light"><b>Préstamo</b></ion-text>
  </div>
  <ion-list>
    <div class="ion-text-center ion-margin-bottom">
      <ion-text class="ion-text-center" color="light"><b>#{{loanDetail?.numeroPrestam}}</b></ion-text>
    </div>
    <!--Vehicle info-->
    <ion-row class="ion-text-center">
      <ion-col size="6">
        <div>
          <small>Saldo inicial</small>
        </div>
        <div>
          <ion-text>{{loanDetail.saldoInicial | currency:loanDetail?.moneda:'symbol-narrow'}}</ion-text>
        </div>
      </ion-col>
      <ion-col size="6">
        <div>
          <small>Saldo pendiente</small>
        </div>
        <div>
          <ion-text>{{loanDetail.saldoCancelacion | currency:loanDetail?.moneda:'symbol-narrow'}}</ion-text>
        </div>
      </ion-col>
      <ion-col size="6">
        <div>
          <small>Fecha apertura</small>
        </div>
        <div>
          <ion-text>{{loanDetail.fechaApertura}}</ion-text>
        </div>
      </ion-col>
      <ion-col size="6">
        <div>
          <small>Fecha cierre</small>
        </div>
        <div>
          <ion-text>{{loanDetail.fechaVencimiento}}</ion-text>
        </div>
      </ion-col>
      <ion-col size="6">
        <div>
          <small>Monto vencido</small>
        </div>
        <div>
          <ion-text>{{loanDetail.totalVence | currency:loanDetail?.moneda:'symbol-narrow'}}</ion-text>
        </div>
      </ion-col>
      <ion-col size="6">
        <div>
          <small>Cuota mensual</small>
        </div>
        <div>
          <ion-text>{{loanDetail.valorCuota | currency:loanDetail?.moneda:'symbol-narrow'}}</ion-text>
        </div>
      </ion-col>
      <ion-col size="6">
        <div>
          <small>Último pago</small>
        </div>
        <div>
          <ion-text>{{loanDetail.fechaUltimoPago }}</ion-text>
        </div>
      </ion-col>
      <ion-col size="6">
        <div>
          <small>Próximo pago</small>
        </div>
        <div>
          <ion-text>{{loanDetail.fechaProximoPago }}</ion-text>
        </div>
      </ion-col>
    </ion-row>
  </ion-list>

  <div class="m-auto ion-text-center" style="width: 150px;">
    <div class="btn-ptm ion-margin-top cursor-pointer" (click)="dismissModal()" >
      <ion-text color="gpblue">Aceptar</ion-text>
    </div>
  </div>

</div>
