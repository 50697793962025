import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {ModalController} from "@ionic/angular";
import {UtilService} from "../../providers/util.service";

@Component({
  selector: 'app-payment-web',
  templateUrl: './payment-web.component.html',
  styleUrls: ['./payment-web.component.scss'],
})
export class PaymentWebComponent implements OnInit, OnDestroy {
  @Input() url: string;
  @ViewChild('frameTest', {static: true}) iframe: ElementRef;
  private boundReceiveMessage: any;

  constructor(
    private modalCtrl: ModalController,
    private utilService: UtilService
  ) {
  }

  ngOnInit() {
    this.boundReceiveMessage = this.receiveMessage.bind(this);
    window.addEventListener('message', this.boundReceiveMessage, false);
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.boundReceiveMessage, false);
  }

  successPayment() {
    void this.utilService.presentAlert({
      title: 'Aviso',
      message: 'El pago se realizó con éxito',
      confirmMethod: () => {
        this.closeModal();
      },
      backdropDismiss: false
    });
  }

  failedPayment() {
    void this.utilService.presentAlert({title: 'Error', message: 'Error al procesar el pago', backdropDismiss: false});
  }

  receiveMessage(event: MessageEvent) {
    // You can access the message data from the event object.
    if (event?.data) {
      const messageData = JSON.parse(event.data);

      if (messageData['process-status'] === 'error') {
        return this.failedPayment();
      }

      if (messageData['process-status'] === 'ok') {
        return this.successPayment();
      }
    }
  }

  closeModal(): void {
    window.removeEventListener('message', this.boundReceiveMessage, false);
    void this.modalCtrl.dismiss();
  }

  onIframeLoad(event: any): void {
    // const iframe: HTMLIFrameElement = event.target;
    // const currentUrl = iframe.contentWindow.location.href;
    // console.log('URL actual:', currentUrl);
  }

}
