import {Injectable} from '@angular/core';
import {AlertController, LoadingController, ToastController} from '@ionic/angular';
import {Observable, Subject, Subscription} from 'rxjs';
import {environment} from '../../environments/environment';
import {LoanHelper} from '../helpers/crediq/LoanHelper';
import {VehicleHelper} from '../helpers/VehicleHelper';
import {AuthService} from './auth.service';
import {PromisesService} from './promises.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private wsStatus = {
    "obtenerTarjetasUsuario": false,
    "obtenerTarjetasUsuarioPagoPrestamos": false,
    "realizarPago": false,
    "registerSpecial": false,
    "consultarPrestamos": false,
    "solicitarEstadoCuenta": false,
    "getGarantias": false,
    "carnetsAsociadosByPrestamo": false,
    "agregarTarjeta": false,
    "validarTarjeta": false,
    "removerTarjeta": false,
  };

  constructor(
    private promisesProvider: PromisesService,
    private authService: AuthService,
    private vehicleHelper: VehicleHelper,
    private loanHelper: LoanHelper
  ) {
  }

  cleanSessionData() {
    // backup images
    const appVersion = localStorage.getItem('appVersion');
    const imgs = localStorage.getItem('vehiclesImages');
    const fbDate = localStorage.getItem('fbDate');
    const isNewInstall = localStorage.getItem('isNewInstall');
    const smartToken = window.localStorage.getItem(environment.smartq.storagekeys.smartToken);
    const username = window.localStorage.getItem(environment.settings.username);
    const appConfig = window.localStorage.getItem('appConfig');
    const cookiesTrueGQ = window.localStorage.getItem('cookiesTrueGQ');
    window.localStorage.clear();
    localStorage.setItem('statusLoansRequest', JSON.stringify(this.wsStatus));

    localStorage.setItem('appVersion', appVersion);
    localStorage.setItem('vehiclesImages', imgs);
    localStorage.setItem('fbDate', fbDate);
    localStorage.setItem(environment.settings.username, username);
    localStorage.setItem('isNewInstall', isNewInstall === 'null' ? '1' : isNewInstall);
    localStorage.setItem(environment.settings.username, username);
    localStorage.setItem('appConfig', appConfig);
    localStorage.setItem('cookiesTrueGQ', cookiesTrueGQ);

    if (smartToken) {
      localStorage.setItem(environment.smartq.storagekeys.smartToken, smartToken);
    }

    // CLEAN OLD DATA
    this.authService.setProfile(null);
    this.authService.setProfileDetail(null);

    this.promisesProvider.setUserVehiclesBase(null);
    this.promisesProvider.setUserVehicles([]);
    this.promisesProvider.setUserCurrentVehicle(null, 0);

    // CREDIQ
    this.authService.setCrediQUser(null, false);
    this.loanHelper.setUserLoans(null, false);
    this.loanHelper.setUserLoansLight(null, false);
    this.loanHelper.setUserCurrentVehicle(null, 0);
    this.vehicleHelper.setCurrentVehicle(null);

  }

}
