import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { VehicleHelper } from '../../../../helpers/VehicleHelper';
import { AuthService } from '../../../../providers/auth.service';
import { ServicesService } from '../../../../providers/services.service';
import { UtilService } from '../../../../providers/util.service';
import { ConfirmQuotationPage } from '../confirm-quotation/confirm-quotation.page';
import { HandleBlockerPage } from "../handle-blocker/handle-blocker.page";
import { Router } from "@angular/router";

@Component({
  selector: 'app-new-quotation',
  templateUrl: './new-quotation.page.html',
  styleUrls: [ './new-quotation.page.scss' ],
})
export class NewQuotationPage implements OnInit {

  // Variables de data user
  codSociedad: string = '';
  codInventarioVehiculo: string = '';
  kilometrajeInicial: string = '';
  cantidadPaquetesCotizar: number = 3;
  monedaUsuario: string = '';
  codigoUsuario: string = '';

  reactiveForm: FormGroup;

  existeSucursal: boolean = false;

  sucursales: any[] = [];

  currentSucursal: any;

  currentCurrency: any;

  //Paquetes
  paquetesDataWS = {
    MONTOS: {
      DESCUENTO: "0.00",
      IVA: "0.00",
      SUBTOTAL: "0.00",
      TOTAL: "0.00",
    },
    PAQUETES: [],
    RANGOKM: '',
    moneda: {
      local: '',
      dolares: ''
    },
    CODIGO_ERROR: '0',
    DESPLIEGA_NOTIFICACION: false,
    MENSAJE: '',
  };

  constructor(private serviceService: ServicesService,
              private auth: AuthService,
              private vehicleHelper: VehicleHelper,
              private utilService: UtilService,
              private navCtrl: NavController,
              private router: Router,
              private modalController: ModalController) {
    this.reactiveForm = new FormGroup({
      kmInput: new FormControl('0'),
      cantServiciosInput: new FormControl('0'),
    });
  }

  ngOnInit() {
    this.getDataUser();
    this.getListadoSucursalesServicontratos();
    this.getMonedaClienteSociedad();
  }

  // Obtenemos la data necesaria para realizar la peticion
  getDataUser() {
    this.codSociedad = this.vehicleHelper.getCurrentVehicle().SOCIEDAD;
    this.codInventarioVehiculo = this.vehicleHelper.getCurrentVehicle().NUM_INVENTARIO;
  }

  getMonedaClienteSociedad() {
    const socUser = this.auth.getSociedadUsuario();
    this.serviceService.obtenerMonedaClienteSociedad(socUser).then((resp: any) => {
      this.monedaUsuario = resp.valor;
    }).catch((error: any) => {
      // console.log('Error al obtener moneda de cliente => ', error);
    });
  }

  // Obtenemos la data para la gestion de cotizaciones, kilometros y tipos de moneda
  getDataServiContratos() {
    this.utilService.presentLoading();
    this.codigoUsuario = this.auth.getCodigoUsuario();
    this.kilometrajeInicial = this.reactiveForm.get('kmInput').value;
    // this.cantidadPaquetesCotizar = this.reactiveForm.get('cantServiciosInput').value;
    if (parseInt(this.kilometrajeInicial) <= 0 && this.cantidadPaquetesCotizar <= 0) {
      this.dismissLoad();
      this.utilService.presentToast('El kilometraje de su vehiculo y cantidad de paquetes a cotizar deben de ser mayores a 0.');
      return;
    }
    if (this.cantidadPaquetesCotizar < 3) {
      this.dismissLoad();
      this.utilService.presentToast('Debe de seleccionar al menos 3 paquetes de servicios para poder realiazar la cotizacion.');
      return;
    }
    const kilometraje = this.kilometrajeInicial.replace(",", "");
    this.serviceService.getDataServicontratos(this.codSociedad, this.codInventarioVehiculo, '', kilometraje, this.cantidadPaquetesCotizar, this.monedaUsuario, this.codigoUsuario).then((arg: paquetes) => {
      this.dismissLoad();
      this.paquetesDataWS.MONTOS = arg.MONTOS;
      this.paquetesDataWS.PAQUETES = arg.PAQUETES;
      this.paquetesDataWS.moneda = arg.moneda;
      this.paquetesDataWS.CODIGO_ERROR = arg.CODIGO_ERROR;
      this.paquetesDataWS.DESPLIEGA_NOTIFICACION = arg.DESPLIEGA_NOTIFICACION;
      this.paquetesDataWS.MENSAJE = arg.MENSAJE;

      if (this.paquetesDataWS.CODIGO_ERROR !== '0' && this.paquetesDataWS.DESPLIEGA_NOTIFICACION) {
        return this.openErrorHandlerModal();
      }

      if (!this.paquetesDataWS.PAQUETES) {
        this.utilService.presentToast('No hemos encontrado paquetes disponibles para tu vehiculo.');
        return;
      }
      for (let index = 0; index < this.paquetesDataWS.PAQUETES.length; index++) {
        const element = this.paquetesDataWS.PAQUETES[index];
        this.currentCurrency = this.paquetesDataWS.PAQUETES[0].COD_MONEDA;
        this.paquetesDataWS.RANGOKM += ',' + element.KM;
      }
      this.paquetesDataWS.RANGOKM = this.paquetesDataWS.RANGOKM.substring(1);
    }, err => {
      this.dismissLoad();
      // console.log("err: obtener_data_servicontratos", err);
    });
  }

  formatNumber(event) {
    let vString = event.target.value.replace(/,/g, '');
    vString = vString.replace('-', '');
    vString = vString.replace(/\./g, '');
    vString = vString.replace('.', '');
    vString = vString.replace(/\D/g, '');
    const v = +vString;
    const formatValue = v.toLocaleString();
    this.reactiveForm.get('kmInput').setValue(formatValue);
  }

  add() {
    this.cantidadPaquetesCotizar++
  }

  remove() {
    if (this.cantidadPaquetesCotizar > 3) {
      this.cantidadPaquetesCotizar--;
    }
  }

  dismissLoad() {
    setTimeout(() => {
      this.utilService.dismissLoading();
    }, 500);
  }

  getListadoSucursalesServicontratos() {
    try {
      // const sociedad = this.auth.getSociedadUsuario();
      const sociedad = this.vehicleHelper.getCurrentVehicle().SOCIEDAD;
      // const sociedad = "C101";
      this.serviceService.getListadoSucursalesServicontratos(sociedad).then(response => {
        this.sucursales = response;
        this.existeSucursal = true;
      }).catch(err => {
        // console.log(err);
      });
    } catch (error) {
      // console.log("err: obtener_sucursales_servicontratos ", error);
    }
  }

  async presentModal() {
    if (!this.currentSucursal) {
      this.utilService.presentToast("Debe seleccionar una Sucursal cercana para generar una cotizacion.");
      return;
    }
    const modal = await this.modalController.create({
      component: ConfirmQuotationPage,
      cssClass: 'my-custom-class',
      componentProps: {
        'paquetes': this.paquetesDataWS,
        'sucursal': this.sucursales.find(x => x.id_department === this.currentSucursal)?.nombre || '',
        'sucursal_id': this.sucursales.find(x => x.id_department === this.currentSucursal)?.id_department || '',
        'currency': this.currentCurrency,
      }
    });
    modal.onDidDismiss()
      .then((data: any) => {
        if (data.data) {
          if (data.data.dismissSuccess) {
            this.paquetesDataWS.PAQUETES.length = 0;
            this.paquetesDataWS.RANGOKM = null;
            this.paquetesDataWS.MONTOS.IVA = null;
            this.paquetesDataWS.MONTOS.DESCUENTO = '';
            this.paquetesDataWS.MONTOS.TOTAL = null;
            this.paquetesDataWS.MONTOS.SUBTOTAL = null;
            this.paquetesDataWS.moneda = null;
            this.cantidadPaquetesCotizar = 3;
            this.reactiveForm.get('kmInput').setValue("0");
          }
        }
      });
    return await modal.present();
  }

  async openErrorHandlerModal() {
    const modal = await this.modalController.create({
      component: HandleBlockerPage,
      cssClass: 'my-custom-class',
      componentProps: {
        offices: this.sucursales,
        message: this.paquetesDataWS.MENSAJE,
        errorID: this.paquetesDataWS.CODIGO_ERROR,
      }
    });
    modal.present();

    const {data, role} = await modal.onWillDismiss();

    if (data.hasOwnProperty('submitted') && data.submitted) {
      this.router.navigateByUrl('grupoq/home');
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }


}

interface paquetes {
  MONTOS: {
    DESCUENTO: string,
    IVA: string,
    SUBTOTAL: string,
    TOTAL: string;
  };
  PAQUETES: [];
  RANGOKM: string;
  moneda: {
    local: string;
    dolares: string;
  };
  DESPLIEGA_NOTIFICACION: boolean;
  CODIGO_ERROR: string;
  MENSAJE: string;
}
