<ion-content>
  <!-- Inicio header vehiculo -->
  <app-header-car sticky="true" [allowCarSelection]="false"></app-header-car>

  <!-- Titulo -->
  <div class="ion-margin-top fsz-14 ion-text-center">
    <ion-icon name="trending-up-outline" color="gptitle"></ion-icon>
    <ion-text class="ion-margin-start font-weight-bold fs-18" color="gptitle">Nueva Cotizacion</ion-text>
  </div>

  <!-- Contenido de ejecucion -->

  <!-- input, entrada de kilometros del vehiculo -->
  <ion-row [formGroup]="reactiveForm" class="ion-margin-top fsz-11">
    <ion-col size="12">
      <ion-text class="ion-margin-start" color="gptitle">Ingresa el kilometraje de tu vehículo</ion-text>
    </ion-col>
    <ion-col size="12">
      <div class="ion-text-center ion-margin-horizontal w-100">
        <ion-input type="text" inputmode="decimal" (ionChange)="formatNumber($event)" formControlName="kmInput"
          class="ion-margin-horizontal">
        </ion-input>
      </div>
    </ion-col>
    <ion-col size="12">
      <ion-text class="ion-margin-start" color="gptitle">Ingresa la cantidad de paquetes que deseas</ion-text>
    </ion-col>
    <ion-col size="12">
      <small class="ion-margin-start" color="gptitle">(Se requiere un mínimo de 3)</small>
    </ion-col>
  </ion-row>

  <ion-grid fixed class="ion-margin-start">
    <ion-row class="controls">
      <ion-col size="2">
        <a (click)="remove()" class="buttonnew plus">
          <ion-icon slot="start" name="remove"></ion-icon>
        </a>
      </ion-col>
      <ion-col size="2">
        <a class="buttonnew plus">
          {{ cantidadPaquetesCotizar }}
        </a>
      </ion-col>
      <ion-col size="2">
        <a (click)="add()" class="buttonnew plus">
          <ion-icon slot="start" name="add"></ion-icon>
        </a>
      </ion-col>
      <ion-col size="2" class="ion-text-center">
        <ion-button (click)="getDataServiContratos()" size="small" fill="solid" shape="round" class="center-button">
          Cotizar
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <hr>

  <div *ngIf="1 < 2">
    <ion-row class="paq-servi" *ngFor="let item of paquetesDataWS?.PAQUETES">
      <ion-col size="12">
        <ion-item lines="none" class="item-benefit bg-item-paq">
          <!-- <ion-thumbnail slot="start" class="thumb-icon-workshop">
          </ion-thumbnail> -->
          <ion-label>
            <ion-text class="ion-margin-start">
              <span>
                {{ item.PACKAGE_ID_EXT }}
              </span>
            </ion-text>
            <br>
            <ion-text class="ion-margin-start ion-margin-top">
              <strong class="price-paq ">
                {{ item.COD_MONEDA }} {{ item.PRECIO_PAQ }}
              </strong>
            </ion-text>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>

    <div class="if" *ngIf="paquetesDataWS?.PAQUETES?.length > 0">
      <ion-row class="">
        <ion-col size="12">
          <ion-item lines="none" class="">
            <ion-col size="12">
              <table class="table-fill">
                <thead>
                  <tr>
                    <th class="text-left">Concepto</th>
                    <th class="text-left">Total</th>
                  </tr>
                </thead>
                <tbody class="table-hover">
                  <tr>
                    <td class="text-left">Descuento aplicado</td>
                    <td class="text-left"> <strong class="price-paq">
<!--                      {{ paquetesDataWS.moneda?.local }}-->
                      {{currentCurrency}}
                      {{ paquetesDataWS.MONTOS.DESCUENTO }} </strong>
                    </td>
                  </tr>
<!--                  <tr>-->
<!--                    <td class="text-left">Sub-total</td>-->
<!--                    <td class="text-left"> <strong class="price-paq"> {{ paquetesDataWS.MONTOS.SUBTOTAL || 0 }}</strong>-->
<!--                    </td>-->
<!--                  </tr>-->
<!--                  <tr>-->
<!--                    <td class="text-left">IVA</td>-->
<!--                    <td class="text-left"> <strong class="price-paq"> {{ paquetesDataWS.MONTOS.IVA }}</strong> </td>-->
<!--                  </tr>-->
                  <tr>
                    <td class="text-left">Total a pagar</td>
                    <td class="text-left">
                      <strong class="price-paq">
<!--                        {{ paquetesDataWS.moneda?.local }} -->
                        {{currentCurrency}}
                        {{ paquetesDataWS.MONTOS.TOTAL }}
                    </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ion-label class="text-alert-table" color="medium">
                Precios ya incluyen impuestos
              </ion-label>
            </ion-col>
          </ion-item>
        </ion-col>
      </ion-row>
    </div>


    <!-- <ion-row *ngIf="paquetesDataWS.PAQUETES.length > 0">

    </ion-row> -->
    <div class="ion-margin-top"></div>
    <ion-row *ngIf="(paquetesDataWS?.PAQUETES?.length > 0) && (existeSucursal)">
      <ion-col size="12">
        <ion-item>
          <ion-label>Seleccione sucursal</ion-label>
          <ion-select [(ngModel)]="currentSucursal" ok-text="Seleccionar" cancel-text="Cancelar" class="no-border">
            <ion-select-option *ngFor="let item of sucursales" value="{{item.id_department}}">{{ item.nombre }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="(paquetesDataWS?.PAQUETES?.length > 0) && (!existeSucursal)">
      <ion-col size="12" class="ion-text-center">
        <ion-text>
          <span>No hemos encontrado sucursales disponibles</span>
        </ion-text>
      </ion-col>
    </ion-row>

    <div class="proceso" *ngIf="(paquetesDataWS?.PAQUETES?.length > 0) && (existeSucursal)">
      <!-- <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-label>Seleccione Moneda</ion-label>
            <ion-select value="USD" ok-text="Seleccionar" cancel-text="Cancelar" class="no-border">
              <ion-select-option value="USD">Dólares</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <ion-item>
            <ion-label>Tipo de contrato</ion-label>
            <ion-select value="A" ok-text="Seleccionar" cancel-text="Cancelar" class="no-border">
              <ion-select-option value="A">ACELERA PLUS</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row> -->

     <ion-row>
      <ion-col size="6" class="ion-text-center ion-margin-top">
        <ion-button (click)="closeModal()" size="small" fill="solid" shape="round" class="center-button-gen" color="danger">
          Volver
        </ion-button>
      </ion-col>
      <ion-col size="6" class="ion-text-center ion-margin-top">
        <ion-button (click)="presentModal()" size="small" fill="solid" shape="round" class="center-button-gen">
          Generar Cotizacion
        </ion-button>
      </ion-col>
     </ion-row>

    </div>

  </div>

</ion-content>
