import {Component, OnInit, ViewChild} from '@angular/core';
import {AlertController, LoadingController, PopoverController, ToastController} from '@ionic/angular';
import {Router} from '@angular/router';
import {AuthService} from '../../providers/auth.service';
import {PromisesService} from '../../providers/promises.service';
import {HomeService} from '../../providers/home.service';
import {VehiculosService} from '../../providers/vehiculos.service';
import {UtilService} from '../../providers/util.service';
import {MainCarSelectorComponent} from '../main-car-selector/main-car-selector.component';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-loan-detail-popover',
  templateUrl: './loan-detail-popover.component.html',
  styleUrls: ['./loan-detail-popover.component.scss'],
})
export class LoanDetailPopoverComponent implements OnInit {
  insurancesCompaniesLoaded: Subscription;
  loanDetail: any;

  @ViewChild(MainCarSelectorComponent, {static: true}) CarSelector: MainCarSelectorComponent;

  constructor(
    private toastCtrl: ToastController,
    public loadingCtrl: LoadingController,
    private router: Router,
    public servicioAuth: AuthService,
    private promiseService: PromisesService,
    private utilService: UtilService,
    private homeService: HomeService,
    private vehiculosService: VehiculosService,
    public alertCtrl: AlertController,
    private popoverCtrl: PopoverController
  ) {
  }

  ngOnInit() {
    this.insurancesCompaniesLoaded = this.promiseService.getInsuranceCompaniesObservable().subscribe(() => {
      // console.log('insurances companies loaded');
      this.utilService.dismissLoading();
    });
  }

  async dismissModal() {
    await this.popoverCtrl.dismiss();
  }

}
