<ion-content class="ion-margin-top">

  <!-- Titulo -->
  <div class="ion-margin-top fsz-14 ion-text-center">
    <ion-icon name="trending-up-outline" color="gptitle"></ion-icon>
    <ion-text class="ion-margin-start font-weight-bold fs-18" color="gptitle">Confirmar Cotizacion en proceso</ion-text>
  </div>
  <div class="ion-margin-top"></div>

  <div class="space-between-title">
    <div class="ion-text-center ion-margin-bottom">
      <ion-label style="font-size: 1.3em">
        Sucursal:
      </ion-label>
      <span style="font-size: 1.2em">
      {{sucursal}}
      </span>
      <hr>
    </div>
  </div>

<!--  <div>-->
<!--    <ion-list>-->
<!--      <ion-item>-->
<!--        <ion-label>-->
<!--          Sucursal:-->
<!--        </ion-label>-->
<!--        {{sucursal}}-->
<!--      </ion-item>-->
<!--    </ion-list>-->
<!--  </div>-->

  <div *ngFor="let item of getPaquetes(); let i=index">
    <ion-row class="paq-servi">
      <ion-col size="12">
        <ion-item lines="none" class="item-benefit bg-item-paq">
          <!-- <ion-thumbnail slot="start" class="thumb-icon-workshop">
            </ion-thumbnail> -->
          <ion-label>
            <ion-text class="ion-margin-start">
              <span>
                {{ item.PACKAGE_ID_EXT }}
              </span>
            </ion-text>
            <br>
            <ion-text class="ion-margin-start ion-margin-top">
              <strong class="price-paq ">
                USD {{ item.PRECIO_PAQ }}
              </strong>
            </ion-text>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>

  </div>

  <div class="if" *ngIf="paquetes.PAQUETES.length > 0">
    <ion-row class="">
      <ion-col size="12">
        <ion-item lines="none" class="">
          <ion-col size="12">
            <table class="table-fill">
              <thead>
                <tr>
                  <th class="text-left">Concepto</th>
                  <th class="text-left">Total</th>
                </tr>
              </thead>
              <tbody class="table-hover">
                <tr>
                  <td class="text-left">Descuento aplicado</td>
                  <td class="text-left"> <strong class="price-paq">
                    {{currency}}
                    {{ paquetes.MONTOS.DESCUENTO }}
                  </strong> </td>
                </tr>
<!--                <tr>-->
<!--                  <td class="text-left">Sub-total</td>-->
<!--                  <td class="text-left"> <strong class="price-paq"> {{ paquetes.MONTOS.SUBTOTAL || 0 }}</strong>-->
<!--                  </td>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                  <td class="text-left">IVA</td>-->
<!--                  <td class="text-left"> <strong class="price-paq"> {{ paquetes.MONTOS.IVA }}</strong> </td>-->
<!--                </tr>-->
                <tr>
                  <td class="text-left">Total a pagar</td>
                  <td class="text-left"> <strong class="price-paq">
                    {{currency}}
                    {{ paquetes.MONTOS.TOTAL }}
                  </strong> </td>
                </tr>
              </tbody>
            </table>
            <ion-label class="text-alert-table" color="medium">
              Precios ya incluyen impuestos
            </ion-label>
          </ion-col>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-col size="12" class="ion-text-center ion-margin-top">
      <ion-button (click)="saveAndConfirm()" size="small" fill="solid" shape="round" class="center-button-confirm">
        Confirmar y Generar
      </ion-button>
    </ion-col>

    <ion-col size="12" class="ion-text-center">
      <ion-button (click)="cancel()" color="danger" size="small" fill="solid" shape="round"
        class="center-button-confirm">
        Cancelar
      </ion-button>
    </ion-col>
  </div>

</ion-content>
