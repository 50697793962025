import {Injectable} from '@angular/core';
import {SoapService} from '../../services/soap/soap.service';
import {environment as env} from '../../../environments/environment';
import {UtilService} from "../util.service";

@Injectable({
  providedIn: 'root'
})
export class ApptStatusService {
  constructor(private soapService: SoapService, private utilService: UtilService) {}

  /**
   * Search quote by code
   * @param quote_number Quote number
   */
  searchByQuoteNumber(quote_number) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      num_orden: quote_number,
    };
    const urlService = 'pdf_proforma_orden';
    return this.soapService.post(url, urlService, atributos)
      .then((response: any) => {
        const jsonResponse = JSON.parse(response.return);
        this.utilService.checkAndManageErrorResponse(jsonResponse, urlService,"migrupoq");
        return jsonResponse;
        // return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }


  /**
   * Get appointment status
   * @param sociedad Vehicle society
   * @param codigoCliente
   */
  getApptStatus(sociedad, codigoCliente) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      sociedad,
      codigo_cliente: codigoCliente,
      paramBusqueda: ''
    };
    const urlService = 'estado_vhcle_taller';
    return this.soapService.post(url, urlService, atributos)
      .then((response: any) => {
        const jsonResponse = JSON.parse(response.return);
        this.utilService.checkAndManageErrorResponse(jsonResponse, urlService,"migrupoq");
        return jsonResponse;
        // return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  /**
   * Get quote by order #
   * @param sociedad User society
   * @param codigoCliente
   *
   * @param cant_dias
   * últimos días de los cuales obtendrá registros la consulta, en caso
   * de que se defina en 0, obtendrá por defecto los registros de los últimos 30
   * días.
   *
   * @param tipo_cot
   * “AR”: pedidos aprobados y rechazados.
   * “C”: pedidos cotizados.
   * “P”: pedidos pendientes.
   * Si va vacío este parámetro obtiene todos los presupuestos del cliente que se especificó.
   *
   * @param cod_cotizacion order number
   * @param nombreBusqueda parámetro de búsqueda a partir de la descripción del encabezado de la cotización.
   */
  getQuoteByOrder(sociedad, codigoCliente, cant_dias, tipo_cot, cod_cotizacion, nombreBusqueda) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      sociedad,
      codigo_cliente: codigoCliente,
      cant_dias: cant_dias,
      tipo_cot: tipo_cot,
      cod_cotizacion: cod_cotizacion,
      nombreBusqueda: nombreBusqueda
    };
    const urlService = 'listado_cotizacion';
    return this.soapService.post(url, urlService, atributos)
      .then((response: any) => {
        const jsonResponse = JSON.parse(response.return);
        this.utilService.checkAndManageErrorResponse(jsonResponse, urlService,"migrupoq");
        return jsonResponse;
        // return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  /**
   * Obtener info de pago por pedido #
   * @param sociedad User society
   * @param codigoCliente
   * @param orderNumber
   */
  getPaymentInfo(sociedad, codigoCliente, orderNumber) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      sociedad,
      codigo_cliente: codigoCliente,
      pedido: orderNumber,
    };
    const urlService = 'InfoPago_FAC';
    return this.soapService.post(url, urlService, atributos)
      .then((response: any) => {
        const jsonResponse = JSON.parse(response.return);
        this.utilService.checkAndManageErrorResponse(jsonResponse, urlService,"migrupoq");
        return jsonResponse;
        // return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  /**
   * Send order quote response
   * @param sociedad Sociedad a la que pertenece el usuario
   * @param cod_presupuesto Código para identificar el presupuesto por aprobar
   * @param grupos_rechazados Arreglo de los grupos rechazados.
   * @param prod_rechazados Arreglo de los grupos rechazados.
   * @param grupos_rechazados Arreglo de los productos rechazados.
   * @param comentario Motivo del rechazo o aprobación.
   * @param tipo_cliente Sigla para identificar el tipo de cliente (f – j).
   */
  sendOrderQuoteResponse(sociedad, cod_presupuesto, grupos_rechazados, prod_rechazados, comentario, tipo_cliente) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      sociedad,
      cod_presupuesto: cod_presupuesto,
      grupos_rechazados: JSON.stringify(grupos_rechazados),
      prod_rechazados: JSON.stringify(prod_rechazados),
      comentario: comentario,
      tipo_cliente: tipo_cliente
    };
    const urlService = 'aprobar_presupuesto';
    return this.soapService.post(url, urlService , atributos)
      .then((response: any) => {
        const jsonResponse = JSON.parse(response.return);
        this.utilService.checkAndManageErrorResponse(jsonResponse, urlService,  "migrupoq");
        return jsonResponse;
        // return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }

  downloadJobs(sociedad, num_orden){
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      sociedad,
      num_orden,
    };
    const urlService = 'pdf_proforma_orden';
    return this.soapService.post(url, urlService, atributos)
      .then((response: any) => {
        const jsonResponse = JSON.parse(response.return);
        this.utilService.checkAndManageErrorResponse(jsonResponse, urlService,"migrupoq");
        return jsonResponse;
        // return JSON.parse(response.return);
      }).catch(error => {
        return error;
      });
  }
}
