import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {onError} from '@apollo/client/link/error';
import {DocumentNode} from 'graphql';
import {
  BusinessByCategory,
  BusinessWithCategoriesByCountry,
  EventsQueryBenefits,
  HIGHLIGHTS_QUERY,
  PromoQueryBenefits
} from './queries/benefits.query';

@Injectable({
  providedIn: 'root'
})

export class BenefitsGraphqlService {

  query_benefit_byid = gql`query getBenefits($id: String!){
    benefitById(id: $id) {
      business { id, business, logoUrl, category{ id },
        benefits {
          benefit, id, bannerImage, header, description,
          benefitArtUrl, canShare, isExclusive,
          business  {
            id, logoUrl, business, geolocation, phoneNumber, facebookUrl, whatsappUrl, instagramUrl, twitterUrl
          }
        }
      }
    }
  }`;

  DEFAULT_QUERY = gql`query getBenefits($country: String!){
    categoriesByCountry(country: $country) {
      id, name, description,
      backgroundColor, webIcon, parent{
        id
        name
      }
      business {
        id,
        logoUrl,
        business, category{ name },
        benefits {
          id, benefit, header, description,
          benefitArtUrl, canShare, isExclusive,
          offices {
            office
            latitude
            longitude
          }
          business  {
            business,
            logoUrl,
          },
          tags {
            name
          }
        }
      }
    }
  }`;

  constructor(private apollo: Apollo) {
  }

  getBenefits(countryArg: string, query: DocumentNode) {
    return this.apollo
      .watchQuery({
        query, variables: {country: countryArg.toUpperCase()},
        fetchPolicy: 'no-cache'
      });
  }

  getBenefitsById(idArg: string) {
    return this.apollo
      .watchQuery({
        query: this.query_benefit_byid, variables: {id: idArg},
        fetchPolicy: 'no-cache'
      }).valueChanges;
  }

  getEventsByCountry(countryArg: string) {
    return this.apollo
      .watchQuery({
        query: EventsQueryBenefits, variables: {country: countryArg.toUpperCase()},
        fetchPolicy: 'no-cache'
      }).valueChanges;
  }

  getPromosByCountry(countryArg: string) {
    return this.apollo
      .watchQuery({
        query: PromoQueryBenefits, variables: {country: countryArg.toUpperCase()},
        fetchPolicy: 'no-cache'
      }).valueChanges;
  }

  getBusinessByCategory(categoryIdArg: number) {
    return this.apollo
      .watchQuery({
        query: BusinessByCategory, variables: {categoryId: categoryIdArg},
        fetchPolicy: 'no-cache'
      }).valueChanges;
  }

  getBusinessWithCategoriesByCountry(countryArg: string) {
    return this.apollo
      .watchQuery({
        query: BusinessWithCategoriesByCountry, variables: {country: countryArg.toUpperCase()},
        fetchPolicy: 'no-cache'
      });
  }

  getHighlights(countryArg: string) {
    return this.apollo
      .watchQuery({
        query: HIGHLIGHTS_QUERY, variables: {country: countryArg.toUpperCase()},
        fetchPolicy: 'no-cache'
      });
  }

  // Obtenemos las actividades que tenemos en local
  getActivityLocal() {
    let activities = !!localStorage.getItem("activitiesLocal");
    return activities;
  }

  // Almacenamos el beneficios seleccionado en cache
  saveActivitiesLocal(activity: any, otherActivities?: any) {
    const existeLocal = this.getActivityLocal();
    if (!existeLocal) {
      localStorage.setItem("activitiesLocal", JSON.stringify(""));
    }
    let activities = JSON.parse(localStorage.getItem("activitiesLocal"));
    activities = [];

    let data = {
      ...activity,
      otherActivities: [
        ...otherActivities
      ]
    };
    activities.push(data);
    localStorage.setItem('activitiesLocal', JSON.stringify(activities));
  }

  // Obtenemos los beneficios que tenemos en local
  getBenefitLocal() {
    let benefit = !!localStorage.getItem("benefitsLocal");
    return benefit;
  }

  // Almacenamos el beneficios seleccionado en cache
  saveBenefitLocal(benefit: any, otherBenefits?: any, text: string = "Beneficios") {
    const existeLocal = this.getBenefitLocal();
    if (!existeLocal) {
      localStorage.setItem("benefitsLocal", JSON.stringify(""));
    }
    let benefits = JSON.parse(localStorage.getItem("benefitsLocal"));
    benefits = [];

    let data = {
      text,
      ...benefit,
      otherBenefits: [
        ...otherBenefits
      ]
    }
    benefits.push(data);
    localStorage.setItem('benefitsLocal', JSON.stringify(benefits));
  }

  // Obtenemos las categorias que tenemos en local
  getCategoriesLocal() {
    const category = !!localStorage.getItem('categoriesLocal');
    return category;
  }

  // Almacenamos las categorias seleccionadas en cache
  saveCategoriesLocal(category: any, otherCategories?: any) {
    const existLocal = this.getCategoriesLocal();
    if (!existLocal) {
      localStorage.setItem('categoriesLocal', JSON.stringify(''));
    }
    let categories = JSON.parse(localStorage.getItem('categoriesLocal'));
    categories = [];
    const data = {
      category,
      otherCategories: [
        otherCategories
      ]
    };
    categories.push(data);
    localStorage.setItem('categoriesLocal', JSON.stringify(categories));
  }
}
