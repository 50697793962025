<div class="circle-progress" [ngStyle]="{'width.px': size, 'height.px': size}">
  <svg [ngStyle]="{'width': size, 'height': size}">
    <circle
      [attr.cx]="(size - 10) / 2"
      [attr.cy]="(size - 10) / 2"
      [attr.r]="(size - 10) / 2"
      [ngStyle]="{'strokeWidth': stroke}"
    />
    <circle
      [attr.cx]="(size - 10) / 2"
      [attr.cy]="(size - 10) / 2"
      [attr.r]="(size - 10) / 2"
      [ngStyle]="{
      'strokeDashoffset': (((size * 3) - 10) - (((size * 3) - 10) * percentage ) / 100) + 'px',
      'stroke': color,
      'strokeWidth': stroke,
      'strokeDasharray': ((size * 3) - 10)}"
    />
  </svg>
  <div class="number">
      <span *ngIf="title" [ngClass]="titleClass">{{title}}<span *ngIf="title2" [ngClass]="titleClass2">{{title2}}</span></span>
      <span *ngIf="subtitle" [ngClass]="subtitleClass">{{subtitle}}</span>
      <span *ngIf="description" [ngClass]="descriptionClass">{{description}}</span>
  </div>
</div>
