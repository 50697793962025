import {Component, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {LoanHelper} from '../../helpers/crediq/LoanHelper';
import {UtilService} from '../../providers/util.service';
import {AuthService} from '../../providers/auth.service';
import {AnalyticsService} from "../../providers/analytics.service";

@Component({
  selector: 'app-loan-edit-options-popover',
  templateUrl: './loan-edit-options-popover.component.html',
  styleUrls: ['./loan-edit-options-popover.component.scss'],
})
export class LoanEditOptionsPopoverComponent implements OnInit {

  loan: any;
  updated = false;

  constructor(
    private popoverCtrl: PopoverController,
    private loanHelper: LoanHelper,
    private authService: AuthService,
    private utilHelper: UtilService,
    protected analyticsService: AnalyticsService
  ) {
  }

  ngOnInit() {
  }

  changeLoanAlias() {
    this.utilHelper.presentLoading();

    this.analyticsService.logEvent(AnalyticsService.EDIT_INFORMATION_ALIASES, 'edit_loan_alias_popup');

    this.loanHelper.changeLoanAliasWS({
      country: this.authService.getPaisCodeUsuario(),
      loanNumber: this.loan.numeroPrestam,
      loanALias: this.loan.alias
    }).then((data) => {
      this.utilHelper.dismissLoading();
      console.log(data);
      if (data['process-status'] == 'not-updated') {
        this.utilHelper.presentToast('Error al actualizar el dato');
        this.updated = false;
        this.dismissModal();
        return;
      }
      if (data['process-status'] && data['process-status'] == 'updated') {
        this.utilHelper.presentToast('Dato actualizado');
        this.updated = true;
        this.dismissModal();
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  async dismissModal() {
    await this.popoverCtrl.dismiss({updated: this.updated, loan: this.loan});
  }
}
