import { Component, OnInit } from '@angular/core';
import { VehicleHelper } from '../../helpers/VehicleHelper';
import { AuthService } from '../../providers/auth.service';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-smart-qemergy-calls',
  templateUrl: './smart-qemergy-calls.component.html',
  styleUrls: ['./smart-qemergy-calls.component.scss'],
})
export class SmartQEmergyCallsComponent implements OnInit {

  currentVehicle = null;
  currentCodeClient = null;
  currentCodeCountryClient = null;

  countriesData = [
    {
      country: 'El Salvador',
      code: '+503',
      phone: '25145252',
      key: 'SV',
    },
    {
      country: 'Honduras',
      code: '+504',
      phone: '22904090',
      key: 'HN',
    },
    {
      country: 'Nicaragua',
      code: '+505',
      phone: '22701888',
      key: 'NI',
    },
    {
      country: 'Panamá',
      code: '+507',
      phone: '3091299',
      key: 'PA',
    },
    {
      country: 'Guatemala',
      code: '+502',
      phone: '25030909',
      key: 'GT',
    },
    {
      country: 'Costa Rica',
      code: '+506',
      phone: '22171515',
      key: 'CR',
    },
  ];

constructor(private vehicleHelper: VehicleHelper, private authService: AuthService, private popoverCtrl: PopoverController) { }

  ngOnInit() {
    this.getCurrentClient();
    this.getCurrentVehicle();
    this.getCurrentCountryClient();
  }

  getCurrentVehicle() {
    this.currentVehicle = this.vehicleHelper.getCurrentVehicle();
  }

  getCurrentClient() {
    this.currentCodeClient = this.authService.getCodigoUsuario();
  }

  getCurrentCountryClient() {
    this.currentCodeCountryClient = this.authService.getPaisCodeUsuario();
  }

  async dismissModal() {
    await this.popoverCtrl.dismiss();
  }

  callEmergencySmartQ() {
    const call = this.countriesData.find((x: any) => x.key === this.currentCodeCountryClient);
    return window.open(`tel:${call.code}${call.phone}`);
  }

}
