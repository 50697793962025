export class EventResponse {
  fecha = '';
  tipo_evento = '';
  ip_origen = '';
  evento = '';
  detalle: EventDetail = null;

  constructor(props) {
    this.fecha = props.fecha;
    this.tipo_evento = props.tipo_evento;
    this.ip_origen = props.ip_origen;
    this.evento = props.evento;
    this.detalle = new EventDetail(props.detalle);
  }
}

class EventDetail {
  vin = '';
  uuid = '';
  model = '';
  version = '';
  datetime = '';
  platform = '';
  sap_code = '';
  user_email = '';
  description = '';
  manufacturer = '';
  user_fullname = '';

  constructor(props) {
    if (props) {
      this.vin = props.vin;
      this.uuid = props.uuid;
      this.model = props.model;
      this.version = props.version;
      this.datetime = props.datetime;
      this.platform = props.platform;
      this.sap_code = props.sap_code;
      this.user_email = props.user_email;
      this.description = props.description;
      this.manufacturer = props.manufacturer;
      this.user_fullname = props.user_fullname;
    }
  }
}

export class calificacionConductorSQ {
  error = "";
  dias = 0;
  calificacion = 0;
  resumen: resumenCalificacionConductor;
  eventos: eventosCalificacionConductor[];

  constructor(props) {
    if (props) {
      const calif = props.calificacion ?
        (typeof props.calificacion === 'string' ? props.calificacion.split('/')[0]
          : props.calificacion) : 0;
      this.error = props.error;
      this.dias = props.dias;
      this.calificacion = parseInt(calif, 10) ? +calif : 0;
      this.resumen = props.resumen ?? [];
      this.eventos = props.eventos ?? [];
    }
  }

  get scoreMessage() {
    const score = this.calificacion;

    if (score > 0 && score <= 29) {
      return 'Necesitas mejorar tu forma de conducir';
    }

    if (score > 29 && score <= 59) {
      return 'Cuidado algo anda mal, has un mejor esfuerzo';
    }

    if (score > 59 && score <= 74) {
      return 'Cuida tu forma de conducir';
    }

    if (score > 74 && score <= 84) {
      return 'Vas bien, puedes hacerlo mejor';
    }

    if (score > 84 && score <= 99) {
      return 'Bien hecho su conducción es buena';
    }

    if (score > 99) {
      return 'Bien hecho su conducción es perfecta';
    }
  }

  hasError() {
    return this.error === 'Placa no encontrada.' || (this.calificacion === 0 && this.dias === 0 && this.error === '');
  }
}

class resumenCalificacionConductor {
  arrancadas_bruscas = 0;
  aceleradas_fuerte = 0;
  frenadas_bruscas = 0;
  giros_bruscos = 0;
  cantidad_excesos_velocidad = 0;
  km_sobre_limite = 0;
  segundos_exceso_velocidad = 0;
  crash = 0;

  constructor(props) {
    if (props) {
      this.arrancadas_bruscas = props.arrancadas_bruscas;
      this.aceleradas_fuerte = props.aceleradas_fuerte;
      this.frenadas_bruscas = props.frenadas_bruscas;
      this.giros_bruscos = props.giros_bruscos;
      this.cantidad_excesos_velocidad = props.cantidad_excesos_velocidad;
      this.km_sobre_limite = props.km_sobre_limite;
      this.segundos_exceso_velocidad = props.segundos_exceso_velocidad;
      this.crash = props.crash;
    }
  }
}

class eventosCalificacionConductor {
  constructor(props) {
    if (props) {

    }
  }
}
