import { Component, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { SelectDateComponent } from '../../../components/landing/select-date/select-date.component';
import { AuthService } from '../../../providers/auth.service';
import { ServicesService } from '../../../providers/services.service';
import { UtilService } from '../../../providers/util.service';
import { ConsultaFacturasCliente, DescargarFacturasCliente } from './models';

@Component({
  selector: 'app-e-bills',
  templateUrl: './e-bills.page.html',
  styleUrls: ['./e-bills.page.scss'],
})
export class EBillsPage implements OnInit {

  todayDateComplex: any;
  firstDateComplex: any;

  showAlertData: boolean = false;

  codeToken: string = "";

  slideOpts = {
    initialSlide: 0,
    preloadImages: true,
    updateOnImagesReady: true,
    grabCursor: false,
    pager: true,
    draggable: false,
    allowTouchMove: false,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    on: {
      beforeInit() {
        this.autoplay.stop();
      },
    },
  };

  busqueda = {
    numFactura: ''
  }

  responseData: any;
  rows: any[] = [];

  rowsTemp: any[] = [];

  rowDataClient = {
    Cedula: 0,
    CodigoEjecucion: 0,
    Estado: '',
    MensajeEjecucion: "",
    NumeroCliente: "",
  }

  responseDataDownloadFact: any;
  rowsDownload: any;

  cedula: string = '';
  numero_cliente: string = '';

  customPickerOptionsInicial: any;
  customPickerOptionsFinal: any;
  fechasConsulta = {
    inicial: '',
    final: ''
  }

  constructor(private utilService: UtilService, private serviceServices: ServicesService, private authService: AuthService, private popoverController: PopoverController, private modalController: ModalController) { }

  ngOnInit() {
    // Obtenemos info necesaria para realizar consultra de fadcturas
    this.cedula = this.authService.getUsuarioDocNumber();
    this.numero_cliente = this.authService.getCodigoUsuario();
    // this.authService.getWSCode();
    this.codeToken = this.authService.getWSCode();
    this.getCurrentDate();

    this.customPickerOptionsInicial = {
      buttons: [{
        text: 'Seleccionar',
        handler: (data) => {
          const year = data.year.value;
          const month = data.month.value;
          const day = data.day.value;
          const dt = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
          this.fechasConsulta.inicial = dt;
        }
      }, {
        text: 'Cerrar',
        handler: () => {
        }
      }]
    };

    this.customPickerOptionsFinal = {
      buttons: [{
        text: 'Seleccionar',
        handler: (data) => {
          const year = data.year.value;
          const month = data.month.value;
          const day = data.day.value;
          const dt = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
          this.fechasConsulta.final = dt;
        }
      }, {
        text: 'Cerrar',
        handler: () => {
        }
      }]
    };
  }
  // Obtenemos listado de facturas de cliente autenticado en la web
  obtenerListadoFacturas(fechaInicio?: string, fechaFin?: string) {
    this.utilService.presentLoading();
    this.rows = [];
    this.rowsTemp = [];
    this.serviceServices.obtenerListadoFacturas(this.codeToken, this.cedula, this.numero_cliente, fechaInicio, fechaFin).then(response => {
      this.utilService.dismissLoading();
      this.responseData = new ConsultaFacturasCliente(Array.isArray(response) ? response[0] : response);
      // this.responseData = new ConsultaFacturasCliente(this.fakeFac);
      if (this.responseData.hasError()) {
        this.showAlertData = true;
        this.utilService.presentToast(this.responseData.descripcion);
        return;
      }

      if (this.responseData.descripcion.MensajeEjecucion === 'Sin resultado para los datos de consulta') {
        this.showAlertData = true;
        this.utilService.presentToast(this.responseData.descripcion.MensajeEjecucion);
        return;
      }

      this.showAlertData = false;
      this.rowDataClient.Cedula = this.responseData.descripcion.Cedula;
      this.rowDataClient.CodigoEjecucion = this.responseData.descripcion.CodigoEjecucion;
      this.rowDataClient.Estado = this.responseData.descripcion.Estado;
      this.rowDataClient.MensajeEjecucion = this.responseData.descripcion.MensajeEjecucion;
      this.rowDataClient.NumeroCliente = this.responseData.descripcion.NumeroCliente;
      if (!Array.isArray(this.responseData.descripcion.Facturas)) {
        this.rows.push(this.responseData.descripcion.Facturas);
        this.rowsTemp.push(this.responseData.descripcion.Facturas);
      } else {
        this.rows = this.responseData.descripcion.Facturas;
        this.rowsTemp = this.responseData.descripcion.Facturas;
      }

      // this.utilService.dismissLoading();
    }).catch(err => {
      this.showAlertData = true;
      this.utilService.dismissLoading();
      console.log(err);
    });
  }

  downloadFactura(numfactura: any) {
    this.utilService.presentLoading();
    const code = this.authService.getWSCode();
    this.serviceServices.DescargarFactura(code, numfactura).then(response => {
      this.utilService.dismissLoading();
      // console.log(response);
      this.responseData = new DescargarFacturasCliente(Array.isArray(response) ? response[0] : response);
      // this.responseData = new DescargarFacturasCliente(this.fakeDownload);
      if (this.responseData.hasError()) {
        this.utilService.presentToast(this.responseData.descripcion);
        return;
      }
      this.rowsDownload = this.responseData.descripcion;
      this.utilService.dismissLoading();
      if (this.rowsDownload.download_link) {
        window.open(this.rowsDownload.download_link, '_blank');
      }
    }).catch(error => {
      this.utilService.dismissLoading();
      console.log(error);
    });
  }

  // async openPopoverDate(ev: any) {
  //   const openPopoverDate = await this.popoverController.create({
  //     component: SelectDateComponent,
  //     cssClass: "modal-help",
  //     event: ev,
  //     translucent: false,
  //     backdropDismiss: false,
  //     mode: "ios",
  //   });
  //   await openPopoverDate.present();
  //   const { data } = await openPopoverDate.onWillDismiss();
  //
  //   let clase = "";
  //
  //   if (data) {
  //     clase = "modales-help-gq-b";
  //   } else {
  //     clase = "modales-help-gq";
  //   }
  //
  //   if (data.fechaInicio !== null && data.fechaFin !== null) {
  //     this.obtenerListadoFacturas(data.fechaInicio, data.fechaFin);
  //   }
  //
  // }

  closeModal() {
    this.modalController.dismiss();
  }

  buscarFactura() {
    if (this.rows.length < 1) {

    } else {
      if (this.busqueda.numFactura.length > 0) {
        this.rowsTemp = this.rows.filter(x => x.NumeroProcesoFacturaElectronica.includes(this.busqueda.numFactura));
      } else {
        this.rowsTemp = this.rows;
      }
    }
  }

  encontrarcod() {
    const token = this.authService.getWSCode();
  }

  obtenerFacturasButton() {
    if (this.fechasConsulta.inicial.length > 0 && this.fechasConsulta.final.length > 0){
      if (new Date(this.fechasConsulta.inicial) > new Date(this.fechasConsulta.final)) {
        this.utilService.presentToast('La fecha inicial no puede ser mayor a la fecha final.');
      } else {
        this.obtenerListadoFacturas(this.fechasConsulta.inicial, this.fechasConsulta.final);
      }
    } else {
      this.utilService.presentToast('Debe de proporcionar fechas correctas.');
    }
  }

  getCurrentDate() {
    const dt = new Date();
    const dtx = new Date();
    dtx.setDate(dt.getDate() - 30);
    this.fechasConsulta.final = this.refactorDate(dt);
    this.fechasConsulta.inicial = this.refactorDate(dtx);
    this.obtenerFacturasButton();
    // if (this.fechasConsulta.inicial.length > 0 && this.fechasConsulta.final.length > 0){
    //   this.obtenerListadoFacturas(this.fechasConsulta.inicial, this.fechasConsulta.final);
    // }
  }

  refactorDate(date: any) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return  `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
  }

}
