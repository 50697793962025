import {Injectable} from '@angular/core';
import {of, Subject} from 'rxjs';
import {environment as env} from '../../environments/environment';
import {BaseService} from './base.service';
import {SoapService} from '../services/soap/soap.service';
import {QCar, QUser} from '../models/global';

@Injectable({
  providedIn: 'root'
})
export class PromisesService {

  private userVehicles: QCar[];
  private userCurrentVehicle: QCar;

  private insuranceCompaniesVehicles = {};
  private userCarHistory: any;
  public userCarHistoryStatus: any;
  public userCarHistoryError: any;
  public userCarHistoryErrorMsg: any;

  private userCurrentVehicleIndex: number;
  private userCurrentGeneralVehicleIndex: number;

  private isLoadingCarsHistory = false;
  private isLoadingCarsAppt = false;
  private isLoadingInsuranceCompanies = true;

  $insuranceCompanies = new Subject<any>();
  $insuranceCompaniesVehicles = new Subject<any>();
  $userVehicles = new Subject<any>();
  $userCarHistory = new Subject<any>();
  $userCurrentVehicle = new Subject<any>();

  constructor(public bs: BaseService, private soapService: SoapService) {
    this.userVehicles = this.getUserVehicles();
  }

  setIsLoadingCarsHistory(status) {
    this.isLoadingCarsHistory = status;
  }

  setLoadingInsuranceCompanies(status) {
    this.isLoadingInsuranceCompanies = status;
  }

  pokeInsuranceCompanies() {
    this.$insuranceCompanies.next(true);
  }

  pokeInsuranceCompaniesVehicles() {
    this.$insuranceCompaniesVehicles.next(true);
  }

  getLoadingInsuranceCompanies() {
    return this.isLoadingInsuranceCompanies;
  }

  getIsLoadingCarsHistory() {
    return this.isLoadingCarsHistory;
  }

  setIsLoadingCarsAppt(status) {
    this.isLoadingCarsAppt = status;
  }

  getIsLoadingCarsAppt() {
    return this.isLoadingCarsAppt;
  }

  getUserVehiclesObservable() {
    return this.$userVehicles.asObservable();
  }

  getInsuranceCompaniesObservable() {
    return this.$insuranceCompanies.asObservable();
  }

  getInsuranceCompaniesVehiclesObservable() {
    return this.$insuranceCompaniesVehicles.asObservable();
  }

  getUserCarHistoryObservable() {
    return this.$userCarHistory.asObservable();
  }

  getUserCurrentVehicleObservable() {
    return this.$userCurrentVehicle.asObservable();
  }

  pokeUserVehicles(data) {
    this.$userVehicles.next(data);
  }

  pokeUserCarHistory(data) {
    this.$userCarHistory.next(data);
  }

  pokeUserCurrentVehicle(data) {
    this.$userCurrentVehicle.next(data);
  }

  getUserVehicles(): QCar[] {
    return this.userVehicles && this.userVehicles || JSON.parse(window.localStorage.getItem('userVehicles')) || [];
  }

  getUserCarHistory(VIN): any {
    return JSON.parse(window.localStorage.getItem(`userCarHistory:${VIN}`)) || [];
  }

  keyHistoryExist(VIN) {
    return !!window.localStorage.getItem(`userCarHistory:${VIN}`);
  }

  getUserCurrentVehicle(): QCar | null {
    return this.userCurrentVehicle && this.userCurrentVehicle || this.getUserVehicles() && this.getUserVehicles()[0] || null;
  }

  getUserCurrentVehicleIndex(): number {
    return this.userCurrentVehicleIndex && this.userCurrentVehicleIndex || 0;
  }

  getUserGeneralCurrentVehicleIndex(): number {
    return this.userCurrentGeneralVehicleIndex && this.userCurrentGeneralVehicleIndex || 0;
  }

  setUserCurrentGeneralVehicleIndex(slideIndex) {
    this.userCurrentGeneralVehicleIndex = slideIndex;
  }

  getInsuranceVehiclesCompanies(NUM_PLACA): any {
    try {
      return JSON.parse(window.localStorage.getItem(`insurance:${NUM_PLACA}`)) || undefined;
    } catch (e) {
      return undefined;
    }
  }

  setInsuranceVehiclesCompanies(NUM_PLACA, data): any {
    return window.localStorage.setItem(`insurance:${NUM_PLACA}`, JSON.stringify(data));
  }

  setUserVehicles(userVehicles) {
    this.userVehicles = userVehicles;
    window.localStorage.setItem('userVehicles', JSON.stringify(userVehicles));
  }

  setUserVehiclesBase(userVehicles) {
    window.localStorage.setItem('userVehiclesBase', JSON.stringify(userVehicles));
  }

  getUserVehiclesBase() {
    try {
      return JSON.parse(window.localStorage.getItem('userVehiclesBase'));
    } catch (e) {
      return {};
    }
  }

  setUserCarHistory(car, carHistory) {
    this.userCarHistory = carHistory;
    window.localStorage.setItem(`userCarHistory:${car.NUM_PLACA}`, JSON.stringify(carHistory));
  }

  setUserCurrentVehicle(userVehicle, slideIndex) {
    this.userCurrentVehicle = userVehicle;
    this.userCurrentVehicleIndex = slideIndex;
    // window.localStorage.setItem('userVehicles', JSON.stringify(userVehicles));
  }

  requestUserVehicles(usuario, password) {
    const url = env.API_ENDPOINT;
    const atributos = {
      cod: env.WS_CODIGO,
      usuario,
      clave: password
    };
    return this.soapService.post(url, 'consulta_usuario', atributos)
      .then((response: any) => {
        debugger;
        return JSON.parse(response.return);
      }).catch(error => {
        // console.log(error);
        return error;
      });
  }

}
