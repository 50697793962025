import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { MainCarSelectorComponent } from './main-car-selector/main-car-selector.component';
import { MainNavigationComponent } from './main-navigation/main-navigation.component';
import { HeaderCarComponent } from './header-car/header-car.component';
import { CarEditOptionsComponent } from './car-edit-options/car-edit-options.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmergencyPopoverComponent } from './emergency-popover/emergency-popover.component';
import { AccountReportPopoverComponent } from './account-report-popover/account-report-popover.component';
import { LoanEditOptionsPopoverComponent } from './loan-edit-options-popover/loan-edit-options-popover.component';
import { SpeedLimitComponent } from './speed-limit/speed-limit.component';
import { ActivateAlertComponent } from './activate-alert/activate-alert.component';
import { CommonComponentsModule } from './common/components.module';
import { ApptCompleteStatusComponent } from '../pages/migrupoq/appt/appt-new/appt-complete-status/appt-complete-status.component';
import { MapPage } from '../pages/map/map';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { MultilineComponent } from './multiline/multiline.component';
import {AccordeonComponent} from './accordeon/accordeon.component';
import {QuotesListComponent} from './quotes-list/quotes-list.component';
import { ScoreDriverComponent } from './score-driver/score-driver.component';
import { HeaderTextComponent } from './header-text/header-text.component';
import { CircleProgressComponent } from './circle-progress/circle-progress.component';
import { ConfirmPaymentCreditcardComponent } from './confirm-payment-creditcard/confirm-payment-creditcard.component';
import {SelectCardComponent} from './select-card/select-card.component';
import {PaymentWebComponent} from "./payment-web/payment-web.component";
import {NotifyCrediqTokenizationComponent} from "./notify-crediq-tokenization/notify-crediq-tokenization.component";
import {SmartQEmergyCallsComponent} from './smart-qemergy-calls/smart-qemergy-calls.component';
@NgModule({
  declarations: [
    HeaderComponent,
    MainCarSelectorComponent,
    MainNavigationComponent,
    HeaderCarComponent,
    CarEditOptionsComponent,
    EmergencyPopoverComponent,
    AccountReportPopoverComponent,
    LoanEditOptionsPopoverComponent,
    SpeedLimitComponent,
    ActivateAlertComponent,
    ApptCompleteStatusComponent,
    MultilineComponent,
    MapPage,
    ApptCompleteStatusComponent,
    AccordeonComponent,
    QuotesListComponent,
    ScoreDriverComponent,
    HeaderTextComponent,
    ConfirmPaymentCreditcardComponent,
    SelectCardComponent,
    CircleProgressComponent,
    PaymentWebComponent,
    NotifyCrediqTokenizationComponent,
    SmartQEmergyCallsComponent
  ],
  entryComponents: [
    CarEditOptionsComponent,
    EmergencyPopoverComponent,
    AccountReportPopoverComponent,
    LoanEditOptionsPopoverComponent,
    SpeedLimitComponent,
    ActivateAlertComponent,
    ApptCompleteStatusComponent,
    MultilineComponent,
    MapPage,
    AccordeonComponent,
    QuotesListComponent,
    ScoreDriverComponent,
    NotifyCrediqTokenizationComponent,
    PaymentWebComponent,
    SmartQEmergyCallsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    CommonComponentsModule,
    NgxQRCodeModule
  ],
  exports: [
    HeaderComponent,
    MainCarSelectorComponent,
    MainNavigationComponent,
    HeaderCarComponent,
    ApptCompleteStatusComponent,
    MultilineComponent,
    MapPage,
    AccordeonComponent,
    HeaderTextComponent,
    ConfirmPaymentCreditcardComponent,
    SelectCardComponent,
    CircleProgressComponent,
    PaymentWebComponent,
    SmartQEmergyCallsComponent
  ]
})
export class ComponentsModule {
}

