import StorageHelper from './../StorageHelper';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../../providers/auth.service';
import { UtilService } from '../../providers/util.service';
import { ApptStatusService } from '../../providers/smartq/apptStatus.service';
import { VehicleHelper } from '../VehicleHelper';
import { VehicleStatusResponse } from '../../pages/migrupoq/appt/appt-status/models';
import { PromisesService } from "../../providers/promises.service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})

export class ApptStatusHelper extends StorageHelper {
  $apptStatus = new Subject<any>();
  apptStatuses: any;
  loadingApptStatus = {};

  paymentAfsWindow = environment.endpoints.paymentAfsWindow;
  paymentAfsWindowOrigin = environment.endpoints.paymentAfsWindowOrigin;

  constructor(
    public authService: AuthService,
    public apptStatusService: ApptStatusService,
    public vehicleHelper: VehicleHelper,
    public promisesService: PromisesService,
    public utilService: UtilService,
  ) {
    super();
  }

  loadApptStatuses(society = '') {
    if(society){
      if (!this.isLoadingApptStatus(society)) {
        this.setIsLoadingApptStatus(society, true);
        return this.getApptStatusesWS(society)
          .then(result => {
            this.setIsLoadingApptStatus(society, false);

            if (result.length && result[0]?.Error && result[0].Error === "si") {
              this.pokeApptStatus(society, null, this.vehicleHelper.getCurrentVehicle());
              return this.utilService.presentToast(result[0].descripcion || `Error al obtener estado de los vehículos sociedad: ${society}.`);
            }

            this.setApptStatuses(society, new VehicleStatusResponse(result));
            this.pokeApptStatus(society, new VehicleStatusResponse(result), this.vehicleHelper.getCurrentVehicle());
          }).catch(error => {
            this.setIsLoadingApptStatus(society, false);
            console.log(error);
          });
      }
    }
  }

  getApptStatusVehicles() {
    const vehicles = this.promisesService.getUserVehicles();
    // Let's fetch appt statuses by societies
    const societies = new Set(vehicles.map(x => x.SOCIEDAD));

    // console.log('@@LOG:: GETTING STATUSES');
    Array.from(societies).map((x: string) => {
      this.loadApptStatuses(x);
    });
  }

  getApptStatusesWS(society = '') {
    return this.apptStatusService.getApptStatus(society, this.authService.getCodigoUsuario());
  }

  getQuoteByOrderID(order_number) {
    return this.apptStatusService.getQuoteByOrder(
      this.vehicleHelper.getCurrentVehicle()?.SOCIEDAD,
      this.authService.getCodigoUsuario(),
      0, '', order_number, ''
    );
  }

  getPaymentInfo(order_number) {
    return this.apptStatusService.getPaymentInfo(
      this.vehicleHelper.getCurrentVehicle()?.SOCIEDAD,
      this.authService.getCodigoUsuario(),
      order_number
    );
  }

  saveQuoteResponse(quote_code, rejected_groups = [], rejected_prods = {}, comment) {
    return this.apptStatusService.sendOrderQuoteResponse(
      this.authService.getSociedadUsuario(),
      quote_code,
      rejected_groups, rejected_prods, comment, this.authService.getUserTypeAbbr()
    );
  }

  downloadJobs(orderNumber) {
    return this.apptStatusService.downloadJobs(
      this.authService.getSociedadUsuario(),
      orderNumber,
    );
  }

  searchByQuoteNumber(quote_number) {
    return this.apptStatusService.searchByQuoteNumber(quote_number);
  }

  setIsLoadingApptStatus(society, status: boolean) {
    this.loadingApptStatus[society] = status;
  }

  pokeApptStatus(society, data, vehicle) {
    this.$apptStatus.next(data && {data, society, vehicle} || {data: this.getApptStatus(society), society, vehicle});
  }

  getApptStatusObservable() {
    return this.$apptStatus.asObservable();
  }

  setApptStatuses(society, apptStatuses) {
    this.apptStatuses = apptStatuses;
    window.localStorage.setItem(`apptStatuses:${society}`, JSON.stringify(apptStatuses));
  }

  getApptStatus(society): any {
    const inStorage = window.localStorage.getItem(`apptStatuses:${society}`);
    return inStorage && new VehicleStatusResponse(JSON.parse(inStorage)) || new VehicleStatusResponse(null);
  }

  isLoadingApptStatus(society) {
    return this.loadingApptStatus[society] || false;
  }

  generateHTMLForPaymentCronos(dataFormPayment) {
    return `
      <html lang="es">
    <head>
      <meta charset="utf-8">
    <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no">
    <meta name="author" content="Cronos Mobi S.A">
    <link rel="icon" href="images/icon/favicon.ico">
      <title>Pago en L&iacute;nea</title>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
      </head>
      <style>.overlay{position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(255,255,255,1);z-index:999;display:flex;justify-content:center;align-items:center}</style>
    <body id="body">
    <div class="overlay"></div>
      <div class="container">
    <div class="row p-3 align-items-center fondo-blanco">
    <div class="col-6 header-2"></div>
      <div class="col-6 header-1"></div>
      </div>
      <div class="row fondo-beige1 align-items-center">
    <div class="col-12">
    <h5 class="font-weight-bold">PAYMENT WINDOW CRONOS</h5>
    </div>
    </div>
    <div class="row fondo-blanco align-items-center">
    <div class="col-12 pt-2">
    <form action="${this.paymentAfsWindow}" method="post">
      <h3>Requeridos</h3>
      <div class="form-group row">
    <label class="col-sm-2 col-form-label">Pais:</label>
    <div class="col-sm-10"><input type="text" class="form-control" id="fac_id_pais" name="fac_id_pais" value="${dataFormPayment?.country_pay}"></div>
      </div>
      <div class="form-group row">
    <label class="col-sm-2 col-form-label">Moneda:</label>
    <div class="col-sm-10"><input type="text" class="form-control" id="fac_moneda" name="fac_moneda" value="${dataFormPayment?.fac_moneda}"></div>
      </div>
      <div class="form-group row">
    <label class="col-sm-2 col-form-label">Sitio de Origen Prefijo</label>
    <div class="col-sm-10"><input type="text" class="form-control" id="fac_sitio_origen_prefijo" name="fac_sitio_origen_prefijo" value="MGQ"></div>
      </div>
      <div class="form-group row">
    <label class="col-sm-2 col-form-label">Sitio de Origen URL</label>
    <div class="col-sm-10"><input type="text" class="form-control" id="fac_sitio_origen_url_resp" name="fac_sitio_origen_url_resp" value="${this.paymentAfsWindowOrigin}"></div>
      </div>
      <div class="form-group row">
    <label class="col-sm-2 col-form-label">Id Pago</label>
    <div class="col-sm-10"><input type="text" class="form-control" id="fac_id_pago" name="fac_id_pago" value="${dataFormPayment?.id_pago}"></div>
      </div>
      <div class="form-group row">
    <label class="col-sm-2 col-form-label">Codigo Cliente</label>
    <div class="col-sm-10"><input type="text" class="form-control" id="fac_cliente_codigo" name="fac_cliente_codigo" value="${dataFormPayment?.client_code}"></div>
      </div>
      <div class="form-group row">
    <label class="col-sm-2 col-form-label">Tipo Cliente</label>
    <div class="col-sm-10"><input type="text" class="form-control" id="fac_cliente_tipo" name="fac_cliente_tipo" value="${dataFormPayment?.client_type}"></div>
      </div>
      <div class="form-group row">
    <label class="col-sm-2 col-form-label">Numero factura</label>
    <div class="col-sm-10"><input type="text" class="form-control" id="fac_numero_factura" name="fac_numero_factura" value="${dataFormPayment?.id_fac}"></div>
      </div>
      <div class="form-group row">
    <label class="col-sm-2 col-form-label">Monto Factura</label>
    <div class="col-sm-10"><input type="text" class="form-control" id="fac_monto_factura" name="fac_monto_factura" value="${dataFormPayment.total_order}"></div>
      </div>
      <br>
      <h3>Envio De Email (* Todos requeridos )</h3>
    <hr>
    <div class="form-group row">
    <label class="col-sm-2 col-form-label">Enviar email cliente</label>
    <div class="col-sm-10"><input type="text" class="form-control" id="fac_enviar_email_cliente" name="fac_enviar_email_cliente" value="true"></div>
      </div>
      <div class="form-group row">
    <label class="col-sm-2 col-form-label">Email Cliente</label>
    <div class="col-sm-10"><input type="text" class="form-control" id="fac_cliente_correo" name="fac_cliente_correo" value="${dataFormPayment.client_email}"></div>
      </div>
      <div class="form-group row">
    <label class="col-sm-2 col-form-label">Departamento</label>
      <div class="col-sm-10"><input type="text" class="form-control" id="fac_id_departamento" name="fac_id_departamento" value="${dataFormPayment.id_department}"></div>
    </div>
    <hr>
    <div class="button"><button type="submit" class="btn btn-primary btn float-right">---</button></div>
    </form>
    </div>
    </div>
    <div class="row p-2 fondo-beige1 align-items-center contenedor-opciones"></div>
    </div>
    <script>window.Tether={}</script>
      </body>
      </html>
    `;
  }

}
