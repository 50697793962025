<ion-content>
  <ion-grid>
    <ion-row class="ion-margin-top ion-align-items-center">
      <ion-col (click)="closeModal()" size="2" class="ion-text-center ion-no-padding">
        <ion-icon class="bck-button" name="chevron-back" color="light"></ion-icon>
      </ion-col>
      <ion-col size="8" class="fsz-14 ion-text-center">
        <ion-icon name="chatbubble-ellipses" color="gptitle"></ion-icon>
        <ion-text class="ion-margin-start" color="gptitle">Aprobación de presupuestos</ion-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="ion-no-padding">
        <ion-searchbar [(ngModel)]="quoteNumber" animated></ion-searchbar>
      </ion-col>
      <ion-col size="6">
        <ion-button expand="full">Buscar</ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button expand="full">Mostrar todos</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
