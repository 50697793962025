import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss'],
})
export class CircleProgressComponent implements OnInit {

  @Input('title') title = '';
  @Input('titleClass') titleClass = '';
  @Input('title2') title2 = '';
  @Input('titleClass2') titleClass2 = '';
  @Input('subtitle') subtitle = '';
  @Input('subtitleClass') subtitleClass = '';
  @Input('description') description = '';
  @Input('descriptionClass') descriptionClass = '';
  @Input('color') color = '#63e2ff';
  @Input('size') size: any;
  @Input('stroke') stroke = 10;
  @Input('percentage') percentage = 0;

  constructor() {
  }

  ngOnInit() {
  }

}
